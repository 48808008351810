import {
  Transaction,
  TransactionStatus,
  TransactionType,
} from '@alienclient/back-front'
import { LoadingButton } from '@mui/lab'
import { Card, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import dayjs from 'dayjs'
import useT from 'hooks/useTranslation'
import { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DataItem } from '../../DataItem/DataItem'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { formatCurrencyAmount } from 'helpers/helpers'

const TransactionCard = (props: Transaction) => {
  const { currencies } = useAppSelector(getGlobal)
  const { t } = useT()
  const alienContext = useContext(AlienContext)
  const [loading, setLoading] = useState(false)

  let typeChipColor:
    | 'success'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'warning' = 'default'
  let transactionType: string = props.transactionType
  let currency
  let amount

  switch (props.transactionType) {
    case TransactionType.DEPOSIT:
      typeChipColor = 'info'
      transactionType = t('wallet.deposit')
      currency = props.toCurrency
      amount = props.toAmount

      break
    case TransactionType.TOPUP:
      typeChipColor = 'default'
      transactionType = t('wallet.top_up')
      currency = props.toCurrency
      amount = props.toAmount

      break
    case TransactionType.WITHDRAW:
      typeChipColor = 'success'
      transactionType = t('wallet.withdraw')
      currency = props.fromCurrency
      amount = props.fromAmount
      break
  }

  let statusChipColor:
    | 'success'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'warning' = 'default'

  switch (props.status) {
    case TransactionStatus.CONFIRMED:
      statusChipColor = 'success'
      break
    case TransactionStatus.PENDING:
      statusChipColor = 'warning'
      break
    case TransactionStatus.REJECTED:
      statusChipColor = 'default'
      break
    case TransactionStatus.CANCELED:
      statusChipColor = 'error'
      break
  }

  const handleCancel = () => {
    setLoading(true)
    alienContext?.client.cancelWithdraw(
      {
        txId: props.txId,
      },

      {
        uuid: uuidv4(),
      }
    )
  }

  return (
    <Stack sx={{ gap: 2, textAlign: 'center' }}>
      <Typography variant='bodySmallBold'>
        {/* {dayjs(props).format('MMM DD, YYYY')} */}
      </Typography>
      <Card sx={{ borderRadius: 5 }}>
        <Stack
          sx={{
            p: 3,
            gap: 2,
          }}
        >
          {/* Use chip for type */}

          <DataItem
            label={t('label.type')}
            value={transactionType}
            // variant='chip'
            chipColor={typeChipColor}
            sx={{
              '.MuiBox-root .MuiTypography-root': {
                fontSize: 12,
                fontWeight: 600,
                color: colors.secondary[6],
              },
            }}
          />
          <DataItem
            label={t('label.status')}
            value={t(`transaction.${props.status}`) || ''}
            variant='chip'
            chipColor={statusChipColor}
            sx={{ borderRadius: 2.5, fontSize: 12, fontWeight: 600 }}
          />
          <DataItem
            label={t('label.txId')}
            value={props.txId}
            showCopyIcon
            showValue={false}
          />
          {props.gateway && (
            <DataItem
              label={t('wallet.payment_gateway')}
              value={t(`payment_gateway.${props.gateway}`)}
            />
          )}

          {props.noteForPlayer && (
            <DataItem label={t('label.note')} value={props.noteForPlayer} />
          )}

          {currency && (
            <DataItem
              label={t('label.currency')}
              value={t(`currencies.${currency}`, currency)}
            />
          )}
          {amount && (
            <DataItem
              label={t('label.amount')}
              value={formatCurrencyAmount(
                amount,
                currency,
                currencies[currency]?.acceptedDecimals
              )}
            />
          )}

          <DataItem
            label={t('label.date_time')}
            value={dayjs(props.createdAt).format('MMM DD, YYYY | h:mm a')}
          />
          {props.transactionType === TransactionType.WITHDRAW &&
            props.status === TransactionStatus.PENDING && (
              <Stack>
                <LoadingButton
                  color='error'
                  size='small'
                  loading={loading}
                  sx={{ fontSize: 14 }}
                  onClick={handleCancel}
                >
                  {t('general.cancel')}
                </LoadingButton>
              </Stack>
            )}
        </Stack>
      </Card>
    </Stack>
  )
}

export default TransactionCard
