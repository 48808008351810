import { LaunchInfo } from '@alienclient/back-front'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { fetchJSONData } from 'helpers/helpers'
import FunPlayButton from 'parsroyal/components/FunPlayButton/FunPlayButton'
import PageContent from 'parsroyal/components/PageContent/PageContent'
import { useEffect, useState } from 'react'
import {
  Form,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, showToast } from 'store/reducers/uiSlice'

interface FunLaunchParams {
  balance: number
  gameId: string
  lang: string
  currency: string
  nickname: string
  extraData: string
}

export const callLaunchApi = async (
  url: string,
  body: FunLaunchParams,
  gameVersion: number
) => {
  const data = JSON.stringify({
    ...body,
    extraData: {
      lang: body.lang,
      ...JSON.parse(body.extraData),
    },
    // playerId,
  })

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...(gameVersion === 2 && { aliengameversion: 2 }),
    },
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

const languageList = {
  az: { title: 'Azerbaijani' },
  be: { title: 'Belarusian' },
  zh: { title: 'Chinese' },
  da: { title: 'Danish' },
  nl: { title: 'Dutch' },
  en: { title: 'English' },
  $en: { title: '$English$' },
  hi: { title: 'Hindi' },
  tr: { title: 'Turkish' },
  uz: { title: 'Uzbek' },
  bn: { title: 'Bengali' },
  fi: { title: 'Finnish' },
  fr: { title: 'French' },
  de: { title: 'German' },
  ka: { title: 'Georgian' },
  id: { title: 'Indonesian' },
  it: { title: 'Italian' },
  ja: { title: 'Japanese' },
  kk: { title: 'Kazakh' },
  mt: { title: 'Maltese' },
  fa: { title: 'Persian' },
  pt: { title: 'Portuguese' },
  ro: { title: 'Romanian' },
  ru: { title: 'Russian' },
  es: { title: 'Spanish' },
  tl: { title: 'Tagalog' },
  th: { title: 'Thai' },
  uk: { title: 'Ukrainian' },
  vi: { title: 'Vietnamese' },
  pl: { title: 'Polish' },
  sv: { title: 'Swedish' },
  lt: { title: 'Lithuanian' },
  ko: { title: 'Korean' },
  ur: { title: 'Urdu' },
  ar: { title: 'Arabic' },
  hy: { title: 'Armenian' },
}

const gameVersionList = [1, 2]

const FunTest = () => {
  const dispatch = useAppDispatch()
  const [exchangeRates, setExchangeRates] = useState()
  const { gameList, generalConfig } = useAppSelector(getUi)
  const { locale } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [funLaunchParams, setFunLaunchParams] = useState<FunLaunchParams>({
    balance: parseFloat(searchParams.get('balance') || '') || 1000,
    gameId: searchParams.get('gameId') || '1001',
    lang: searchParams.get('lang') || 'en',
    currency: searchParams.get('currency') || 'USD',
    extraData: searchParams.get('extraData') || `{ "launchUrl": "" }`,
    // playerId: '',
    nickname: searchParams.get('nickname') || '',
  })

  const [gameVersion, setGameVersion] = useState(
    parseInt(searchParams.get('gameVersion') || `1`)
  )
  const gameSlug =
    gameList.find((game) => game.gameId === funLaunchParams.gameId)?.slug ||
    'crashroyale'

  // Construct the URL with query parameters
  const queryParams = new URLSearchParams()

  for (const key in funLaunchParams) {
    const value = funLaunchParams[key as keyof FunLaunchParams]?.toString()
    if (funLaunchParams.hasOwnProperty(key) && value) {
      queryParams.set(key, value)
    }
  }

  const url = `${location.pathname}?${queryParams.toString()}`

  async function fetchData() {
    try {
      // Timestamp
      const jsonData = await fetchJSONData(
        'https://alienrates.imoon.com/rates/rates.json'
      )

      setExchangeRates(jsonData.exchangeRates)
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  const launchGame = (options?: { newTab?: boolean; inIframe?: boolean }) => {
    const getFunPlayURL = async () => {
      dispatch(
        showToast({
          show: true,
          message: 'Loading...',
          severity: 'info',
        })
      )
      try {
        if (generalConfig.funLaunchUrl) {
          const res = await callLaunchApi(
            generalConfig.funLaunchUrl,
            funLaunchParams,
            gameVersion
          )
          navigate(url)

          if (options?.newTab) {
            window.open(res.url)
          } else {
            const iframe: HTMLIFrameElement | null =
              document.querySelector('#funTestIframe')

            if (iframe) {
              iframe.src = res.url
            }
          }

          dispatch(
            showToast({
              show: true,
              message: 'Game launched!',
              severity: 'success',
            })
          )
        }
      } catch (error) {
        console.error(error)
        dispatch(
          showToast({ show: true, message: `${error}`, severity: 'error' })
        )
      }
    }

    getFunPlayURL()
  }

  return (
    <Box>
      <PageContent title='Fun Launch Mode' sx={{ alignItems: 'center' }}>
        <Stack
          sx={{
            flexDirection: ['column', 'row'],
            gap: 1,
            alignItems: ['stretch', 'start'],
            flexWrap: 'wrap',
            input: {
              transform: 'translateY(6px)',
            },
            '*': {
              border: 'none',
            },
          }}
        >
          {/* Game Id */}
          <FormControl>
            <InputLabel
              id='gameIdLabel'
              sx={{
                top: '-3px',
                padding: '4px 10px',
                left: -8,
                right: 'initial',
              }}
            >
              Game ID
            </InputLabel>
            <Select
              labelId='gameIdLabel'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                sx: {
                  maxHeight: '500px',
                  maxWidth: 1,
                },
              }}
              value={funLaunchParams.gameId}
              onChange={(e) =>
                setFunLaunchParams((prev) => ({
                  ...prev,
                  gameId: e.target.value,
                }))
              }
            >
              {gameList?.map((game) => (
                <MenuItem value={game.gameId} key={game.gameId}>
                  {game.gameId} - {game.slug}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Currency */}
          <FormControl>
            <InputLabel
              id='currencyLabel'
              sx={{
                top: '-3px',
                padding: '4px 10px',
                left: -8,
                right: 'initial',
              }}
            >
              Currency
            </InputLabel>
            <Select
              labelId='currencyLabel'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                sx: {
                  maxHeight: '500px',
                  maxWidth: 1,
                },
              }}
              value={funLaunchParams.currency}
              onChange={(e) =>
                setFunLaunchParams((prev) => ({
                  ...prev,
                  currency: e.target.value,
                }))
              }
              sx={{ width: 100 }}
            >
              {exchangeRates &&
                Object.keys(exchangeRates).map((key) => (
                  <MenuItem value={key} key={key}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {/* Game Version */}
          <FormControl>
            <InputLabel
              id='gameVersionLabel'
              sx={{
                top: '-3px',
                padding: '4px 10px',
                left: -8,
                right: 'initial',
              }}
            >
              Game Version
            </InputLabel>
            <Select
              labelId='gameVersionLabel'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                sx: {
                  maxHeight: '500px',
                  maxWidth: 1,
                },
              }}
              value={gameVersion}
              onChange={(e) => setGameVersion(parseInt(`${e.target.value}`))}
              sx={{ width: 150 }}
            >
              {gameVersionList.map((key) => (
                <MenuItem value={key} key={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Balance */}
          <TextField
            variant='outlined'
            label='Balance'
            type='number'
            value={funLaunchParams.balance}
            onChange={(e) =>
              setFunLaunchParams((prev) => ({
                ...prev,
                balance: parseFloat(e.target.value),
              }))
            }
          />
          {/* Player Id */}
          {/* <TextField
            variant="outlined"
            placeholder="Player Id"
            value={funLaunchParams.playerId}
            onChange={(e) =>
              setFunLaunchParams((prev) => ({
                ...prev,
                playerId: e.target.value,
              }))
            }
          /> */}

          {/* Language */}
          <FormControl>
            <InputLabel
              id='languageLabel'
              sx={{
                top: '-3px',
                padding: '4px 10px',
                left: -8,
                right: 'initial',
              }}
            >
              Language
            </InputLabel>
            <Select
              labelId='languageLabel'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                sx: {
                  maxHeight: '500px',
                  maxWidth: 1,
                },
              }}
              value={funLaunchParams.lang}
              onChange={(e) =>
                setFunLaunchParams((prev) => ({
                  ...prev,
                  lang: e.target.value,
                }))
              }
            >
              {Object.entries(languageList).map(([code, data]) => (
                <MenuItem value={code} key={code}>
                  {code} - {data.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Nickname*/}
          <TextField
            variant='outlined'
            label='Nickname'
            value={funLaunchParams.nickname}
            onChange={(e) =>
              setFunLaunchParams((prev) => ({
                ...prev,
                nickname: e.target.value,
              }))
            }
          />

          {/* Extra Data */}
          <TextField
            multiline
            label='Extra Data'
            value={funLaunchParams.extraData}
            onChange={(e) =>
              setFunLaunchParams((prev) => ({
                ...prev,
                extraData: e.target.value,
              }))
            }
            rows={5}
            maxRows={5}
            sx={{ textarea: { fontSize: 14 } }}
          />
        </Stack>
        <Stack sx={{ flexDirection: ['column', 'row'], gap: 1 }}>
          <Button variant='contained' onClick={() => launchGame()}>
            Launch Game
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => launchGame({ newTab: true })}
          >
            Launch In New Tab
          </Button>

          <FunPlayButton variant='outlined' slug={gameSlug}>
            Launch In Play Iframe
          </FunPlayButton>
        </Stack>
      </PageContent>

      {<iframe id='funTestIframe' height={650} />}
    </Box>
  )
}

export default FunTest
