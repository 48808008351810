import {
  InitInfo,
  OnGetBetsResponseParams,
  OnGetPaymentUrlResponseParams,
  OnGetPlayerWalletResponseParams,
  OnGetTransactionsResponseParams,
  OnVerifyPaymentResponseParams,
  PaymentGatewayCpgConfig,
  PaymentGatewayJbConfig,
  PaymentGatewayPwConfig,
  PlayerKycVerification,
} from '@alienclient/back-front'
import { OnPlayerNicknameChangeParams } from '@alienclient/back-front/lib/interfaces/on-player-nickname-change-params.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

const initialState: Partial<
  InitInfo & {
    isLoggedIn: boolean
    uploadFileUrl?: {
      fileUrl: string
      uploadUrl: string
    }
    betsList?: OnGetBetsResponseParams
    transactionList?: OnGetTransactionsResponseParams
    verifyPaymentResponse?: OnVerifyPaymentResponseParams
    getPaymentUrlResponse?: OnGetPaymentUrlResponseParams
  }
> = {
  isLoggedIn: false,
  uploadFileUrl: { fileUrl: '', uploadUrl: '' },
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobal(state, action: PayloadAction<InitInfo>) {
      Object.assign(state, action.payload)
      state.isLoggedIn = !!action.payload.player.playerId

      if (action.payload.paymentGateways) {
        const obj = {
          // @ts-ignore
          CPG: {},
          ...action.payload.paymentGateways,
        }
        const arr = Object.entries(obj)
        arr.sort((a, b) => a[1].order - b[1].order)
        const sortedPaymentGateways = Object.fromEntries(arr)
        state.paymentGateways = sortedPaymentGateways as
          | {
              CPG: PaymentGatewayCpgConfig
              PW: PaymentGatewayPwConfig
              JB: PaymentGatewayJbConfig
            }
          | undefined
      }
    },
    resetGlobal: () => {
      return initialState
    },
    updateKycVerification(state, action: PayloadAction<PlayerKycVerification>) {
      if (state.player && state.player.kyc && state.player.kyc.verifications) {
        const verifications = [...state.player.kyc.verifications] // Create a copy of the verifications array

        // Find the index of the verification with a matching uuid
        const indexToUpdate = verifications.findIndex(
          (verification) => verification.uuid === action.payload.uuid
        )

        if (indexToUpdate !== -1) {
          // Replace the object with the new action.payload at the found index
          verifications[indexToUpdate] = action.payload

          // Update the state with the updated verifications array
          state.player.kyc.verifications = verifications
        }
      }
    },
    setPlayerNickname(
      state,
      action: PayloadAction<OnPlayerNicknameChangeParams>
    ) {
      if (state.player) state.player.nickname = action.payload.data?.nickname
    },
    setBetsList(state, action: PayloadAction<OnGetBetsResponseParams>) {
      state.betsList = action.payload
    },
    setTransactionList(
      state,
      action: PayloadAction<OnGetTransactionsResponseParams>
    ) {
      state.transactionList = action.payload
    },
    updateWallet(
      state,
      action: PayloadAction<OnGetPlayerWalletResponseParams>
    ) {
      if (state.player?.wallet && action.payload.data) {
        state.player.wallet = action.payload.data
      }
    },
    updateActiveCurrency(state, action: PayloadAction<string>) {
      if (state.player?.activeCurrency)
        state.player.activeCurrency = action.payload
    },
    setPaymentVerificationResponse(
      state,
      action: PayloadAction<OnVerifyPaymentResponseParams>
    ) {
      state.verifyPaymentResponse = action.payload
    },
    setGetPaymentUrlResponse(
      state,
      action: PayloadAction<OnGetPaymentUrlResponseParams>
    ) {
      state.getPaymentUrlResponse = action.payload
    },
    resetGetPaymentUrlResponse(state) {
      state.getPaymentUrlResponse = initialState.getPaymentUrlResponse
    },
    setUploadFileUrl(
      state,
      action: PayloadAction<{ fileUrl: string; uploadUrl: string }>
    ) {
      if (state.uploadFileUrl) {
        state.uploadFileUrl.fileUrl = action.payload.fileUrl
        state.uploadFileUrl.uploadUrl = action.payload.uploadUrl
      }
    },
    clearUploadFileUrl(state) {
      state.uploadFileUrl = initialState.uploadFileUrl
    },
  },
})

export const {
  setGlobal,
  resetGlobal,
  setBetsList,
  setTransactionList,
  setPlayerNickname,
  updateWallet,
  updateActiveCurrency,
  updateKycVerification,
  setPaymentVerificationResponse,
  setGetPaymentUrlResponse,
  resetGetPaymentUrlResponse,
  setUploadFileUrl,
  clearUploadFileUrl,
} = globalSlice.actions
export const getGlobal = (state: RootState) => state.global
