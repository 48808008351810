import {
  faAddressCard,
  faBell,
  faCoins,
  faEllipsis,
  faGamepad,
  faHandHoldingDollar,
  faHeadset,
  faHouse,
  faMoneySimpleFromBracket,
  faRightLeft,
  faRightToBracket,
  faTrophyStar,
  faWallet,
} from '@fortawesome/pro-duotone-svg-icons'
import { MobileNavItem } from 'parsroyal/components/MobileBottomNav/MobileNavList'
import { NavItemProps } from 'parsroyal/types'
import {
  showBetsSideDrawer,
  showNotificationSideDrawer,
  showTransactionSideDrawer,
  showVerificationSideDrawer,
  showWalletModal,
} from 'store/reducers/uiSlice'
import store from 'store/store'

export const navDrawerWidth = 60

export const gameplayBreakpoint = 1060

export enum NavIndex {
  Games,
  Login,
  Wallet,
  Cup,
  Support,
  More,
  TransactionList,
  BetsList,
  NotificationList,
}

export enum Currencies {
  USDT = 'USDT',
  TOMAN = 'TOMAN',
}

export const COOKIE_VALIDITY = 30

export const navList1: NavItemProps[] = [
  {
    type: 'route',
    icon: faHouse,
    label: 'nav.home',
    href: '',
  },
  // {
  //   icon: faRocket,
  //   label: 'Games',
  //   href: 'games',
  // },
  {
    type: 'route',
    icon: faTrophyStar,
    label: 'nav.tournaments',
    href: 'tournament',
  },
]

export const navList2: NavItemProps[] = [
  {
    type: 'action',
    action: () => {
      store.dispatch(showWalletModal({ activeTab: 'deposit' }))
    },
    icon: faMoneySimpleFromBracket,
    label: 'nav.deposit',
    href: 'deposit',
    queryParams: { key: 'wallet', value: 'deposit' },
    isProtected: true,
  },
  {
    type: 'action',
    action: () => {
      store.dispatch(showWalletModal({ activeTab: 'withdraw' }))
    },
    icon: faHandHoldingDollar,
    label: 'nav.withdraw',
    href: 'withdraw',
    queryParams: { key: 'wallet', value: 'withdraw' },
    isProtected: true,
  },
  {
    type: 'action',
    action: () => {
      store.dispatch(showTransactionSideDrawer())
    },
    icon: faRightLeft,
    label: 'nav.transactions',
    href: 'transactions',

    isProtected: true,
  },
  {
    type: 'action',
    action: () => {
      store.dispatch(showBetsSideDrawer())
    },
    icon: faCoins,
    label: 'nav.my_bets',
    href: 'bets',
    isProtected: true,
  },

  {
    type: 'action',
    action: () => {
      store.dispatch(showNotificationSideDrawer())
    },
    icon: faBell,
    label: 'nav.notifications',
    href: 'notifications',
    isProtected: true,
  },
  {
    type: 'action',
    action: () => {
      store.dispatch(showVerificationSideDrawer())
    },
    icon: faAddressCard,
    label: 'nav.verification',
    href: 'verification',
    isProtected: true,
  },
]

export const mobileNavList: MobileNavItem[] = [
  { icon: faGamepad, label: 'nav.games' },
  {
    icon: faRightToBracket,
    label: 'user.login',
    forGuestUser: true,
  },
  {
    icon: faWallet,
    label: 'nav.wallet',
    isProtected: true,
  },
  {
    icon: faTrophyStar,
    label: 'nav.cup',
  },

  {
    icon: faHeadset,
    label: 'nav.support',
  },
  {
    icon: faEllipsis,
    label: 'nav.more',
  },
]

export enum NotificationSeverity {
  VERBOSE = 'VERBOSE',
  INFORMATION = 'INFORMATION',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  ALERT = 'ALERT',
}

export enum NotificationType {
  TEXT = 'TEXT',
  PROGRESS = 'PROGRESS',
}

export enum CurrencyType {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

export const gcSkeletonList = new Array(6).fill('')
