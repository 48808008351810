import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { colors, theme } from '@theme'
import { AlienContext } from 'App'
import { formatCurrencyAmount } from 'helpers/helpers'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { v4 as uuidv4 } from 'uuid'
import { PaymentGatewayProps } from './PaymentGatewaySelect'
import useT from 'hooks/useTranslation'

export interface CurrencySelectProps {
  currency: string
  setCurrency: Dispatch<SetStateAction<string>>
}

export const CurrencySelect = (
  props: CurrencySelectProps & PaymentGatewayProps
) => {
  const { paymentGateway, currency, setCurrency } = props
  const alienContext = useContext(AlienContext)
  const wallet = useAppSelector(getGlobal).player?.wallet

  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  const handleChange = (e: SelectChangeEvent) => {
    setCurrency(e.target.value)
  }

  const handleDeposit = () => {
    if (alienContext?.isSocketConnected) {
      alienContext.client.getPlayerWallet(
        {},
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )
    }
  }

  if (!wallet?.byCurrency) return <></>

  // for (const [key, value] of Object.entries(wallet.byCurrency)) {
  //   itemList.push(
  //     <MenuItem value={key} key={key}>
  //       <Stack>
  //         {/* Currency icon to be here */}
  //         <Typography>{key}</Typography>
  //       </Stack>
  //     </MenuItem>
  //   )
  // }

  return (
    <Stack sx={{ gap: 2.5, width: 1 }}>
      <FormControl fullWidth variant='filled'>
        <InputLabel
          id='selectCurrencyLabel'
          sx={{
            top: '-3px',
            padding: '4px 10px',
            '*': {
              fontFamily: `${
                i18n.dir(i18n.language) === 'rtl'
                  ? 'Iranyekan'
                  : '"Nunito Sans"'
              }, sans-serif !important`,
            },
            ...(dir === 'rtl'
              ? { right: 6, left: 'initial' }
              : { left: 0, right: 'initial' }),
          }}
        >
          {t('wallet.currency')}
        </InputLabel>
        <Select
          labelId='selectCurrencyLabel'
          size='small'
          value={currency}
          onChange={handleChange}
          sx={{
            backgroundColor: colors.gray[2],
            borderRadius: '12px',
            height: '48px',
            border: `1px solid ${colors.gray[4]}`,
            ':hover, :focus': {
              backgroundColor: colors.gray[2],
            },
            '.MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
            '.MuiStack-root': {
              padding: '0 6px',
              flexDirection: 'row',
              gap: 0.5,
            },
            '.MuiSvgIcon-root':
              dir === 'rtl'
                ? {
                    left: '13px',
                    right: 'initial',
                  }
                : { right: '13px', left: 'initial' },
          }}
          disableUnderline
        >
          {/* {Object.keys(wallet.byCurrency).map((item) => (
            <MenuItem value={item} key={item}>
              <Stack>
                <Typography>{item}</Typography>
              </Stack>
            </MenuItem>
          ))} */}

          <MenuItem
            value={currency}
            sx={{
              '*': {
                fontFamily: `${
                  i18n.dir(i18n.language) === 'rtl'
                    ? 'Iranyekan'
                    : '"Nunito Sans"'
                }, sans-serif !important`,
              },
            }}
          >
            <Stack>
              <Typography sx={{ fontSize: 16 }}>
                {t(`currencies.${currency}`, currency)}
              </Typography>
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}
