import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { colors } from '@theme'
import { addLog } from 'helpers/logger'
import useT from 'hooks/useTranslation'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUi,
  hideLoginModal,
  hideMobileNavigation,
  showLoginModal,
  showMobileNavigation,
} from 'store/reducers/uiSlice'

function LoginRegister() {
  const theme = useTheme()
  const { t } = useT()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const dispatch = useAppDispatch()
  const { loginModal, mobileNavigation } = useAppSelector(getUi)

  const handleClick = () => {
    addLog('loginClick', { message: 'login button clicked' })

    if (breakpoint) {
      dispatch(hideMobileNavigation())
      dispatch(showLoginModal())
    } else {
      dispatch(hideLoginModal())
      dispatch(
        showMobileNavigation({
          open: true,
          index: NavIndex.Login,
        })
      )
    }
  }

  useEffect(() => {
    if (loginModal.open || mobileNavigation?.open) handleClick()
  }, [breakpoint])

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        '& > *:not(:last-child)': {
          marginInlineEnd: 1,
        },
      }}
    >
      <Button
        variant='outlined'
        color='secondary'
        size='small'
        onClick={handleClick}
      >
        <Typography variant='buttonMedium'>{t('user.login')}</Typography>
      </Button>

      <Button
        variant='contained'
        onClick={handleClick}
        color='secondary'
        size='small'
      >
        <Typography variant='buttonMedium' sx={{ color: colors.gray[12] }}>
          {t('user.register')}
        </Typography>
      </Button>
    </Stack>
  )
}

export default LoginRegister
