import { Backdrop, Box, CircularProgress } from '@mui/material'
import { colors } from '@theme'
import { useContext, useEffect, useState } from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import Game from './components/pages/Game'
import Home from './components/pages/Home'
// import i18n (needs to be bundled ;))
import { AuthResult } from '@alienclient/back-front'
import { connectSocket } from '@shared'
import { AlienContext } from 'App'
import { gameListApi, generalConfigWithLocalesApi } from 'apis/strapiApi'
import { Crisp } from 'crisp-sdk-web'
import useT from 'hooks/useTranslation'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { getGlobal, resetGlobal } from 'store/reducers/globalSlice'
import {
  clearToast,
  getUi,
  storeGameList,
  storeGeneralConfig,
  storeGeneralConfigWithLocales,
} from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import '../i18n'
import { GoogleLoginButton } from './components/GoogleLoginButton/GoogleLoginButton'
import Layout from './components/Layout/Layout'
import PageContent from './components/PageContent/PageContent'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import PageTemplate from './components/pages/PageTemplate/PageTemplate'
import PaymentVerification from './components/pages/PaymentVerification/PaymentVerification'
import Tournament from './components/pages/Tournament'
import FunTest from './components/pages/FunTest/FunTest'
import { TelegramLoginButton } from './components/Modals/TelegramLoginButton'
import { readLogs } from 'helpers/logger'
import LogsContainer from './components/LogsContainer'

const ParsRoyal = () => {
  const { i18n } = useTranslation()
  const { t } = useT()
  const alienContext = useContext(AlienContext)
  const global = useAppSelector(getGlobal)
  const { generalConfig, generalConfigWithLocales, toast, loginInfoList } =
    useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const auth = localStorage.getItem('auth')
  const isSocketConnected = global.player?.playerId
  const storedLanguage = localStorage.getItem('storedLanguage')

  const getGeneralConfigWithLocales = async () => {
    const data = await generalConfigWithLocalesApi()
    if (data) {
      dispatch(storeGeneralConfigWithLocales(data.data))
    }
  }

  const getGameListData = async () => {
    const gameListData = await gameListApi(i18n.language)
    if (gameListData) dispatch(storeGameList(gameListData.data))
  }

  useEffect(() => {
    getGeneralConfigWithLocales()
    getGameListData()

    alienContext?.setIsSocketConnected(false)

    if (
      i18n.language &&
      generalConfig.crispWebsiteId &&
      !generalConfig.showCustomSupportModal
    ) {
      Crisp.configure(generalConfig.crispWebsiteId, { locale: i18n.language })
    }
  }, [])

  useEffect(() => {
    const callConnectSocket = async (result: AuthResult) => {
      await connectSocket(result)
    }

    if (auth && !isSocketConnected) {
      callConnectSocket(JSON.parse(auth))
      setTimeout(() => {
        alienContext?.setIsLoading(false)
      }, 1000)
    }
  }, [auth])

  useEffect(() => {
    const getGeneralConfig = async () => {
      if (generalConfigWithLocales) {
        const localizedGeneralConfig =
          generalConfigWithLocales.localizations?.find(
            (item) => item.locale === i18n.language
          )
        dispatch(
          storeGeneralConfig(localizedGeneralConfig || generalConfigWithLocales)
        )
      }
    }
    getGeneralConfig()
  }, [generalConfigWithLocales])

  useEffect(() => {
    if (!generalConfig.showCustomSupportModal && generalConfig.crispWebsiteId) {
      if (generalConfig.defaultLanguage) {
        Crisp.configure(generalConfig.crispWebsiteId, {
          locale: generalConfig.defaultLanguage,
        })
      }
    }
  }, [generalConfig.crispWebsiteId])

  useEffect(() => {
    if (isSocketConnected) {
      alienContext?.setIsSocketConnected(true)
      alienContext?.setIsLoading(false)
      alienContext?.client.getTransactions(
        {},
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )
      alienContext?.client.getBets(
        {},
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )

      alienContext?.client.getUiState({}, { uuid: uuidv4() })
    } else {
      alienContext?.setIsSocketConnected(false)
    }
  }, [isSocketConnected])

  useEffect(() => {
    if (toast?.show && toast.message) {
      // TODO make message2 typesafe
      enqueueSnackbar(t(toast.message), {
        variant: toast.severity,
        message2: toast.message2,
      } as any)
      dispatch(clearToast())
    }
  }, [toast])

  useEffect(() => {
    if (generalConfig) {
      if (generalConfig.showComingSoon) {
        if (alienContext?.isSocketConnected)
          alienContext?.client.disconnect({
            uuid: uuidv4(),
            meta: { tabId: window.tabId },
          })
        dispatch(resetGlobal())
        localStorage.removeItem('group')
        localStorage.removeItem('auth')
      }
    }
  }, [generalConfig])

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <CssBaseline /> */}
      {(storedLanguage || generalConfig.defaultLanguage) && (
        <Routes>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route
              path='/'
              element={
                <Navigate
                  to={`/${storedLanguage || generalConfig.defaultLanguage}`}
                  replace
                />
              }
            />
            <Route path=':locale'>
              <Route index element={<Home />} />
              <Route
                path='googleLogin'
                element={
                  <PageContent>
                    <GoogleLoginButton />
                  </PageContent>
                }
              />
              <Route path='tournament' element={<Tournament />} />

              <Route path='play'>
                <Route path=':gameSlug' element={<Game />} />
              </Route>

              <Route path='payment'>
                <Route
                  path=':paymentGateway'
                  element={<PaymentVerification />}
                />
              </Route>
              <Route path=':page' element={<PageTemplate />}></Route>

              <Route path='funTest' element={<FunTest />} />
              <Route
                path='loginTest'
                element={
                  <PageContent>
                    <TelegramLoginButton isTest />
                    <GoogleLoginButton isTest />
                  </PageContent>
                }
              />
              <Route path='logs' element={<LogsContainer />} />
            </Route>

            <Route path={'*'} element={<Navigate to={'/'} replace />} />
          </Route>
        </Routes>
      )}

      {alienContext && (
        <Backdrop
          open={alienContext.isLoading}
          transitionDuration={{ exit: 1000 }}
          sx={{
            background: colors.gray[2],
            zIndex: (theme) => theme.zIndex.modal + 1,
          }}
        >
          <CircularProgress color='primary' role='presentation' />
        </Backdrop>
      )}
    </BrowserRouter>
  )
}

export default ParsRoyal
