import { faWallet } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { colors } from '@theme'
import { ActiveCurrencySelect } from 'parsroyal/components/Modals/ActiveCurrencySelect'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import {
  getUi,
  showMobileNavigation,
  showWalletModal,
} from 'store/reducers/uiSlice'

function WalletContainer() {
  const global = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const [activeCurrency, setActiveCurrency] = useState(
    global.player?.activeCurrency
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    setActiveCurrency(global.player?.activeCurrency)
  }, [global.player?.activeCurrency])

  const handleClick = () => {
    if (breakpoint) {
      dispatch(showWalletModal({ activeTab: 'deposit' }))
    } else {
      dispatch(
        showMobileNavigation({
          open: true,
          index: NavIndex.Wallet,
        })
      )
    }
  }

  return (
    <>
      <Stack
        sx={{
          '*': {
            borderRadius: '0 !important',
          },
          '.MuiInputBase-root.MuiFilledInput-root': {
            border: 'none',
            borderRadius: '0 !important',
          },
          borderRadius: 2,
          flexDirection: 'row',
          cursor: 'pointer',
          overflow: 'hidden',
          minWidth: 200,
        }}
      >
        {activeCurrency && <ActiveCurrencySelect />}
        <IconButton
          sx={{
            svg: { width: 15 },
            background: colors.secondary[6],
            px: 2,
            ':hover, :focus': {
              background: colors.secondary[7],
            },
          }}
          onClick={handleClick}
        >
          <Stack
            sx={{
              flexDirection: ' row',
              gap: 0.5,
              alignItems: 'center',
              '.fa-chevron-down': { width: 12 },
            }}
          >
            <FontAwesomeIcon icon={faWallet} />
          </Stack>
        </IconButton>
      </Stack>
    </>
  )
}

export default WalletContainer
