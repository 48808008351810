import React, { useEffect } from 'react'
import Login from './Login/Login'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideWalletModal } from 'store/reducers/uiSlice'
import { ModalComponent } from '.'
import { Wallet } from './Wallet'
import { useSearchParams } from 'react-router-dom'

const WalletModal = () => {
  const walletModal = useAppSelector(getUi).walletModal
  const dispatch = useAppDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (walletModal.activeTab)
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        wallet: walletModal.activeTab,
      })
  }, [walletModal.activeTab])

  return (
    <ModalComponent
      open={walletModal.open}
      height={755}
      title='nav.wallet'
      showBackButton
      showCloseButton
      onClose={() => dispatch(hideWalletModal())}
    >
      <Wallet />
    </ModalComponent>
  )
}

export default WalletModal
