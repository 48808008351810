import { Box, Stack } from '@mui/material'
import { colors } from '@theme'
import { Interweave } from 'interweave'
import HeroBanner from 'parsroyal/components/HeroBanner/HeroBanner'
import PageContent from 'parsroyal/components/PageContent/PageContent'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'

const PageTemplate = () => {
  const { i18n } = useTranslation()
  const { pageList, generalConfig } = useAppSelector(getUi)
  const { page: pageSlug } = useParams()
  const page = pageList.find((page) => page.slug === pageSlug)
  const dir = i18n.dir(i18n.language)

  if (!page) return <></>

  return (
    <>
      <Helmet>
        <title>
          {page.title_en} - {generalConfig.siteTitle}
        </title>
      </Helmet>
      <Box>
        <Stack>
          <HeroBanner
            images={{
              desktop: page?.img?.bannerImg?.url,
              mobile: page?.img?.bannerImgMobile?.url,
            }}
            sx={{
              '.banner__container': {
                height: ['105vw', 568, 290, 392, 475],
              },
            }}
          />
        </Stack>
        <PageContent
          maxWidth={'xl'}
          sx={{
            p: {
              textTransform: 'initial',
              color: colors.gray[9],
              fontWeight: 400,
            },
            h2: {
              fontSize: 24,
            },
            pt: { xs: 0 },
          }}
          title={page.title}
        >
          {page.content && <Interweave content={page.content} />}
        </PageContent>
      </Box>
    </>
  )
}

export default PageTemplate
