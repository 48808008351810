import React from 'react'
import TransactionList from './TransactionList'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideTransactionSideDrawer } from 'store/reducers/uiSlice'
import SideDrawer from 'parsroyal/components/SideDrawer/SideDrawer'
import { faLeftRight, faRightLeft } from '@fortawesome/pro-duotone-svg-icons'

const TransactionSideDrawer = () => {
  const transactionSideDrawer = useAppSelector(getUi).transactionSideDrawer
  const dispatch = useAppDispatch()
  return (
    <SideDrawer
      open={transactionSideDrawer.open}
      title='nav.transactions'
      icon={faRightLeft}
      onClose={() => dispatch(hideTransactionSideDrawer())}
    >
      <TransactionList />
    </SideDrawer>
  )
}

export default TransactionSideDrawer
