import { ThemeProvider } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { theme } from '@theme'
import dayjs from 'dayjs'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'store/store'
import App from './App'
import './styles.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const buildTimestamp = process.env.REACT_APP_BUILD_TIMESTAMP
console.log(
  `Built on: ${buildTimestamp && dayjs.unix(parseInt(buildTimestamp))}`
)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId='587376991352-dhkfa5p0q10s596c5q9m0pp2g83cnnhd.apps.googleusercontent.com'>
        <App />
      </GoogleOAuthProvider>
    </ThemeProvider>
  </Provider>
)
