import { PaymentGatewayName } from '@alienclient/back-front'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { formatCurrencyAmount } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getGlobal,
  resetGetPaymentUrlResponse,
  setGetPaymentUrlResponse,
} from 'store/reducers/globalSlice'
import { getUi, showToast } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'

interface TomanDepositProps {
  paymentGateway: PaymentGatewayName
}

export const TomanDeposit = (props: TomanDepositProps) => {
  const { getPaymentUrlResponse, paymentGateways, ...global } =
    useAppSelector(getGlobal)
  const { walletConfig, toast } = useAppSelector(getUi)
  const alienContext = useContext(AlienContext)
  const JBGateway = paymentGateways?.JB
  const { i18n } = useTranslation()
  const { t } = useT()
  const { locale } = useParams()
  const dir = i18n.dir(i18n.language)
  const [selectedAmount, setSelectedAmount] = useState(
    JBGateway?.depositPackages?.[0] ||
      walletConfig.depositAmount?.[0].amount ||
      0
  )
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const getPaymentURL = (amount: number) => {
    if (alienContext?.isSocketConnected) {
      alienContext.client.getPaymentUrl(
        {
          gateway: props.paymentGateway,
          amount,
          callbackUrl:
            `${window.location.origin}/${locale}/payment/${props.paymentGateway}`.toLocaleLowerCase(),
        },
        { uuid: uuidv4() }
      )
    }
  }

  useEffect(() => {
    dispatch(
      showToast({
        show: true,
        message: getPaymentUrlResponse?.message,
        severity: getPaymentUrlResponse?.success ? 'success' : 'error',
      })
    )

    if (getPaymentUrlResponse) {
      if (getPaymentUrlResponse?.success) {
        window.open(getPaymentUrlResponse.data)
      }
      dispatch(resetGetPaymentUrlResponse())
    }

    setIsLoading(false)
  }, [getPaymentUrlResponse])

  // TODO: Convert button type to submit and wrap component inside form tag
  const handleSubmit = () => {
    if (alienContext?.isSocketConnected) {
      getPaymentURL(selectedAmount)
      setIsLoading(true)
    }
  }

  return (
    <Box
      sx={{
        '.MuiButton-root.Mui-disabled': {
          background: '#443f52',
          pointerEvents: 'initial',
        },
      }}
    >
      <Stack
        sx={{
          gap: 1.5,
        }}
      >
        <Typography
          sx={{
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          }}
          variant='subtitle2'
        >
          {t('wallet.select_deposit')}
        </Typography>
        <Grid container dir='ltr'>
          {paymentGateways?.JB.depositPackages &&
          paymentGateways?.JB.depositPackages.length > 0
            ? paymentGateways?.JB.depositPackages?.map((number) => (
                <GridItem
                  key={number}
                  amount={number}
                  selectedAmount={selectedAmount}
                  setSelectedAmount={setSelectedAmount}
                />
              ))
            : walletConfig.depositAmount?.map(
                (item) =>
                  item.amount && (
                    <GridItem
                      key={item.id}
                      amount={item.amount}
                      selectedAmount={selectedAmount}
                      setSelectedAmount={setSelectedAmount}
                    />
                  )
              )}
        </Grid>
        {/* <Typography
        sx={{
          color: colors.gray[7],
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 600,
        }}
        variant="body"
      >
        {t('wallet.min_deposit_one')}
        <Box component={'span'} dir="ltr">
          <span style={{ display: 'inline-block' }}>
            {' '}
            {t('currencies.IRT')}{' '}
          </span>
          <span> {formatCurrencyAmount(100000, 'IRT')}</span>
        </Box>{' '}
        {t('wallet.min_deposit_two')}
      </Typography> */}
      </Stack>
      <LoadingButton
        fullWidth
        disabled={!selectedAmount}
        loading={isLoading}
        variant='contained'
        color='secondary'
        sx={{
          mt: 1,
          position: 'sticky',
          bottom: 16,
          zIndex: 10,
        }}
        onClick={() => handleSubmit()}
      >
        {t('wallet.deposit')}
      </LoadingButton>
    </Box>
  )
}

interface GridItemProps {
  amount: number
  selectedAmount: number
  setSelectedAmount: Dispatch<SetStateAction<number>>
  // onClick: () => void
}

const GridItem = (props: GridItemProps) => {
  const { amount, selectedAmount, setSelectedAmount } = props
  const alienContext = useContext(AlienContext)
  const { i18n } = useTranslation()
  const { t } = useT()
  const { locale } = useParams()
  const dir = i18n.dir(i18n.language)

  const handleClick = () => {
    if (amount !== selectedAmount) {
      setSelectedAmount(amount)
    }
  }

  return (
    <Grid
      item
      xs={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 0.5,
        '.MuiTypography-root': {
          fontSize: 14,
          color: colors.gray[9],
          fontWeight: 700,
        },
        '.MuiButton-root': {
          px: 1,
          py: 0.5,
          border: `1px solid ${colors.gray[4]}`,
          background: colors.gray[2],
          transition: 'opacity 150ms ease-in-out',
          '&:hover': {
            background: colors.gray[2],
            opacity: 0.8,
          },
          '&:disabled': {
            background: colors.gray[4],
            '.MuiTypography-root': {
              color: colors.gray[9],
            },
          },
        },
      }}
    >
      <Button
        fullWidth
        onClick={handleClick}
        disabled={selectedAmount === amount}
      >
        <Stack>
          <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
            {formatCurrencyAmount(props.amount, 'IRT')}
          </Typography>
          <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
            {t('currencies.IRT', 'IRT')}
          </Typography>
        </Stack>
      </Button>
    </Grid>
  )
}
