import {
  IconDefinition,
  faRectangleXmark,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AppBar,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { useTranslation } from 'react-i18next'

interface SideDrawerProps {
  icon?: IconDefinition
}

const SideDrawer = (props: SideDrawerProps & DrawerProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  return (
    <Drawer
      // disablePortal

      dir={dir}
      anchor={sm ? (dir === 'rtl' ? 'left' : 'right') : 'bottom'}
      open={props.open}
      onClose={props.onClose}
      sx={{
        zIndex: 1300,
        '*': {
          fontFamily: `${
            i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
          }, sans-serif !important`,
        },
        '.MuiDrawer-paper': {
          height: 1,
          borderRadius: sm
            ? dir === 'rtl'
              ? '0px 16px 16px 0px !important'
              : ' 16px 0px 0px 16px !important'
            : '0 !important',
          background: 'linear-gradient(180deg, #1D1929 0%, #2D2740 100%)',
        },
      }}
    >
      <AppBar position='sticky'>
        <Toolbar sx={{ background: colors.gray[3] }}>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              svg: { color: colors.gray[12] },
              width: 1,
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {props.icon && <FontAwesomeIcon icon={props.icon} />}
              <Typography variant='headline'>{t(props.title || '')}</Typography>
            </Stack>
            <IconButton
              onClick={(e) => {
                if (props.onClose) props.onClose(e, 'backdropClick')
              }}
              sx={{ svg: { color: colors.gray[12] } }}
            >
              <FontAwesomeIcon icon={faRectangleXmark} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Stack
        sx={{
          width: [1, 375],
          pb: 1.5,
          px: 2,
        }}
      >
        <Stack mt={4} gap={2}>
          {props.children}
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default SideDrawer
