import {
  Box,
  BoxProps,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { navDrawerWidth } from '@shared/constants'
import useBreakpoint from 'hooks/useBreakpoint'

const B2BContainer = (props: BoxProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const currentBreakpoint = useBreakpoint().getBreakPointName()
  return (
    <Box {...props}>
      <Container
        // maxWidth={'xl'}
        id='sliderContainer'
        sx={{
          display: 'flex',
          position: 'relative',
          p: '0 !important',
          ...(currentBreakpoint && {
            maxWidth: `${
              theme.breakpoints.values[currentBreakpoint] - navDrawerWidth
            }px !important`,
          }),
        }}
        fixed={md}
      >
        {props.children}
      </Container>
    </Box>
  )
}

export default B2BContainer
