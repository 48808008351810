import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { USDTIcon } from '@svg'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { formatCurrencyAmount } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal, updateActiveCurrency } from 'store/reducers/globalSlice'
import {
  getUi,
  showMobileNavigation,
  showWalletModal,
} from 'store/reducers/uiSlice'
import store from 'store/store'
import { v4 as uuidv4 } from 'uuid'

export const ActiveCurrencySelect = () => {
  const alienContext = useContext(AlienContext)
  const global = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const wallet = global.player?.wallet
  const activeCurrency = global.player?.activeCurrency
  const currencies = global.currencies
  const [currency, setCurrency] = useState(activeCurrency || 'USD')
  const hasMultipleCurrencies = Object.values(wallet?.byCurrency || {}).length
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (activeCurrency) {
      setCurrency(activeCurrency)
    }
  }, [activeCurrency])

  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  const handleChange = (e: SelectChangeEvent) => {
    setCurrency(e.target.value)
    alienContext?.client.setActiveCurrency(
      { currency: e.target.value },
      { uuid: uuidv4(), meta: { tabId: window.tabId } }
    )
    store.dispatch(updateActiveCurrency(e.target.value))
  }

  const handleClick = () => {
    if (generalConfig.allowActiveCurrencyChange) setOpen((prev) => !prev)
  }

  const handleWalletClick = () => {
    if (!generalConfig.allowActiveCurrencyChange) {
      if (breakpoint) {
        dispatch(showWalletModal({ activeTab: 'deposit' }))
      } else {
        dispatch(
          showMobileNavigation({
            open: true,
            index: NavIndex.Wallet,
          })
        )
      }
    }
  }

  if (!wallet?.byCurrency) return <></>

  const renderCurrencies = () => {
    const menuItemList = []

    for (const [key, value] of Object.entries(wallet.byCurrency)) {
      menuItemList.push(
        <MenuItem
          key={key}
          value={key}
          sx={{
            p: '5px 12px',
            '*': {
              fontFamily: `${
                i18n.dir(i18n.language) === 'rtl'
                  ? 'Iranyekan'
                  : '"Nunito Sans"'
              }, sans-serif !important`,
            },
          }}
        >
          <Stack
            dir='ltr'
            sx={{
              flexDirection: 'row',
              justifyContent: dir === 'rtl' ? 'flex-end' : 'flex-start',
              alignItems: 'center',
              height: 28,
              svg: { width: 20 },
              gap: 0.5,
            }}
          >
            {key === 'USDT' && <USDTIcon />}
            {key === 'IRT' ? (
              <Typography variant='bodySmall'>
                {dir === 'rtl' ? 'تومان' : key}
              </Typography>
            ) : (
              <Typography variant='bodySmall'>{key}</Typography>
            )}
            <Typography variant='bodySmallBold'>
              {formatCurrencyAmount(
                value.balance,
                key,
                currencies[key]?.acceptedDecimals
              )}
            </Typography>
          </Stack>
        </MenuItem>
      )
    }

    return menuItemList
  }

  return (
    <Stack
      sx={{
        flexDirection: ' row',
        alignItems: 'center',
        width: 1,
        // paddingInlineStart: 1.5,
        // '.MuiSelect-icon': {
        //   display: hasMultipleCurrencies ? 'block' : 'none',
        // },

        '.MuiInputBase-root': {
          background: 'none !important',
          cursor: 'pointer !important',
        },

        '.MuiSelect-select': {
          paddingInlineStart: '8px !important',
          cursor: 'pointer !important',
          pr: `12px !important`,
          '&:focus': {
            background: 'initial',
          },
        },

        '.Mui-disabled': {
          opacity: 1,
          WebkitTextFillColor: 'initial !important',
          backgroundColor: colors.gray[3],
        },
        backgroundColor: colors.gray[3],
        ':hover, :focus': {
          opacity: 0.8,
        },
        svg: {
          cursor: 'pointer',
        },
      }}
      onClick={handleWalletClick}
    >
      <FormControl fullWidth variant='filled'>
        <InputLabel
          id='selectActiveCurrencyLabel'
          sx={{
            fontSize: 13,
            fontWeight: 400,
            top: -3,
            ...(dir === 'rtl'
              ? { right: 9, left: 'initial' }
              : { left: -5, right: 'initial' }),
          }}
        >
          {t('user.balance')}
        </InputLabel>

        <Select
          disableUnderline
          disabled={!generalConfig.allowActiveCurrencyChange}
          open={open}
          id='selectActiveCurrency'
          label='selectActiveCurrencyLabel'
          size='small'
          value={currency}
          onChange={handleChange}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          sx={{
            borderRadius: '12px',
            height: '42px',
            '.MuiStack-root': {
              flexDirection: 'row',
              gap: 0.5,
            },
            '.MuiSvgIcon-root': {
              // display: 'none !important',
              ...(dir === 'rtl'
                ? {
                    left: '7px',
                    right: 'initial',
                  }
                : { right: '7px', left: 'initial' }),
            },
          }}
          IconComponent={() => (
            <Stack sx={{ paddingInlineEnd: 1 }} onClick={handleClick}>
              <FontAwesomeIcon icon={faChevronDown} />
            </Stack>
          )}
        >
          {renderCurrencies()}
        </Select>
      </FormControl>
    </Stack>
  )
}
