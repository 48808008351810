import { Box, Button } from '@mui/material'
import HeroSlider from 'parsroyal/components/HeroSlider/HeroSlider'
import PageContent from 'parsroyal/components/PageContent/PageContent'
import { LiveTournamentStatus } from 'parsroyal/components/Tournament/LiveTournamentStatus'
import BannerOne from 'parsroyal/sections/BannerOne/BannerOne'
import GamesCollectionSlider from 'parsroyal/sections/GamesCollectionSlider/GamesCollectionSlider'
import JoinSection from 'parsroyal/sections/JoinSection/JoinSection'
import BannerTwo from 'parsroyal/sections/BannerTwo/BannerTwo'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import GameBanner from 'parsroyal/sections/GameBanner/GameBanner'
import { useContext, useEffect } from 'react'
import { AlienContext } from 'App'
import { getUi } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import TournamentHistory from 'parsroyal/components/Tournament/TournamentHistory'

function Home() {
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const { generalConfig, tournamentSection } = useAppSelector(getUi)
  const alienContext = useContext(AlienContext)

  return (
    <>
      <Helmet>
        <title>{generalConfig.siteTitle}</title>
      </Helmet>
      <Box>
        <HeroSlider />
        <PageContent>
          {generalConfig.showGameSlider && <GamesCollectionSlider />}
          {generalConfig.showGameBanner && <GameBanner />}
          {generalConfig.showBannerOne && <BannerOne />}
          {generalConfig.showBannerTwo && <BannerTwo />}

          <LiveTournamentStatus />
          <JoinSection />
          {tournamentSection.history?.statsList && <TournamentHistory />}
        </PageContent>
      </Box>
    </>
  )
}

export default Home
