import { Stack, useMediaQuery, useTheme } from '@mui/material'
import {
  COOKIE_VALIDITY,
  gameplayBreakpoint,
  navDrawerWidth,
} from '@shared/constants'
import { AlienContext } from 'App'
import {
  footerApi,
  gameListApi,
  loginInfoListApi,
  pageListApi,
  tournamentSectionApi,
  verificationApi,
  walletConfigApi,
  walletInfoListApi,
} from 'apis/strapiApi'

import { Crisp } from 'crisp-sdk-web'
import { getAffiliateCookies, setCookie } from 'helpers/helpers'
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGame } from 'store/reducers/gameSlice'
import {
  getUi,
  setComingSoon,
  storeFooter,
  storeGameList,
  storeGeneralConfig,
  storeLoginInfoList,
  storePageList,
  storeTournamentSection,
  storeVerification,
  storeWalletConfig,
  storeWalletInfoList,
} from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import ComingSoon from '../ComingSoon/ComingSoon'
import BetsSideDrawer from '../Drawer/MyBetsDrawer/BetsSideDrawer'
import NotificationSideDrawer from '../Drawer/NotificationsDrawer/NotificationsDrawer'
import TransactionSideDrawer from '../Drawer/TransactionsDrawer/TransactionSideDrawer'
import VerificationSideDrawer from '../Drawer/VerificationDrawer/VerificationSideDrawer'
import Footer from '../Footer'
import Header from '../Header'
import LiveSupportModal from '../Modals/LiveSupportModal'
import LoginModal from '../Modals/Login/LoginModal'
import PopupModal from '../Modals/PopupModal'
import WalletModal from '../Modals/WalletModal'

const Layout = (props: PropsWithChildren) => {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const { locale } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const { generalConfig, generalConfigWithLocales } = useAppSelector(getUi)
  const game = useAppSelector(getGame)
  const showUiParam = searchParams.get('showUi')

  const idParams = {
    affId: searchParams.get('affId'),
    refId: searchParams.get('refId'),
    channelId: searchParams.get('channelId'),
  }

  const { affId, channelId, refId } = getAffiliateCookies()

  const getInitContent = async () => {
    try {
      const [
        pageListData,
        walletConfigData,
        gameListData,
        verificationData,
        footerData,
        tournamentData,
        walletInfoData,
        loginInfoData,
      ] = await Promise.all([
        pageListApi(locale),
        walletConfigApi(locale),
        gameListApi(locale),
        verificationApi(locale),
        footerApi(locale),
        tournamentSectionApi(locale),
        walletInfoListApi(locale),
        loginInfoListApi(locale),
      ])

      if (pageListData) dispatch(storePageList(pageListData.data))
      if (walletConfigData) dispatch(storeWalletConfig(walletConfigData.data))
      if (gameListData) dispatch(storeGameList(gameListData.data))
      if (verificationData) dispatch(storeVerification(verificationData.data))
      if (footerData) dispatch(storeFooter(footerData.data))
      if (tournamentData) dispatch(storeTournamentSection(tournamentData.data))
      if (walletInfoData) dispatch(storeWalletInfoList(walletInfoData.data))
      if (loginInfoData) dispatch(storeLoginInfoList(loginInfoData.data))
    } catch (error) {
      // Handle errors here
      console.error('An error occurred:', error)
    } finally {
      if (!localStorage.getItem('auth')) alienContext?.setIsLoading(false)
    }
  }

  useLayoutEffect(() => {
    // Set tab id
    window.tabId = uuidv4()
  }, [])

  useEffect(() => {
    if (idParams.affId && !affId) {
      setCookie('affId', idParams.affId, COOKIE_VALIDITY)
    }
    if (idParams.refId && !refId) {
      setCookie('refId', idParams.refId, COOKIE_VALIDITY)
    }
    if (idParams.channelId && !channelId) {
      setCookie('channelId', idParams.channelId, COOKIE_VALIDITY)
    }
  }, [idParams])

  useEffect(() => {
    if (showUiParam === 'true') {
      dispatch(setComingSoon(false))
    } else if (showUiParam === 'false') {
      dispatch(setComingSoon(true))
    }
  }, [generalConfig.showComingSoon])

  useEffect(() => {
    if (generalConfig.availableLanguages && locale) {
      if (
        !generalConfig.availableLanguages.split(',').includes(locale) &&
        generalConfig.defaultLanguage
      ) {
        navigate(generalConfig.defaultLanguage)
      }
    }
  }, [generalConfig.availableLanguages])

  useEffect(() => {
    if (locale) {
      localStorage.setItem('storedLanguage', locale)
      i18n.changeLanguage(locale)
      document.body.dir = i18n.dir(locale)
      const getGeneralConfig = async () => {
        if (generalConfigWithLocales) {
          const localizedGeneralConfig =
            generalConfigWithLocales.localizations?.find(
              (item) => item.locale === i18n.language
            )
          dispatch(
            storeGeneralConfig(
              localizedGeneralConfig || generalConfigWithLocales
            )
          )
        }
      }
      getGeneralConfig()
      if (
        localStorage.getItem('i18nextLng') ||
        generalConfig.availableLanguages?.includes(locale)
      ) {
        getInitContent()
      }
      if (
        !generalConfig.showCustomSupportModal &&
        generalConfig.crispWebsiteId
      ) {
        Crisp.configure(generalConfig.crispWebsiteId, { locale })
      }
    }
  }, [locale])

  // Close game socket connection if outside of game page
  useEffect(() => {
    if (alienContext?.isSocketConnected)
      if (!location.pathname.includes('play') && game.activeGameId) {
        alienContext?.client.closeGame(
          { gameId: game.activeGameId },
          { uuid: uuidv4() }
        )
      }
  }, [location])

  useEffect(() => {
    if (alienContext?.isSocketConnected) {
      if (locale) {
        alienContext?.client.setActiveLanguage(
          { language: locale },
          { uuid: uuidv4() }
        )
      }
    }
  }, [alienContext?.isSocketConnected, locale])

  return (
    <>
      <Stack
        sx={{
          minHeight: 1,
          ...(breakpoint &&
            !generalConfig.showComingSoon && {
              width: `calc(100% - ${navDrawerWidth + 11}px)`,
              marginInlineStart: 'auto',
            }),
          '*': {
            fontFamily: `${
              i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },
        }}
        className='nonSelect'
      >
        <Header />
        {generalConfig.showComingSoon ? (
          <ComingSoon />
        ) : (
          <>
            <Stack
              sx={{
                flex: 1,
                overflowY: 'scroll',

                height: 1,
              }}
            >
              {props.children}
            </Stack>
            <Footer />
          </>
        )}
      </Stack>
      {/* <ReduxModal />
      <SideDrawer /> */}
      <LiveSupportModal />
      <LoginModal />
      <WalletModal />
      <TransactionSideDrawer />
      <BetsSideDrawer />
      <NotificationSideDrawer />
      <VerificationSideDrawer />
      {!generalConfig.showComingSoon && <PopupModal />}
    </>
  )
}

export default Layout
