import { faClipboard, faCopy } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import React, { useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'

const UserProfile = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const global = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const [copied, setCopied] = useState(false)
  const { t } = useT()

  return (
    <Stack sx={{ alignItems: 'center', gap: 0.5, textAlign: 'center' }}>
      <Stack
        sx={{
          position: 'relative',
          height: 68,
          width: 68,
          img: { zIndex: 1 },
        }}
      >
        <img src='/assets/png/user.png' alt='' />
      </Stack>
      <Stack sx={{ my: 1 }}>
        <Typography variant='bodyXSmall' color={colors.gray[6]}>
          {t('label.nickname')}
        </Typography>
        <Typography variant='bodyBold'>{global.player?.nickname}</Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: colors.gray[8],
          border: `1px solid ${colors.gray[6]}`,
          borderRadius: 4,
          height: 26,
        }}
      >
        <Typography
          variant='caption'
          sx={{
            color: colors.gray[8],
            fontSize: 11,
            paddingInlineStart: 1,
            span: { fontWeight: 700, paddingInlineEnd: 0.75 },
          }}
        >
          <span> {t('label.player_id')}:</span>
          <span style={{ display: 'inline-block' }}>
            {' '}
            {global.player?.playerId}
          </span>
        </Typography>
        <IconButton
          sx={{
            paddingInlineStart: 0,
            ':hover, :focus': { background: 'none' },
          }}
          onClick={() => {
            setCopied(true)
            navigator.clipboard.writeText(global.player?.playerId || '')

            setTimeout(() => {
              setCopied(false)
            }, 1000)
          }}
        >
          <FontAwesomeIcon
            icon={copied ? faClipboard : faCopy}
            size='2xs'
            color={colors.gray[7]}
            fixedWidth
          />
        </IconButton>
      </Stack>
      <Typography
        variant='caption'
        sx={{
          color: colors.gray[6],
          fontSize: 10,
          fontWeight: 600,
          span: { fontWeight: 700, paddingInlineEnd: 0.5 },
        }}
      >
        {global.player?.emailAddress && (
          <>
            <span> {t('label.email_address')}:</span>
            {global.player?.emailAddress}
          </>
        )}
        {global.player?.telegramId && (
          <>
            <span> {t('label.telegram_id')}:</span>
            {global.player?.telegramId}
          </>
        )}
      </Typography>
    </Stack>
  )
}

export default UserProfile
