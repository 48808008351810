import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { colors } from '@theme'
import i18n from 'i18n'
import {
  CustomContentProps,
  SnackbarContent,
  closeSnackbar,
  useSnackbar,
} from 'notistack'
import { forwardRef } from 'react'

export interface CustomSnackbarProps extends CustomContentProps {
  message2?: string
}

const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar()
    return (
      <SnackbarContent ref={ref}>
        <CustomSnackbarContent {...props} id={id} />
      </SnackbarContent>
    )
  }
)

const CustomSnackbarContent = (props: CustomSnackbarProps) => {
  const theme = useTheme()
  let variantStyles = {
    backgroundColor: theme.palette.info.light,
    iconColor: theme.palette.info.dark,
    icon: faCircleInfo,
  }

  let message = props.message

  if (typeof message === 'string') {
    message = message
      ?.split('\n')
      .map((paragraph, index) => <p key={index}>{paragraph}</p>)
  }
  switch (props.variant) {
    case 'success':
      variantStyles = {
        backgroundColor: colors.tertiary[9],
        iconColor: colors.tertiary[3],
        icon: faCircleCheck,
      }
      break
    case 'error':
      variantStyles = {
        backgroundColor: '#FF6768',
        iconColor: '#BA3B33',
        icon: faCircleExclamation,
      }
      break

    default:
      break
  }

  return (
    <Card
      sx={{
        '*': {
          fontFamily: `${
            i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
          }, sans-serif !important`,
        },
        minWidth: 245,
        borderRadius: 3,
        background: variantStyles.backgroundColor,
        svg: { color: variantStyles.iconColor },
      }}
      onClick={() => closeSnackbar(props.id)}
    >
      <Stack sx={{ flexDirection: 'row' }}>
        <Stack
          sx={{
            p: '6px 8px',
            svg: { width: 24, height: 24 },
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={variantStyles.icon} />
        </Stack>
        <Card
          sx={{
            p: '6px 10px',
            width: 1,
            borderRadius: 0,
          }}
        >
          <Stack
            sx={{ height: 1, justifyContent: 'center', paddingInlineEnd: 3 }}
          >
            <Typography variant='bodySmallBold'>{props.message2}</Typography>
            <Typography variant={props.message2 ? 'caption' : 'bodySmallBold'}>
              {message}
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Card>
  )
}

CustomSnackbar.displayName = 'Success Snackbar'

export default CustomSnackbar
