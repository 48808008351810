import { useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

export default function ScrollToTop() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6

    if (!searchParams.get('wallet')) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
      })
    }
  }, [location])

  return null
}
