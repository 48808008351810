import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons'
import Section from '../../components/Section/Section'

// Import Swiper React components
import { SwiperSlide } from 'swiper/react'

import { Box } from '@mui/material'
import { gcSkeletonList } from '@shared/constants'
import { gameListApi } from 'apis/strapiApi'
import GameCardSkeleton from 'parsroyal/components/GameCard/GameCardSkeleton'
import { Game } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CollectionSlider } from '../../components/CollectionSlider/CollectionSlider'
import { GameCard } from '../../components/GameCard/GameCard'

const GamesCollectionSlider = () => {
  const { locale } = useParams()
  const [gameList, setGameList] = useState<Game[]>()

  useEffect(() => {
    const getGameList = async () => {
      const data = await gameListApi(locale)
      if (data) setGameList(data.data)
    }
    getGameList()
  }, [locale])

  return (
    <Section
      // title='section.crash_collection'
      title='nav.games'
      icon={faRocketLaunch}
      // viewAllUrl='#'
    >
      <CollectionSlider>
        {gameList
          ? gameList.map((gameCard) => (
              <SwiperSlide key={gameCard.id}>
                <Box
                  sx={{
                    px: [0.75, 0.5, 0.75],
                  }}
                >
                  <GameCard {...gameCard} />
                </Box>
              </SwiperSlide>
            ))
          : gcSkeletonList.map((gameCard, index) => (
              <SwiperSlide key={index}>
                <Box sx={{ px: [0.75, 0.5, 0.75] }}>
                  <GameCardSkeleton />
                </Box>
              </SwiperSlide>
            ))}
      </CollectionSlider>
    </Section>
  )
}

export default GamesCollectionSlider
