import { PlayerWalletCurrency } from '@alienclient/back-front'
import { faClipboard } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { formatCurrencyAmount } from 'helpers/helpers'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import {
  getUi,
  showToast,
  showVerificationSideDrawer,
} from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import { CurrencySelectProps } from '../../CurrencySelect'
import { PaymentGatewayProps } from '../../PaymentGatewaySelect'

import WAValidator from 'multicoin-address-validator'
import useT from 'hooks/useTranslation'
import { LoadingButton } from '@mui/lab'
import { SelectForm } from './Components/SelectForm'
import { PlayerVerificationStatus } from '@alienclient/back-front/lib/enum/player-verification-status.enum'

interface WithdrawInputProps {
  activeCurrency: string
  activeCurrencyObj: PlayerWalletCurrency
  minAmount: number
  feeAmount: number
}

export const CryptoInput = (
  props: PaymentGatewayProps & CurrencySelectProps & WithdrawInputProps
) => {
  const { paymentGateway, currency, minAmount } = props
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const alienContext = useContext(AlienContext)
  const { toast, generalConfig } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const { player, currencies } = useAppSelector(getGlobal)
  const wallet = player?.wallet
  const activeCurrency = player?.activeCurrency || 'USD'
  const currencyInUSDT = player?.wallet.byCurrency['USDT']
  let validate = WAValidator.validate

  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [withdrawWallet, setWithdrawWallet] = useState(activeCurrency || 'USD')
  const [currencyWithdraw, setCurrencyWithdraw] = useState(currency || 'USD')

  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null)
  const [walletAddress, setWalletAddress] = useState('')

  const activeWalletCurrencyBalance =
    wallet?.byCurrency[withdrawWallet].balance || 0

  const handleWithdraw = () => {
    const verifications = player?.kyc?.verifications

    if (!verifications) return // Return early if verifications is null or undefined

    const verificationRequired = verifications.length > 0

    if (verificationRequired) {
      const isPlayerVerified = verifications
        .filter((item) => item.required)
        .every((item) => item.status === PlayerVerificationStatus.CONFIRMED)

      if (!isPlayerVerified) {
        dispatch(
          showToast({
            message: 'wallet.withdraw_verification',
            show: true,
          })
        )
        dispatch(showVerificationSideDrawer())
        return // Return early if not verified
      }
    }

    if (!withdrawAmount || !activeCurrency) return // Return early if missing amount or currency

    setIsLoading(true)
    alienContext?.client.addCpgWithdraw(
      {
        activeCurrency: withdrawWallet,
        amount: withdrawAmount,
        destinationCurrency: currencyWithdraw,
        destinationAddress: walletAddress,
      },
      {
        uuid: uuidv4(),
        meta: {
          currency: t(`currencies.${activeCurrency}`, activeCurrency),
          amount: withdrawAmount,
        },
      }
    )

    if (withdrawAmount >= minAmount && walletAddress) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  function handleWalletAddress(e: any) {
    // const isValid = validate(e.target.value, 'USDT')
    // setIsValidWalletAddress(isValid)
    setWalletAddress(e.target.value)
  }
  useEffect(() => {
    if (withdrawAmount && activeCurrency) {
      if (
        withdrawAmount >= minAmount &&
        walletAddress
        // &&
        // isValidWalletAddress
      ) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(true)
    }
  }, [withdrawAmount, walletAddress, minAmount, activeCurrency])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Delete',
      'Enter',
      'Backspace',
    ]

    switch ('IRT') {
      case 'IRT':
        break
      default:
        allowedKeys.push(...allowedKeys, '.')
    }

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (toast.show) {
      setIsLoading(false)
    }
  }, [toast])

  const handleChangeWithdrawWallet = (e: SelectChangeEvent) => {
    setWithdrawWallet(e.target.value)
    setWithdrawAmount(null)
  }

  const handleChangeCurrencyWithdraw = (e: SelectChangeEvent) => {
    setCurrencyWithdraw(e.target.value)
  }

  const updateWithdrawAmount = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value

    const newValue = parseFloat(value)

    if (!isNaN(newValue)) {
      if (newValue <= activeWalletCurrencyBalance) {
        setWithdrawAmount(newValue)
      }
    } else {
      setWithdrawAmount(null)
    }
  }

  if (!wallet?.byCurrency) return <></>

  const renderCurrencies = () => {
    return Object.entries(wallet.byCurrency).map(([key, value]) => (
      <MenuItem
        key={key}
        value={key}
        sx={{
          p: '5px 12px',
          '*': {
            fontFamily: `${
              i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },
        }}
      >
        <Stack
          dir='ltr'
          sx={{
            flexDirection: 'row',
            justifyContent: dir === 'rtl' ? 'flex-end' : 'flex-start',
            alignItems: 'center',
            height: 28,
            gap: 0.5,
          }}
        >
          {key === 'IRT' ? (
            <Typography sx={{ fontSize: 16 }}>
              {dir === 'rtl' ? 'تومان' : key}
            </Typography>
          ) : (
            <Typography sx={{ fontSize: 16 }}>{key}</Typography>
          )}
        </Stack>
      </MenuItem>
    ))
  }

  const menuList = () => {
    return (
      <MenuItem
        key={currency}
        value={currency}
        sx={{
          '*': {
            fontFamily: `${
              i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },
        }}
      >
        <Stack>
          <Typography sx={{ fontSize: 16 }}>
            {t(`currencies.${currency}`, currency)}
          </Typography>
        </Stack>
      </MenuItem>
    )
  }

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'nowrap',
          gap: '8px',
          width: '100%',
        }}
      >
        <SelectForm
          label={t(`wallet.select_wallet`) || ''}
          disabled={!generalConfig.allowActiveCurrencyChange}
          value={withdrawWallet}
          onChange={handleChangeWithdrawWallet}
        >
          {renderCurrencies()}
        </SelectForm>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: '5px',
            height: '48px',
            background: colors.gray[4],
            borderRadius: '6px',
            padding: '6px',
            flex: 1,
          }}
        >
          <Typography
            dir='ltr'
            variant='caption'
            sx={{
              color: colors.gray[7],
              lineHeight: '12px',
            }}
          >
            {t(`wallet.amount`)}
          </Typography>

          <Typography
            dir='ltr'
            sx={{
              fontSize: 16,
              lineHeight: '16px',
              // color: colors.gray[8],
            }}
          >
            {formatCurrencyAmount(activeWalletCurrencyBalance, withdrawWallet)}
          </Typography>
        </Box>
      </Stack>

      <FormControl>
        <TextField
          dir='ltr'
          value={withdrawAmount || ''}
          focused={withdrawAmount ? true : undefined}
          label={t(`wallet.enter_amount`) || ''}
          onChange={updateWithdrawAmount}
          onKeyDown={handleKeyDown}
          sx={{
            transition: 'none',
            '.MuiInputBase-root': {
              border: `1px solid ${colors.gray[4]}`,
              backgroundColor: colors.gray[2],
            },
            fieldset: {
              border: `none`,
            },
            input: {
              boxSizing: 'border-box',
              height: 48,
              padding: '12px 20px 0 20px',
            },
          }}
          InputLabelProps={{
            sx: {
              '&.MuiInputLabel-shrink': {
                transition: 'ease .2s',
                left: 7,
                right: 'initial',
              },
              left: 7,
              right: 'initial',
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                size='small'
                sx={{
                  py: 0.5,
                  px: 1,
                  minWidth: 35,
                  marginInlineEnd: dir === 'rtl' ? 0.5 : 0,
                }}
                onClick={() =>
                  setWithdrawAmount(
                    Math.floor(activeWalletCurrencyBalance) || 0
                  )
                }
              >
                <Typography
                  dir='ltr'
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: colors.primary[3],
                    textTransform: 'uppercase',
                  }}
                >
                  {t('wallet.max')}
                </Typography>
              </IconButton>
            ),
          }}
        />
      </FormControl>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'nowrap',
          gap: '8px',
          width: '100%',
        }}
      >
        <SelectForm
          label={t(`wallet.select_currency`) || ''}
          value={currencyWithdraw}
          onChange={handleChangeCurrencyWithdraw}
        >
          {menuList()}
        </SelectForm>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: '5px',
            width: '49%',
            height: '48px',
            background: colors.gray[4],
            borderRadius: '6px',
            padding: '6px',
          }}
        >
          <Typography
            dir='ltr'
            variant='caption'
            sx={{
              color: colors.gray[7],
              lineHeight: '12px',
            }}
          >
            {t(`wallet.exchange`)}
          </Typography>

          <Typography
            dir='ltr'
            sx={{
              fontSize: 16,
              lineHeight: '16px',
            }}
          >
            {/* Shold be refactor */}
            {wallet?.inUSD.balance &&
              formatCurrencyAmount(
                (withdrawAmount ?? 0) *
                  currencies[withdrawWallet].inUSD *
                  currencies[currency].USDin,
                currency
              )}
          </Typography>
        </Box>
      </Stack>

      <FormControl>
        <TextField
          dir='ltr'
          value={walletAddress}
          placeholder={t('wallet.enter_address') || ''}
          onChange={handleWalletAddress}
          sx={{
            input: {
              height: 48,
              py: 0,
              fontSize: 12,
            },
            '.MuiInputBase-root': {
              border: `1px solid ${colors.gray[4]}`,
              px: 1,
              backgroundColor: colors.gray[2],
            },
            fieldset: {
              border: `none`,
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                size='small'
                sx={{
                  py: 0.5,
                  px: 1,
                  minWidth: 35,
                  marginInlineEnd: dir === 'rtl' ? 0.5 : 0,
                }}
                onClick={() =>
                  setWalletAddress(currencyInUSDT?.address?.TRON || '')
                }
              >
                <FontAwesomeIcon icon={faClipboard} />
              </IconButton>
            ),
          }}
        />
      </FormControl>

      <LoadingButton
        disabled={isDisabled}
        loading={isLoading}
        variant='contained'
        onClick={() => handleWithdraw()}
        sx={{
          fontSize: 14,
          fontWeight: 700,
        }}
      >
        {t('wallet.withdraw')}
      </LoadingButton>

      <Stack
        sx={{
          gap: 1.5,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          '.MuiTypography-root': {
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          },
        }}
      >
        <Typography
          sx={{
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {t('wallet.transaction_fee')} ={' '}
        </Typography>

        <Stack>
          <Typography
            variant='buttonMedium'
            sx={{
              color: colors.gray[7],
              textAlign: 'center',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            ${props.feeAmount}
          </Typography>
          <Typography dir='ltr' variant='caption'>
            <span style={{ display: 'inline-block' }}>
              {t(`currencies.${activeCurrency}`, activeCurrency)}
            </span>
            {activeCurrency &&
              currencies &&
              currencies[activeCurrency]?.inUSD > 0 &&
              formatCurrencyAmount(
                Math.round(props.feeAmount / currencies[activeCurrency]?.inUSD)
              )}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
