import { Bet, BetStatus } from '@alienclient/back-front'
import { Card, Chip, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { formatCurrencyAmount } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { DataItem } from '../../DataItem/DataItem'

const BetCard = (props: Bet) => {
  const { currencies } = useAppSelector(getGlobal)
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  let statusChipColor:
    | 'success'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'warning' = 'default'

  switch (props.status) {
    case BetStatus.WON:
      statusChipColor = 'success'
      break
    case BetStatus.LOST:
      statusChipColor = 'error'
      break
    case BetStatus.CANCELED:
      statusChipColor = 'warning'
      break
    case BetStatus.CASHBACK:
      statusChipColor = 'default'
      break
    case BetStatus.OPEN:
      statusChipColor = 'info'
      break

    default:
      break
  }

  return (
    <Stack sx={{ gap: 2, textAlign: 'center' }}>
      <Card sx={{ borderRadius: 5 }}>
        <Stack
          sx={{
            p: 3,
            gap: 2,
          }}
        >
          <DataItem
            label={t('label.game')}
            value={props.gameTitle}
            variant='highlight'
          />
          <DataItem
            label={t('label.placed_at')}
            value={dayjs(props.placedAt).format('h:mm a')}
            variant='caption'
          />
          {props.gameData?.mode && (
            <DataItem
              label={t('label.mode')}
              value={t(`games.mode.${props.gameData.mode}`)}
              sx={{ textTransform: 'capitalize' }}
              variant='caption'
            />
          )}
          <DataItem
            label={t('label.bet_id')}
            value={props.id}
            showCopyIcon
            showValue={false}
          />
          <DataItem
            label={t('label.currency')}
            value={
              props.currency === 'IRT'
                ? dir === 'rtl'
                  ? 'تومان'
                  : props.currency
                : props.currency
            }
          />
          <DataItem
            label={t('label.bet_amount')}
            value={formatCurrencyAmount(
              props.betAmount,
              props.currency,
              currencies[props.currency]?.acceptedDecimals
            )}
          />

          {props.status === BetStatus.WON && (
            <DataItem label={t('label.win_odds')} value={`${props.winOdds}x`} />
          )}
          {props.winAmount && props.status !== BetStatus.CANCELED ? (
            <DataItem
              label={t('label.win_amount')}
              value={formatCurrencyAmount(
                props.winAmount,
                props.currency,
                currencies[props.currency]?.acceptedDecimals
              )}
            />
          ) : (
            <></>
          )}
          <DataItem
            label={t('label.status')}
            value={
              <Chip
                color={statusChipColor}
                label={t(`status.${props.status}`)}
                sx={{
                  borderRadius: 2.5,
                  fontSize: 12,
                  fontWeight: 800,
                  lineHeight: 1,
                  p: '2px 4px',
                  height: 24,
                }}
              />
            }
          />
        </Stack>
      </Card>
    </Stack>
  )
}

export default BetCard
