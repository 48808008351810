import { AlienClient } from '@alienclient/back-front'
import { Button } from '@mui/material'
import { alienClient } from 'handlers/eventsReceiver'
import { SnackbarProvider } from 'notistack'
import ParsRoyal from 'parsroyal'
import CustomSnackbar from 'parsroyal/components/CustomSnackbar/CustomSnackbar'
import { Dispatch, SetStateAction, createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface AlienContextProps {
  client: AlienClient
  isSocketConnected: boolean
  setIsSocketConnected: Dispatch<SetStateAction<boolean>>

  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

// TODO: Improve state manage, maybe use context for isSocket
export const AlienContext = createContext<AlienContextProps | null>(null)

function App() {
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  return (
    <AlienContext.Provider
      value={{
        client: alienClient,
        isSocketConnected,
        setIsSocketConnected,
        isLoading,
        setIsLoading,
      }}
    >
      <SnackbarProvider
        Components={{
          success: CustomSnackbar,
          default: CustomSnackbar,
          error: CustomSnackbar,
          info: CustomSnackbar,
          warning: CustomSnackbar,
        }}
        anchorOrigin={{
          horizontal: dir === 'rtl' ? 'right' : 'left',
          vertical: 'top',
        }}
        autoHideDuration={3000}
        disableWindowBlurListener
      >
        <ParsRoyal />
      </SnackbarProvider>
    </AlienContext.Provider>
  )
}

export default App
