import {
  IconButton,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Swiper, useSwiper } from 'swiper/react'
import 'swiper/css'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '@theme'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FreeMode } from 'swiper'

export const CollectionSlider = (props: PropsWithChildren & StackProps) => {
  const { children } = props
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const [swiper, setSwiper] = useState(useSwiper())
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(false)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  useEffect(() => {
    swiper && swiper.changeLanguageDirection(dir)
  }, [i18n.language])

  const handleNavClick = (direction: 'prev' | 'next') => {
    setIsEnd(false)
    setIsBeginning(false)
    direction === 'prev' ? swiper.slidePrev() : swiper.slideNext()
  }

  return (
    <>
      <Stack
        className='swiperNav-container'
        sx={{
          maxWidth: theme.breakpoints.values.xl,
          position: 'absolute',
          top: -8,
          zIndex: 2,
          m: 'auto',

          flexDirection: 'row',
          ...(dir === 'rtl'
            ? { left: 0, right: 'initial' }
            : { right: 0, left: 'initial' }),

          '.swiper, .swiper-container': {
            margin: md ? 0 : '0 -24px !important',
          },

          '.swiperNav': {
            display: 'block',
            top: [null, null, null, 185, 225],
            zIndex: 1,
            width: 38,
            height: 38,

            '.MuiIconButton-root': {
              height: 38,
              width: 38,
              border: `1px solid ${colors.gray[5]}`,
              borderRadius: 1.5,
              path: {
                color: colors.gray[12],
                opacity: 1,
              },
            },
            '&--prev': {
              '.MuiIconButton-root': {
                path: isBeginning
                  ? {
                      color: colors.gray[5],
                    }
                  : '',
              },
            },
            '&--next': {
              '.MuiIconButton-root': {
                path: isEnd
                  ? {
                      color: colors.gray[5],
                    }
                  : '',
              },
            },
          },
        }}
      >
        <Stack
          className='swiperNav swiperNav--prev'
          sx={{ marginInlineEnd: 1 }}
        >
          <IconButton
            onClick={() => handleNavClick('prev')}
            disabled={isBeginning}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={dir === 'rtl' ? faChevronRight : faChevronLeft}
              fontSize={16}
            />
          </IconButton>
        </Stack>
        <Stack className='swiperNav swiperNav--next'>
          <IconButton onClick={() => handleNavClick('next')} disabled={isEnd}>
            <FontAwesomeIcon
              icon={dir === 'rtl' ? faChevronLeft : faChevronRight}
              fontSize={16}
            />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        onSwiper={(swiper) => {
          setSwiper(swiper)
          setIsBeginning(true)
        }}
        onSlideChange={(swiper) => {
          swiper.isBeginning ? setIsBeginning(true) : setIsBeginning(false)
          swiper.isEnd ? setIsEnd(true) : setIsEnd(false)
        }}
        grabCursor
        breakpoints={{
          0: {
            slidesPerView: 2.5,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 4.5,
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 6,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 6,
          },
        }}
        style={{ padding: '6px 0', margin: md ? '0' : '0 -8px' }}
      >
        {children}
      </Swiper>
    </>
  )
}
