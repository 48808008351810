import { Box, Button, Stack } from '@mui/material'
import { colors } from '@theme'
import { clearLogs, readLogs } from 'helpers/logger'
import React, { useEffect, useState } from 'react'
import ReactJson from 'react-json-view'

const LogsContainer = () => {
  const [logs, setLogs] = useState(readLogs())

  return (
    <Stack sx={{ color: colors.gray[8], gap: 2, mt: 2 }}>
      <Button
        variant='contained'
        sx={{ alignSelf: 'end' }}
        onClick={() => {
          clearLogs()
          setLogs(readLogs())
        }}
        color='error'
      >
        Clear Logs
      </Button>
      <ReactJson src={readLogs()} theme='ashes' collapsed={false} />
    </Stack>
  )
}

export default LogsContainer
