export const GoogleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      fill='none'
      viewBox='0 0 24 25'
    >
      <g clipPath='url(#clip0_6551_399333)'>
        <path
          fill='#4285F4'
          d='M23.766 12.776c0-.816-.066-1.636-.207-2.438H12.24v4.62h6.482a5.555 5.555 0 01-2.399 3.647v2.999h3.867c2.271-2.09 3.576-5.177 3.576-8.828z'
        ></path>
        <path
          fill='#34A853'
          d='M12.24 24.5c3.236 0 5.965-1.062 7.954-2.896l-3.867-2.998c-1.076.731-2.465 1.146-4.084 1.146-3.13 0-5.785-2.112-6.737-4.952h-3.99v3.091a12.002 12.002 0 0010.723 6.61z'
        ></path>
        <path
          fill='#FBBC04'
          d='M5.503 14.8a7.188 7.188 0 010-4.594V7.115H1.517a12.01 12.01 0 000 10.776l3.986-3.09z'
        ></path>
        <path
          fill='#EA4335'
          d='M12.24 5.25a6.521 6.521 0 014.603 1.799l3.425-3.426A11.533 11.533 0 0012.24.5 11.998 11.998 0 001.516 7.115l3.986 3.09C6.45 7.363 9.108 5.25 12.239 5.25z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_6551_399333'>
          <path
            fill='#fff'
            d='M0 0H24V24H0z'
            transform='translate(0 .5)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export const TransactionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='18'
      fill='none'
      viewBox='0 0 17 18'
    >
      <path
        fill='#B7ACCB'
        d='M2 4h10V2c0-.375.219-.75.594-.906a1.01 1.01 0 011.094.218l3 3C16.875 4.5 17 4.75 17 5c0 .281-.125.531-.313.719l-3 3a1.01 1.01 0 01-1.093.219C12.219 8.78 12 8.405 12 8V6H2c-.563 0-1-.438-1-1 0-.531.438-1 1-1zm14 8c.531 0 1 .469 1 1 0 .563-.469 1-1 1H6v2c0 .406-.25.781-.625.938a1.01 1.01 0 01-1.094-.22l-3-3c-.187-.187-.312-.437-.312-.687 0-.281.125-.531.312-.719l3-3a1.01 1.01 0 011.094-.218c.375.156.625.531.625.937v2h10V12z'
      ></path>
    </svg>
  )
}

export const NotificationIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#B7ACCB'
        d='M8 0c.531 0 1 .469 1 1v.625c2.281.438 4 2.469 4 4.875v.594c0 1.468.531 2.906 1.5 4l.219.25c.281.312.344.719.187 1.094-.156.343-.531.562-.906.562H2c-.406 0-.781-.219-.938-.563a1.012 1.012 0 01.188-1.093l.219-.25a6.082 6.082 0 001.531-4V6.5c0-2.406 1.719-4.438 4-4.875V1c0-.531.438-1 1-1zm1.406 15.438A1.959 1.959 0 018 16c-.531 0-1.063-.188-1.438-.563C6.188 15.063 6 14.531 6 14h4c0 .531-.219 1.063-.594 1.438z'
      ></path>
    </svg>
  )
}

export const HomeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill='#FB8111'
        d='M7 .344l7 6.125v6.281c0 .688-.563 1.25-1.25 1.25h-2.5C9.531 14 9 13.469 9 12.75V10c0-.563-.469-1-1-1H6c-.563 0-1 .438-1 1v2.75C5 13.469 4.437 14 3.75 14h-2.5C.531 14 0 13.469 0 12.75V6.469L7 .344z'
        opacity='0.4'
      ></path>
    </svg>
  )
}

export const VerticalLine = (props: { color: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2'
      height='100%'
      fill='none'
      viewBox='0 0 2 35'
    >
      <path
        stroke={props.color}
        strokeDasharray='1 4'
        strokeLinecap='round'
        strokeWidth='2'
        d='M1 42L1 1'
      ></path>
    </svg>
  )
}

export const SecondPlace = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='43'
      fill='none'
      viewBox='0 0 32 43'
    >
      <path
        fill='url(#paint0_linear_21_137541)'
        d='M8.107 23.19c1.892.245 3.542 1.015 4.711 2.076.245.222.154.93-.14 1.082-1.399.728-3.19 1.053-5.082.808-1.892-.245-3.543-1.015-4.711-2.076-.245-.222-.154-.93.14-1.082 1.398-.728 3.19-1.053 5.082-.808z'
      ></path>
      <path
        fill='url(#paint1_linear_21_137541)'
        d='M8.107 23.19c1.892.245 3.542 1.015 4.711 2.076.245.222.154.93-.14 1.082-1.399.728-3.19 1.053-5.082.808-1.892-.245-3.543-1.015-4.711-2.076-.245-.222-.154-.93.14-1.082 1.398-.728 3.19-1.053 5.082-.808z'
      ></path>
      <path
        fill='url(#paint2_linear_21_137541)'
        d='M8.107 23.19c1.892.245 3.542 1.015 4.711 2.076.245.222.154.93-.14 1.082-1.399.728-3.19 1.053-5.082.808-1.892-.245-3.543-1.015-4.711-2.076-.245-.222-.154-.93.14-1.082 1.398-.728 3.19-1.053 5.082-.808z'
      ></path>
      <path
        fill='url(#paint3_linear_21_137541)'
        d='M8.328 20.535c2.023.88 3.61 2.264 4.57 3.818.2.325-.126 1.081-.5 1.157-1.787.36-3.881.145-5.905-.734-2.023-.879-3.61-2.264-4.57-3.817-.2-.325.127-1.082.501-1.157 1.787-.36 3.88-.146 5.904.733z'
      ></path>
      <path
        fill='url(#paint4_linear_21_137541)'
        d='M8.328 20.535c2.023.88 3.61 2.264 4.57 3.818.2.325-.126 1.081-.5 1.157-1.787.36-3.881.145-5.905-.734-2.023-.879-3.61-2.264-4.57-3.817-.2-.325.127-1.082.501-1.157 1.787-.36 3.88-.146 5.904.733z'
      ></path>
      <path
        fill='url(#paint5_linear_21_137541)'
        d='M8.328 20.535c2.023.88 3.61 2.264 4.57 3.818.2.325-.126 1.081-.5 1.157-1.787.36-3.881.145-5.905-.734-2.023-.879-3.61-2.264-4.57-3.817-.2-.325.127-1.082.501-1.157 1.787-.36 3.88-.146 5.904.733z'
      ></path>
      <path
        fill='url(#paint6_linear_21_137541)'
        d='M9.018 16.35c2.063 1.859 3.404 4.147 3.93 6.384.11.468-.583 1.24-1.06 1.18-2.274-.286-4.687-1.38-6.75-3.24-2.063-1.858-3.404-4.146-3.93-6.383-.109-.469.583-1.24 1.06-1.18 2.275.286 4.687 1.38 6.75 3.239z'
      ></path>
      <path
        fill='url(#paint7_linear_21_137541)'
        d='M2.154 16.833a13.487 13.487 0 002.985 3.842c2.063 1.858 4.475 2.953 6.75 3.239.477.06 1.168-.712 1.059-1.18-.525-2.237-1.867-4.525-3.93-6.384a13.516 13.516 0 00-3.908-2.479l-2.956 2.962z'
      ></path>
      <path
        fill='url(#paint8_linear_21_137541)'
        d='M23.893 23.19c-1.892.245-3.542 1.015-4.71 2.076-.246.222-.154.93.139 1.082 1.399.728 3.19 1.053 5.082.808 1.892-.245 3.543-1.015 4.712-2.076.244-.222.153-.93-.14-1.082-1.4-.728-3.19-1.053-5.083-.808z'
      ></path>
      <path
        fill='url(#paint9_linear_21_137541)'
        d='M23.893 23.19c-1.892.245-3.542 1.015-4.71 2.076-.246.222-.154.93.139 1.082 1.399.728 3.19 1.053 5.082.808 1.892-.245 3.543-1.015 4.712-2.076.244-.222.153-.93-.14-1.082-1.4-.728-3.19-1.053-5.083-.808z'
      ></path>
      <path
        fill='url(#paint10_linear_21_137541)'
        d='M23.893 23.19c-1.892.245-3.542 1.015-4.71 2.076-.246.222-.154.93.139 1.082 1.399.728 3.19 1.053 5.082.808 1.892-.245 3.543-1.015 4.712-2.076.244-.222.153-.93-.14-1.082-1.4-.728-3.19-1.053-5.083-.808z'
      ></path>
      <path
        fill='url(#paint11_linear_21_137541)'
        d='M23.672 20.535c-2.023.88-3.61 2.264-4.57 3.818-.2.325.127 1.081.5 1.157 1.788.36 3.881.145 5.905-.734 2.023-.879 3.61-2.264 4.57-3.817.2-.325-.127-1.082-.5-1.157-1.788-.36-3.882-.146-5.905.733z'
      ></path>
      <path
        fill='url(#paint12_linear_21_137541)'
        d='M23.672 20.535c-2.023.88-3.61 2.264-4.57 3.818-.2.325.127 1.081.5 1.157 1.788.36 3.881.145 5.905-.734 2.023-.879 3.61-2.264 4.57-3.817.2-.325-.127-1.082-.5-1.157-1.788-.36-3.882-.146-5.905.733z'
      ></path>
      <path
        fill='url(#paint13_linear_21_137541)'
        d='M23.672 20.535c-2.023.88-3.61 2.264-4.57 3.818-.2.325.127 1.081.5 1.157 1.788.36 3.881.145 5.905-.734 2.023-.879 3.61-2.264 4.57-3.817.2-.325-.127-1.082-.5-1.157-1.788-.36-3.882-.146-5.905.733z'
      ></path>
      <path
        fill='url(#paint14_linear_21_137541)'
        d='M22.982 16.35c-2.063 1.859-3.404 4.147-3.93 6.384-.11.468.583 1.24 1.06 1.18 2.274-.286 4.687-1.38 6.75-3.24 2.063-1.858 3.404-4.146 3.929-6.383.11-.469-.582-1.24-1.058-1.18-2.276.286-4.688 1.38-6.75 3.239z'
      ></path>
      <path
        fill='url(#paint15_linear_21_137541)'
        d='M29.846 16.833a13.488 13.488 0 01-2.984 3.842c-2.063 1.858-4.476 2.953-6.75 3.239-.477.06-1.17-.712-1.06-1.18.526-2.237 1.867-4.525 3.93-6.384a13.515 13.515 0 013.909-2.479l2.955 2.962z'
      ></path>
      <path
        fill='url(#paint16_linear_21_137541)'
        d='M16 31.5c5.51 0 9.978-4.477 9.978-10s-4.467-10-9.978-10c-5.51 0-9.978 4.477-9.978 10s4.467 10 9.978 10z'
      ></path>
      <path
        fill='url(#paint17_linear_21_137541)'
        d='M16 29.47c4.392 0 7.953-3.568 7.953-7.97 0-4.402-3.561-7.97-7.953-7.97-4.393 0-7.954 3.568-7.954 7.97 0 4.402 3.561 7.97 7.954 7.97z'
      ></path>
      <path
        fill='#5B536F'
        d='M16 28.343c3.771 0 6.828-3.064 6.828-6.843 0-3.78-3.057-6.843-6.828-6.843-3.77 0-6.828 3.064-6.828 6.843S12.23 28.343 16 28.343z'
      ></path>
      <path
        fill='url(#paint18_linear_21_137541)'
        d='M6.022 21.5c0 5.523 4.467 10 9.978 10 5.51 0 9.978-4.477 9.978-10l-.001-.116H6.023l-.001.116z'
      ></path>
      <path
        fill='#F5F3FB'
        d='M13.077 25.71v-1.187l2.823-3.036c.38-.416.652-.783.817-1.099.165-.325.248-.654.248-.987 0-.85-.508-1.274-1.523-1.274-.793 0-1.523.3-2.192.9l-.532-1.2c.347-.316.78-.57 1.3-.762a4.657 4.657 0 011.622-.287c.933 0 1.647.212 2.142.637.503.425.755 1.033.755 1.824 0 .533-.128 1.045-.383 1.536-.248.492-.64 1.02-1.177 1.587l-1.919 2.023h3.814v1.324h-5.795z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_21_137541'
          x1='5.76'
          x2='7.837'
          y1='22.284'
          y2='25.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_137541'
          x1='8.059'
          x2='8.034'
          y1='23.953'
          y2='25.758'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_137541'
          x1='5.99'
          x2='6.826'
          y1='24.272'
          y2='26.917'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_137541'
          x1='3.637'
          x2='7.061'
          y1='19.845'
          y2='22.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_137541'
          x1='7.637'
          x2='7.587'
          y1='20.87'
          y2='23.512'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_137541'
          x1='5.388'
          x2='6.923'
          y1='21.337'
          y2='24.942'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_137541'
          x1='6.843'
          x2='6.992'
          y1='15.32'
          y2='20.043'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_137541'
          x1='5.588'
          x2='8.956'
          y1='16.7'
          y2='21.333'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_137541'
          x1='26.24'
          x2='24.163'
          y1='22.284'
          y2='25.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_137541'
          x1='24.357'
          x2='24.332'
          y1='23.953'
          y2='25.758'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_137541'
          x1='24.812'
          x2='22.184'
          y1='24.19'
          y2='26.999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_137541'
          x1='28.364'
          x2='24.939'
          y1='19.845'
          y2='22.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_137541'
          x1='24.815'
          x2='24.766'
          y1='20.87'
          y2='23.512'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_137541'
          x1='25.31'
          x2='21.556'
          y1='21.216'
          y2='24.195'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_137541'
          x1='25.161'
          x2='25.012'
          y1='15.318'
          y2='20.041'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_137541'
          x1='25.145'
          x2='20.246'
          y1='16.5'
          y2='18.776'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_137541'
          x1='9.848'
          x2='20.395'
          y1='15.335'
          y2='25.858'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_137541'
          x1='21.165'
          x2='10.66'
          y1='26.677'
          y2='16.195'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint18_linear_21_137541'
          x1='16'
          x2='16'
          y1='24.426'
          y2='30.99'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const THirdPrizePlace = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='43'
      fill='none'
      viewBox='0 0 32 43'
    >
      <path
        fill='url(#paint0_linear_21_137582)'
        d='M8.152 19.763c1.46 1.395 2.377 3.074 2.694 4.692.066.34-.467.87-.816.813-1.666-.274-3.408-1.131-4.869-2.525-1.46-1.394-2.377-3.074-2.694-4.692-.066-.339.467-.87.816-.813 1.666.274 3.408 1.131 4.869 2.525z'
      ></path>
      <path
        fill='url(#paint1_linear_21_137582)'
        d='M8.152 19.763c1.46 1.395 2.377 3.074 2.694 4.692.066.34-.467.87-.816.813-1.666-.274-3.408-1.131-4.869-2.525-1.46-1.394-2.377-3.074-2.694-4.692-.066-.339.467-.87.816-.813 1.666.274 3.408 1.131 4.869 2.525z'
      ></path>
      <path
        fill='url(#paint2_linear_21_137582)'
        d='M10.133 16.462c1.172 2.232 1.576 4.592 1.291 6.646-.06.43-.914.856-1.306.651-1.876-.975-3.602-2.676-4.773-4.908-1.172-2.232-1.577-4.593-1.291-6.646.06-.43.913-.857 1.306-.652 1.875.976 3.602 2.677 4.773 4.909z'
      ></path>
      <path
        fill='url(#paint3_linear_21_137582)'
        d='M4.031 14.65c.155 1.38.587 2.815 1.314 4.2 1.17 2.233 2.897 3.934 4.772 4.91.393.204 1.247-.222 1.307-.652.285-2.054-.12-4.414-1.291-6.646a12.249 12.249 0 00-2.564-3.351l-3.538 1.54z'
      ></path>
      <path
        fill='url(#paint4_linear_21_137582)'
        d='M23.438 19.763c-1.461 1.395-2.377 3.074-2.694 4.692-.066.34.467.87.816.813 1.666-.274 3.408-1.131 4.869-2.525 1.46-1.394 2.377-3.074 2.694-4.692.066-.339-.467-.87-.816-.813-1.666.274-3.408 1.131-4.87 2.525z'
      ></path>
      <path
        fill='url(#paint5_linear_21_137582)'
        d='M23.438 19.763c-1.461 1.395-2.377 3.074-2.694 4.692-.066.34.467.87.816.813 1.666-.274 3.408-1.131 4.869-2.525 1.46-1.394 2.377-3.074 2.694-4.692.066-.339-.467-.87-.816-.813-1.666.274-3.408 1.131-4.87 2.525z'
      ></path>
      <path
        fill='url(#paint6_linear_21_137582)'
        d='M21.457 16.462c-1.172 2.232-1.576 4.592-1.291 6.646.06.43.914.856 1.306.651 1.875-.975 3.602-2.676 4.773-4.908 1.172-2.232 1.577-4.593 1.291-6.646-.06-.43-.913-.857-1.306-.652-1.875.976-3.602 2.677-4.773 4.909z'
      ></path>
      <path
        fill='url(#paint7_linear_21_137582)'
        d='M27.559 14.65a11.947 11.947 0 01-1.314 4.2c-1.171 2.233-2.898 3.934-4.773 4.91-.392.204-1.246-.222-1.306-.652-.285-2.054.12-4.414 1.29-6.646a12.247 12.247 0 012.565-3.351l3.538 1.54z'
      ></path>
      <path
        fill='url(#paint8_linear_21_137582)'
        d='M15.795 31.793c5.688 0 10.3-4.496 10.3-10.042 0-5.546-4.612-10.042-10.3-10.042-5.689 0-10.3 4.496-10.3 10.042 0 5.546 4.611 10.042 10.3 10.042z'
      ></path>
      <path
        fill='url(#paint9_linear_21_137582)'
        d='M15.795 29.755c4.534 0 8.21-3.583 8.21-8.004 0-4.42-3.676-8.004-8.21-8.004-4.534 0-8.21 3.583-8.21 8.004 0 4.42 3.676 8.004 8.21 8.004z'
      ></path>
      <path
        fill='#8C3D27'
        d='M15.794 28.622c3.893 0 7.048-3.076 7.048-6.871 0-3.795-3.155-6.872-7.048-6.872-3.892 0-7.047 3.077-7.047 6.872s3.155 6.871 7.047 6.871z'
      ></path>
      <path
        fill='url(#paint10_linear_21_137582)'
        d='M5.495 21.75c0 5.547 4.611 10.043 10.3 10.043 5.688 0 10.3-4.496 10.3-10.042l-.002-.117H5.497l-.002.117z'
      ></path>
      <path
        fill='url(#paint11_linear_21_137582)'
        d='M15.795 11.709l-.128.001-1.59 1.55 11.604 11.314a9.813 9.813 0 00.413-2.823c0-5.546-4.61-10.042-10.3-10.042z'
      ></path>
      <path
        fill='#FCE1C2'
        d='M15.732 26.3a6.864 6.864 0 01-1.92-.267c-.604-.186-1.105-.435-1.504-.748l.584-1.244a4.704 4.704 0 002.785.889c1.252 0 1.879-.449 1.879-1.346 0-.423-.158-.736-.474-.939-.306-.211-.756-.317-1.35-.317H14.16v-1.345h1.406c.52 0 .933-.114 1.24-.343.305-.236.458-.562.458-.977 0-.389-.143-.685-.431-.888-.279-.203-.687-.304-1.225-.304-.965 0-1.814.304-2.548.913l-.584-1.218c.39-.338.877-.6 1.461-.787a5.975 5.975 0 011.824-.279c1.02 0 1.814.211 2.38.634.567.415.85.994.85 1.739 0 .482-.135.905-.404 1.269a2.24 2.24 0 01-1.1.787c.566.143 1.007.41 1.323.8.315.38.473.845.473 1.395 0 .795-.316 1.425-.947 1.89-.63.458-1.498.686-2.603.686z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_21_137582'
          x1='4.303'
          x2='6.345'
          y1='17.683'
          y2='20.981'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_137582'
          x1='4.601'
          x2='6.847'
          y1='20.546'
          y2='21.387'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_137582'
          x1='7.925'
          x2='7.643'
          y1='14.216'
          y2='19.33'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_137582'
          x1='7.251'
          x2='8.482'
          y1='16.809'
          y2='19.177'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_137582'
          x1='27.281'
          x2='25.239'
          y1='17.692'
          y2='20.99'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_137582'
          x1='26.983'
          x2='24.738'
          y1='20.555'
          y2='21.396'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_137582'
          x1='23.66'
          x2='23.942'
          y1='14.225'
          y2='19.339'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_137582'
          x1='24.334'
          x2='23.102'
          y1='16.818'
          y2='19.185'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_137582'
          x1='9.445'
          x2='20.032'
          y1='15.56'
          y2='26.418'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_137582'
          x1='21.127'
          x2='10.581'
          y1='26.95'
          y2='16.134'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBC56D'></stop>
          <stop offset='1' stopColor='#C5715A'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_137582'
          x1='15.795'
          x2='15.795'
          y1='24.689'
          y2='31.28'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_137582'
          x1='20.87'
          x2='17.048'
          y1='17.509'
          y2='10.647'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C3D27' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#8C3D27'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const FirstPlace = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='43'
      fill='none'
      viewBox='0 0 32 43'
    >
      <path
        fill='url(#paint0_linear_21_137497)'
        d='M6.91 23.19c1.892.245 3.542 1.015 4.71 2.076.246.222.154.93-.139 1.082-1.399.728-3.19 1.053-5.082.808-1.892-.245-3.543-1.015-4.712-2.076-.244-.222-.153-.93.14-1.082 1.4-.728 3.19-1.053 5.083-.808z'
      ></path>
      <path
        fill='url(#paint1_linear_21_137497)'
        d='M6.91 23.19c1.892.245 3.542 1.015 4.71 2.076.246.222.154.93-.139 1.082-1.399.728-3.19 1.053-5.082.808-1.892-.245-3.543-1.015-4.712-2.076-.244-.222-.153-.93.14-1.082 1.4-.728 3.19-1.053 5.083-.808z'
      ></path>
      <path
        fill='url(#paint2_linear_21_137497)'
        d='M7.13 20.535c2.024.88 3.612 2.264 4.57 3.818.202.325-.126 1.081-.5 1.157-1.787.36-3.88.145-5.904-.734-2.023-.879-3.61-2.264-4.57-3.817-.2-.325.127-1.082.5-1.157 1.788-.36 3.882-.146 5.905.733z'
      ></path>
      <path
        fill='url(#paint3_linear_21_137497)'
        d='M7.13 20.535c2.024.88 3.612 2.264 4.57 3.818.202.325-.126 1.081-.5 1.157-1.787.36-3.88.145-5.904-.734-2.023-.879-3.61-2.264-4.57-3.817-.2-.325.127-1.082.5-1.157 1.788-.36 3.882-.146 5.905.733z'
      ></path>
      <path
        fill='url(#paint4_linear_21_137497)'
        d='M10.774 16.273c.81 2.055.956 4.16.538 5.937-.087.373-.853.676-1.17.464-1.518-1.012-2.847-2.647-3.658-4.703-.81-2.055-.955-4.16-.538-5.938.088-.372.853-.675 1.17-.463 1.518 1.011 2.848 2.647 3.658 4.703z'
      ></path>
      <path
        fill='url(#paint5_linear_21_137497)'
        d='M10.774 16.273c.81 2.055.956 4.16.538 5.937-.087.373-.853.676-1.17.464-1.518-1.012-2.847-2.647-3.658-4.703-.81-2.055-.955-4.16-.538-5.938.088-.372.853-.675 1.17-.463 1.518 1.011 2.848 2.647 3.658 4.703z'
      ></path>
      <path
        fill='url(#paint6_linear_21_137497)'
        d='M7.82 16.35c2.064 1.859 3.405 4.147 3.93 6.384.11.468-.582 1.24-1.059 1.18-2.275-.286-4.687-1.38-6.75-3.24-2.063-1.858-3.404-4.146-3.93-6.383-.11-.469.583-1.24 1.06-1.18 2.274.286 4.687 1.38 6.75 3.239z'
      ></path>
      <path
        fill='url(#paint7_linear_21_137497)'
        d='M.957 16.833a13.487 13.487 0 002.984 3.842c2.063 1.858 4.476 2.953 6.75 3.238.477.06 1.17-.71 1.06-1.18-.526-2.236-1.867-4.524-3.93-6.383a13.516 13.516 0 00-3.909-2.48L.957 16.834z'
      ></path>
      <path
        fill='url(#paint8_linear_21_137497)'
        d='M25.09 23.19c-1.891.245-3.542 1.015-4.71 2.076-.245.222-.154.93.139 1.082 1.399.728 3.19 1.053 5.083.808 1.892-.245 3.542-1.015 4.71-2.076.245-.222.154-.93-.139-1.082-1.399-.728-3.19-1.053-5.082-.808z'
      ></path>
      <path
        fill='url(#paint9_linear_21_137497)'
        d='M25.09 23.19c-1.891.245-3.542 1.015-4.71 2.076-.245.222-.154.93.139 1.082 1.399.728 3.19 1.053 5.083.808 1.892-.245 3.542-1.015 4.71-2.076.245-.222.154-.93-.139-1.082-1.399-.728-3.19-1.053-5.082-.808z'
      ></path>
      <path
        fill='url(#paint10_linear_21_137497)'
        d='M24.87 20.535c-2.024.88-3.611 2.264-4.57 3.818-.201.325.126 1.081.5 1.157 1.787.36 3.88.145 5.904-.734 2.023-.879 3.61-2.264 4.57-3.817.2-.325-.126-1.082-.5-1.157-1.788-.36-3.881-.146-5.905.733z'
      ></path>
      <path
        fill='url(#paint11_linear_21_137497)'
        d='M24.87 20.535c-2.024.88-3.611 2.264-4.57 3.818-.201.325.126 1.081.5 1.157 1.787.36 3.88.145 5.904-.734 2.023-.879 3.61-2.264 4.57-3.817.2-.325-.126-1.082-.5-1.157-1.788-.36-3.881-.146-5.905.733z'
      ></path>
      <path
        fill='url(#paint12_linear_21_137497)'
        d='M21.226 16.273c-.81 2.055-.955 4.16-.538 5.937.088.373.853.676 1.17.464 1.519-1.012 2.848-2.647 3.658-4.703.81-2.055.956-4.16.538-5.938-.087-.372-.852-.675-1.17-.463-1.518 1.011-2.847 2.647-3.658 4.703z'
      ></path>
      <path
        fill='url(#paint13_linear_21_137497)'
        d='M21.226 16.273c-.81 2.055-.955 4.16-.538 5.937.088.373.853.676 1.17.464 1.519-1.012 2.848-2.647 3.658-4.703.81-2.055.956-4.16.538-5.938-.087-.372-.852-.675-1.17-.463-1.518 1.011-2.847 2.647-3.658 4.703z'
      ></path>
      <path
        fill='url(#paint14_linear_21_137497)'
        d='M24.18 16.35c-2.064 1.859-3.405 4.147-3.93 6.384-.11.468.582 1.24 1.058 1.18 2.276-.286 4.688-1.38 6.75-3.24 2.064-1.858 3.405-4.146 3.93-6.383.11-.469-.582-1.24-1.058-1.18-2.275.286-4.688 1.38-6.75 3.239z'
      ></path>
      <path
        fill='url(#paint15_linear_21_137497)'
        d='M31.043 16.833a13.486 13.486 0 01-2.984 3.842c-2.063 1.858-4.475 2.953-6.75 3.238-.477.06-1.169-.71-1.06-1.18.526-2.236 1.867-4.524 3.93-6.383a13.516 13.516 0 013.909-2.48l2.955 2.963z'
      ></path>
      <path
        fill='url(#paint16_linear_21_137497)'
        d='M16 31.5c5.51 0 9.978-4.477 9.978-10s-4.467-10-9.978-10c-5.51 0-9.978 4.477-9.978 10s4.467 10 9.978 10z'
      ></path>
      <path
        fill='url(#paint17_linear_21_137497)'
        d='M16 29.47c4.392 0 7.953-3.568 7.953-7.97 0-4.402-3.561-7.97-7.953-7.97-4.393 0-7.954 3.568-7.954 7.97 0 4.402 3.561 7.97 7.954 7.97z'
      ></path>
      <path
        fill='#562E0A'
        d='M16 28.343c3.771 0 6.828-3.064 6.828-6.843 0-3.78-3.057-6.843-6.828-6.843-3.77 0-6.828 3.064-6.828 6.843S12.23 28.343 16 28.343z'
      ></path>
      <path
        fill='url(#paint18_linear_21_137497)'
        d='M6.022 21.5c0 5.523 4.467 10 9.978 10 5.51 0 9.978-4.477 9.978-10l-.001-.116H6.023l-.001.116z'
      ></path>
      <path
        fill='#fff'
        d='M13.307 25.71v-1.344h2.03v-5.738l-1.57.937-.639-1.19 2.68-1.597h1.162v7.588h1.902v1.343h-5.565z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_21_137497'
          x1='4.563'
          x2='6.64'
          y1='22.284'
          y2='25.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_137497'
          x1='4.375'
          x2='7.64'
          y1='24.119'
          y2='25.622'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_137497'
          x1='2.439'
          x2='5.864'
          y1='19.845'
          y2='22.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_137497'
          x1='3.873'
          x2='6.466'
          y1='22.694'
          y2='22.652'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_137497'
          x1='9.309'
          x2='8.522'
          y1='13.986'
          y2='17.601'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_137497'
          x1='5.92'
          x2='7.932'
          y1='14.35'
          y2='16.4'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_137497'
          x1='4.714'
          x2='6.446'
          y1='14.855'
          y2='20.261'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_137497'
          x1='5.062'
          x2='7.283'
          y1='17.8'
          y2='19.723'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_137497'
          x1='27.437'
          x2='25.36'
          y1='22.284'
          y2='25.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_137497'
          x1='27.626'
          x2='24.36'
          y1='24.119'
          y2='25.622'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_137497'
          x1='29.561'
          x2='26.136'
          y1='19.845'
          y2='22.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_137497'
          x1='28.127'
          x2='25.534'
          y1='22.694'
          y2='22.652'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_137497'
          x1='22.691'
          x2='23.479'
          y1='13.986'
          y2='17.601'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_137497'
          x1='26.08'
          x2='24.068'
          y1='14.35'
          y2='16.4'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_137497'
          x1='27.286'
          x2='25.554'
          y1='14.855'
          y2='20.261'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_137497'
          x1='26.938'
          x2='24.717'
          y1='17.8'
          y2='19.723'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_137497'
          x1='9.848'
          x2='20.395'
          y1='15.335'
          y2='25.858'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_137497'
          x1='21.165'
          x2='10.66'
          y1='26.677'
          y2='16.195'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint18_linear_21_137497'
          x1='16'
          x2='16'
          y1='24.426'
          y2='30.99'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const DisabledCup = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='49'
      height='41'
      fill='none'
      viewBox='0 0 49 41'
    >
      <g clipPath='url(#clip0_21_137364)'>
        <g opacity='0.5'>
          <path
            fill='url(#paint0_linear_21_137364)'
            d='M33.722 7.15v25.03c2.19 0 7.92-5.415 7.92-5.978V13.128c0-.563-5.73-5.979-7.92-5.979z'
          ></path>
          <path
            fill='url(#paint1_linear_21_137364)'
            d='M33.722 7.15v25.03c2.19 0 7.92-5.415 7.92-5.978V13.128c0-.563-5.73-5.979-7.92-5.979z'
          ></path>
          <path
            fill='url(#paint2_linear_21_137364)'
            d='M33.722 7.15v25.03c2.19 0 7.92-5.415 7.92-5.978V13.128c0-.563-5.73-5.979-7.92-5.979z'
          ></path>
          <path
            fill='url(#paint3_linear_21_137364)'
            d='M7.097 13.13v13.072c0 .564 5.73 5.98 7.752 5.98V7.148c-2.023 0-7.752 5.417-7.752 5.98z'
          ></path>
          <path
            fill='url(#paint4_linear_21_137364)'
            d='M7.097 13.13v13.072c0 .564 5.73 5.98 7.752 5.98V7.148c-2.023 0-7.752 5.417-7.752 5.98z'
          ></path>
          <path
            fill='url(#paint5_linear_21_137364)'
            d='M7.097 13.13v13.072c0 .564 5.73 5.98 7.752 5.98V7.148c-2.023 0-7.752 5.417-7.752 5.98z'
          ></path>
          <g style={{ mixBlendMode: 'multiply' }} fill='#A2B2E7'>
            <path d='M41.642 24.86v1.342c0 .564-5.73 5.98-7.92 5.98v-1.343c2.19 0 7.92-5.416 7.92-5.98zM14.849 30.839v1.343c-2.023 0-7.752-5.417-7.752-5.98V24.86c0 .563 5.73 5.979 7.752 5.979z'></path>
          </g>
          <g opacity='0.49'>
            <path
              fill='#fff'
              d='M41.642 14.472v-1.343c0-.563-5.73-5.979-7.92-5.979v1.343c2.19 0 7.92 5.416 7.92 5.98v-.001zM14.849 8.492V7.149c-2.023 0-7.752 5.417-7.752 5.98v1.342c0-.564 5.73-5.98 7.752-5.98v.001z'
            ></path>
          </g>
        </g>
        <path
          fill='url(#paint6_linear_21_137364)'
          d='M28.376 24.38s4.127-3.056 6.796-9.999c2.668-6.943 1.336-9.29 2.014-10.09.68-.8 4.486-.007 5.193.215.708.223.566 1.081.3 2.308-.264 1.227-1.03 6.233-5.573 11.772-4.542 5.538-8.998 6.87-8.998 6.87s-.574-.345.266-1.075h.002z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M35.871 15.31c2.669-6.943 1.336-9.29 2.014-10.09.643-.756 4.086-.088 5.054.175 0-.454-.133-.763-.517-.884-.707-.222-4.514-1.014-5.194-.214-.678.8.654 3.146-2.014 10.089-2.669 6.943-6.796 10-6.796 10-.841.73-.266 1.075-.266 1.075s.566-.17 1.5-.638c1.229-1.109 4.159-4.152 6.22-9.513z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint7_linear_21_137364)'
          d='M42.68 6.815c.265-1.228.407-2.086-.3-2.308a5.442 5.442 0 00-.226-.063c-.012.368-.102.824-.215 1.347-.265 1.227-1.03 6.233-5.573 11.771-2.687 3.277-4.805 5.535-6.527 6.463-.57.547-5.262 1.763-5.262 1.763-.842.73 3.532-.33 3.532-.33s4.456-1.333 8.999-6.872c4.542-5.538 5.308-10.544 5.573-11.771h-.001z'
        ></path>
        <path
          fill='url(#paint8_linear_21_137364)'
          d='M26.515 25.59s4.524-.975 9.125-6.499c4.6-5.523 4.264-8.492 5.108-8.945.844-.453 3.8 2.204 4.324 2.772.523.567.113 1.343-.525 2.422-.638 1.078-2.974 5.631-8.687 8.847-5.713 3.217-9.933 2.332-9.933 2.332s-.37-.624.588-.928z'
        ></path>
        <path
          fill='url(#paint9_linear_21_137364)'
          d='M26.515 25.59s4.524-.975 9.125-6.499c4.6-5.523 4.264-8.492 5.108-8.945.844-.453 3.8 2.204 4.324 2.772.523.567.113 1.343-.525 2.422-.638 1.078-2.974 5.631-8.687 8.847-5.713 3.217-9.933 2.332-9.933 2.332s-.37-.624.588-.928z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M35.919 20.35c4.6-5.523 4.263-8.492 5.107-8.945.8-.428 3.488 1.927 4.219 2.663.153-.446.145-.817-.14-1.125-.523-.568-3.479-3.225-4.323-2.772-.845.453-.508 3.422-5.108 8.945-4.6 5.524-9.125 6.5-9.125 6.5-.958.304-.588.928-.588.928s.537.113 1.485.112c1.414-.487 4.92-2.04 8.472-6.305z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint10_linear_21_137364)'
          d='M44.547 15.339c.639-1.078 1.048-1.854.525-2.422a5.404 5.404 0 00-.17-.173c-.134.357-.365.762-.637 1.221-.638 1.079-2.974 5.632-8.687 8.848-3.38 1.903-6.237 2.37-8.007 2.435a7.867 7.867 0 01-1.056.342c-.958.305-.589.929-.589.929s4.22.884 9.933-2.332c5.713-3.217 8.05-7.77 8.688-8.848z'
        ></path>
        <path
          fill='url(#paint11_linear_21_137364)'
          d='M26.519 25.869s3.539 1.07 8.409-1.216c4.87-2.286 5.462-4.63 6.198-4.632.737-.002 2.123 3.15 2.341 3.78.219.63-.295 1.046-1.058 1.595-.764.55-3.728 3.012-8.753 3.138-5.025.127-7.822-2.208-7.822-2.208s-.092-.611.685-.458v.001z'
        ></path>
        <path
          fill='url(#paint12_linear_21_137364)'
          d='M26.519 25.869s3.539 1.07 8.409-1.216c4.87-2.286 5.462-4.63 6.198-4.632.737-.002 2.123 3.15 2.341 3.78.219.63-.295 1.046-1.058 1.595-.764.55-3.728 3.012-8.753 3.138-5.025.127-7.822-2.208-7.822-2.208s-.092-.611.685-.458v.001z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M34.883 25.76c4.87-2.286 5.461-4.63 6.198-4.632.657-.002 1.829 2.503 2.23 3.493.192-.248.264-.508.155-.82-.218-.63-1.604-3.783-2.34-3.78-.737.002-1.328 2.346-6.199 4.632-4.87 2.286-8.409 1.216-8.409 1.216-.777-.154-.686.458-.686.458s.422.351 1.231.774c1.2.196 4.126.392 7.819-1.34z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint13_linear_21_137364)'
          d='M43.467 23.802a10.643 10.643 0 00-.26-.645c-.188.28-.533.542-.954.846-.764.549-3.728 3.012-8.753 3.138-2.539.064-4.509-.5-5.836-1.074-.734-.074-1.145-.198-1.145-.198-.776-.154-.685.458-.685.458s2.797 2.334 7.822 2.208c5.025-.126 7.99-2.59 8.753-3.139.764-.549 1.277-.964 1.059-1.594h-.001z'
        ></path>
        <path
          fill='url(#paint14_linear_21_137364)'
          d='M28.942 26.637s.357 2.09 4.946 1.815c4.589-.274 5.447-2.117 6.09-1.858.645.26 1.396 3.512 1.494 4.142.099.63-.41.81-1.157 1.021-.747.21-3.697 1.316-8.106-.357-4.409-1.672-3.933-4.606-3.933-4.606s.01-.568.666-.157z'
        ></path>
        <path
          fill='url(#paint15_linear_21_137364)'
          d='M28.942 26.637s.357 2.09 4.946 1.815c4.589-.274 5.447-2.117 6.09-1.858.645.26 1.396 3.512 1.494 4.142.099.63-.41.81-1.157 1.021-.747.21-3.697 1.316-8.106-.357-4.409-1.672-3.933-4.606-3.933-4.606s.01-.568.666-.157z'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint16_linear_21_137364)'
          d='M41.472 30.735c-.082-.527-.622-2.891-1.174-3.8.326 1.003.582 2.157.636 2.506.099.63-.41.811-1.158 1.021-.747.21-3.696 1.316-8.105-.356-2.809-1.065-2.103-2.582-3.092-3.617-.297-.027-.303.305-.303.305S27.8 29.728 32.21 31.4c4.41 1.672 7.359.567 8.106.357.746-.21 1.256-.392 1.157-1.022z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M39.682 27.442c-.644-.26-1.502 1.584-6.09 1.858-3.496.21-3.452-.91-4.463-1.505-.571-.596-.852-1-.852-1s.01-.569.665-.158c0 0 .357 2.09 4.947 1.816 4.588-.275 5.447-2.118 6.09-1.859.645.26 1.395 3.512 1.494 4.142.053.344-1.185-3.05-1.79-3.293z'
          opacity='0.72'
        ></path>
        <path
          fill='url(#paint17_linear_21_137364)'
          d='M20.053 24.38s-4.127-3.056-6.796-9.999c-2.668-6.943-1.336-9.29-2.014-10.09-.68-.8-4.486-.007-5.193.215-.708.223-.566 1.081-.3 2.308.264 1.227 1.03 6.233 5.573 11.772 4.542 5.538 8.998 6.87 8.998 6.87s.574-.345-.266-1.075h-.002z'
        ></path>
        <path
          fill='url(#paint18_linear_21_137364)'
          d='M20.053 24.38s-4.127-3.056-6.796-9.999c-2.668-6.943-1.336-9.29-2.014-10.09-.68-.8-4.486-.007-5.193.215-.708.223-.566 1.081-.3 2.308.264 1.227 1.03 6.233 5.573 11.772 4.542 5.538 8.998 6.87 8.998 6.87s.574-.345-.266-1.075h-.002z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M12.557 15.31C9.89 8.367 11.221 6.02 10.543 5.22c-.643-.756-4.085-.088-5.053.175 0-.454.132-.763.517-.884.707-.223 4.514-1.015 5.193-.214.679.8-.654 3.146 2.014 10.089 2.669 6.943 6.796 10 6.796 10 .842.73.267 1.075.267 1.075s-.567-.17-1.501-.638c-1.229-1.109-4.159-4.152-6.22-9.513h.001z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint19_linear_21_137364)'
          d='M5.748 6.815c-.265-1.228-.407-2.086.3-2.308.057-.018.133-.04.226-.063.012.368.102.824.215 1.347.265 1.227 1.03 6.233 5.574 11.771 2.686 3.277 4.805 5.535 6.526 6.463.57.547 5.262 1.763 5.262 1.763.842.73-3.532-.33-3.532-.33s-4.456-1.333-8.999-6.872C6.78 13.047 6.014 8.042 5.748 6.815z'
        ></path>
        <path
          fill='url(#paint20_linear_21_137364)'
          d='M21.913 25.59s-4.524-.975-9.124-6.499c-4.6-5.523-4.264-8.492-5.108-8.945-.845-.453-3.8 2.204-4.324 2.772-.523.567-.114 1.343.524 2.422.639 1.078 2.975 5.631 8.688 8.847 5.713 3.217 9.933 2.332 9.933 2.332s.37-.624-.588-.928z'
        ></path>
        <path
          fill='url(#paint21_linear_21_137364)'
          fillOpacity='0.2'
          d='M21.913 25.59s-4.524-.975-9.124-6.499c-4.6-5.523-4.264-8.492-5.108-8.945-.845-.453-3.8 2.204-4.324 2.772-.523.567-.114 1.343.524 2.422.639 1.078 2.975 5.631 8.688 8.847 5.713 3.217 9.933 2.332 9.933 2.332s.37-.624-.588-.928z'
        ></path>
        <path
          fill='url(#paint22_linear_21_137364)'
          d='M21.913 25.59s-4.524-.975-9.124-6.499c-4.6-5.523-4.264-8.492-5.108-8.945-.845-.453-3.8 2.204-4.324 2.772-.523.567-.114 1.343.524 2.422.639 1.078 2.975 5.631 8.688 8.847 5.713 3.217 9.933 2.332 9.933 2.332s.37-.624-.588-.928z'
        ></path>
        <path
          fill='url(#paint23_linear_21_137364)'
          d='M21.913 25.59s-4.524-.975-9.124-6.499c-4.6-5.523-4.264-8.492-5.108-8.945-.845-.453-3.8 2.204-4.324 2.772-.523.567-.114 1.343.524 2.422.639 1.078 2.975 5.631 8.688 8.847 5.713 3.217 9.933 2.332 9.933 2.332s.37-.624-.588-.928z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M12.51 20.35c-4.6-5.523-4.264-8.492-5.108-8.945-.799-.428-3.488 1.927-4.218 2.663-.154-.446-.145-.817.14-1.125.523-.568 3.478-3.225 4.323-2.772.845.453.507 3.422 5.107 8.945 4.6 5.524 9.125 6.5 9.125 6.5.958.304.589.928.589.928s-.537.113-1.485.112c-1.414-.487-4.92-2.04-8.473-6.305z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint24_linear_21_137364)'
          d='M3.881 15.339c-.638-1.078-1.047-1.854-.524-2.422.042-.045.1-.104.17-.173.134.357.364.762.637 1.221.638 1.079 2.974 5.632 8.687 8.848 3.38 1.903 6.236 2.37 8.007 2.435a7.867 7.867 0 001.056.342c.958.305.589.929.589.929s-4.22.884-9.934-2.332c-5.713-3.217-8.05-7.77-8.688-8.848z'
        ></path>
        <path
          fill='url(#paint25_linear_21_137364)'
          d='M21.91 25.869s-3.54 1.07-8.41-1.216-5.462-4.63-6.198-4.632c-.737-.002-2.122 3.15-2.34 3.78-.22.63.294 1.046 1.058 1.595.763.55 3.728 3.012 8.753 3.138 5.025.127 7.822-2.208 7.822-2.208s.091-.611-.686-.458v.001z'
        ></path>
        <path
          fill='url(#paint26_linear_21_137364)'
          fillOpacity='0.2'
          d='M21.91 25.869s-3.54 1.07-8.41-1.216-5.462-4.63-6.198-4.632c-.737-.002-2.122 3.15-2.34 3.78-.22.63.294 1.046 1.058 1.595.763.55 3.728 3.012 8.753 3.138 5.025.127 7.822-2.208 7.822-2.208s.091-.611-.686-.458v.001z'
        ></path>
        <path
          fill='url(#paint27_linear_21_137364)'
          d='M21.91 25.869s-3.54 1.07-8.41-1.216-5.462-4.63-6.198-4.632c-.737-.002-2.122 3.15-2.34 3.78-.22.63.294 1.046 1.058 1.595.763.55 3.728 3.012 8.753 3.138 5.025.127 7.822-2.208 7.822-2.208s.091-.611-.686-.458v.001z'
        ></path>
        <path
          fill='url(#paint28_linear_21_137364)'
          d='M21.91 25.869s-3.54 1.07-8.41-1.216-5.462-4.63-6.198-4.632c-.737-.002-2.122 3.15-2.34 3.78-.22.63.294 1.046 1.058 1.595.763.55 3.728 3.012 8.753 3.138 5.025.127 7.822-2.208 7.822-2.208s.091-.611-.686-.458v.001z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M13.545 25.76c-4.87-2.286-5.461-4.63-6.198-4.632-.657-.002-1.829 2.503-2.23 3.493-.192-.248-.264-.508-.155-.82.219-.63 1.604-3.783 2.34-3.78.738.002 1.328 2.346 6.199 4.632 4.87 2.286 8.409 1.216 8.409 1.216.777-.154.685.458.685.458s-.421.351-1.23.774c-1.2.196-4.127.392-7.82-1.34z'
          opacity='0.72'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint29_linear_21_137364)'
          d='M4.962 23.802c.044-.128.136-.358.26-.645.188.28.533.542.954.846.763.549 3.728 3.012 8.753 3.138 2.538.064 4.509-.5 5.836-1.074.733-.074 1.144-.198 1.144-.198.777-.154.686.458.686.458s-2.797 2.334-7.822 2.208c-5.025-.126-7.99-2.59-8.753-3.139-.763-.549-1.277-.964-1.058-1.594z'
        ></path>
        <path
          fill='url(#paint30_linear_21_137364)'
          d='M19.486 26.637s-.356 2.09-4.946 1.815c-4.588-.274-5.447-2.117-6.09-1.858-.645.26-1.395 3.512-1.494 4.142-.099.63.41.81 1.158 1.021.747.21 3.696 1.316 8.105-.357 4.41-1.672 3.933-4.606 3.933-4.606s-.01-.568-.666-.157z'
        ></path>
        <path
          fill='url(#paint31_linear_21_137364)'
          d='M19.486 26.637s-.356 2.09-4.946 1.815c-4.588-.274-5.447-2.117-6.09-1.858-.645.26-1.395 3.512-1.494 4.142-.099.63.41.81 1.158 1.021.747.21 3.696 1.316 8.105-.357 4.41-1.672 3.933-4.606 3.933-4.606s-.01-.568-.666-.157z'
        ></path>
        <path
          fill='url(#paint32_linear_21_137364)'
          d='M19.486 26.637s-.356 2.09-4.946 1.815c-4.588-.274-5.447-2.117-6.09-1.858-.645.26-1.395 3.512-1.494 4.142-.099.63.41.81 1.158 1.021.747.21 3.696 1.316 8.105-.357 4.41-1.672 3.933-4.606 3.933-4.606s-.01-.568-.666-.157z'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint33_linear_21_137364)'
          d='M6.956 30.735c.082-.527.622-2.891 1.174-3.8-.326 1.003-.581 2.157-.636 2.506-.098.63.41.811 1.158 1.021.747.21 3.696 1.316 8.105-.356 2.81-1.065 2.103-2.582 3.093-3.617.297-.027.302.305.302.305s.476 2.934-3.933 4.606c-4.409 1.672-7.358.567-8.105.357-.747-.21-1.257-.392-1.158-1.022z'
        ></path>
        <path
          fill='#FFFAFC'
          d='M8.747 27.442c.644-.26 1.502 1.584 6.09 1.858 3.496.21 3.452-.91 4.463-1.505.572-.596.852-1 .852-1s-.009-.569-.665-.158c0 0-.357 2.09-4.946 1.816-4.589-.275-5.448-2.118-6.091-1.859-.644.26-1.395 3.512-1.494 4.142-.053.344 1.185-3.05 1.79-3.293z'
          opacity='0.72'
        ></path>
        <path
          fill='url(#paint34_linear_21_137364)'
          d='M24.21 1.687c-2.106 0-7.973 5.45-7.973 6.017v25.327c0 .568 5.867 4.295 7.972 4.295 2.106 0 7.973-3.727 7.973-4.295V7.705c0-.566-5.867-6.017-7.973-6.017v-.001z'
        ></path>
        <path
          fill='url(#paint35_linear_21_137364)'
          d='M24.21 1.687c-2.106 0-7.973 5.45-7.973 6.017v25.327c0 .568 5.867 4.295 7.972 4.295 2.106 0 7.973-3.727 7.973-4.295V7.705c0-.566-5.867-6.017-7.973-6.017v-.001z'
        ></path>
        <path
          fill='url(#paint36_radial_21_137364)'
          fillRule='evenodd'
          d='M30.268 24.379s3.234 1.011 8.23-.774c6.712-2.395 7.28-8.946 7.28-8.946s-1.736 6.746-7.158 8.454c-5.42 1.707-8.05.951-8.05.951l-.304.315h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint37_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M30.268 24.379s3.234 1.011 8.23-.774c6.712-2.395 7.28-8.946 7.28-8.946s-1.736 6.746-7.158 8.454c-5.42 1.707-8.05.951-8.05.951l-.304.315h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint38_radial_21_137364)'
          fillRule='evenodd'
          d='M33.49 13.495l.935-.155-.215-2.867s7.313-4.252 9.582-.494c1.91 3.164.635 10.385-6.149 12.817-5.055 1.812-7.384.296-7.384.296l-1.685 1.504.9.608 1.098-1.138s3.916 1.088 8.34-.77c6.11-2.567 9.02-10.523 5.452-14.649-3.292-3.805-10.731.839-10.731.839l-.14 4.011-.002-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint39_radial_21_137364)'
          fillRule='evenodd'
          d='M34.233 10.788s6.058-3.148 8.802-1.661c0 0-2.337-2.293-8.827 1.346l.024.315h.001z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint40_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M34.233 10.788s6.058-3.148 8.802-1.661c0 0-2.337-2.293-8.827 1.346l.024.315h.001z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint41_radial_21_137364)'
          fillRule='evenodd'
          d='M34.426 13.34l-.216.41-.78.262.06-.517.936-.155z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint42_linear_21_137364)'
          fillRule='evenodd'
          d='M34.426 13.34l-.216.41-.78.262.06-.517.936-.155z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint43_radial_21_137364)'
          fillRule='evenodd'
          d='M28.312 24.76l1.163.442-.901-.608-.262.166z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint44_radial_21_137364)'
          fillRule='evenodd'
          d='M17.86 24.379s-3.234 1.011-8.23-.774C2.918 21.21 2.35 14.66 2.35 14.66s1.736 6.746 7.158 8.454c5.42 1.707 8.05.951 8.05.951l.304.315h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint45_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M17.86 24.379s-3.234 1.011-8.23-.774C2.918 21.21 2.35 14.66 2.35 14.66s1.736 6.746 7.158 8.454c5.42 1.707 8.05.951 8.05.951l.304.315h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint46_radial_21_137364)'
          fillRule='evenodd'
          d='M14.77 13.495l-.934-.155.216-2.867S6.739 6.221 4.469 9.98c-1.91 3.164-.634 10.385 6.15 12.817 5.054 1.812 7.383.296 7.383.296l1.686 1.504-.901.608-1.097-1.138s-3.917 1.088-8.34-.77C3.24 20.727.327 12.772 3.896 8.646c3.292-3.806 10.732.838 10.732.838l.14 4.011h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint47_radial_21_137364)'
          fillRule='evenodd'
          d='M13.897 10.788S7.839 7.64 5.096 9.127c0 0 2.337-2.293 8.826 1.346l-.023.315h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint48_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M13.897 10.788S7.839 7.64 5.096 9.127c0 0 2.337-2.293 8.826 1.346l-.023.315h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint49_radial_21_137364)'
          fillRule='evenodd'
          d='M13.705 13.34l.216.41.78.262-.062-.517-.934-.155z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint50_linear_21_137364)'
          fillRule='evenodd'
          d='M13.705 13.34l.216.41.78.262-.062-.517-.934-.155z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint51_radial_21_137364)'
          fillRule='evenodd'
          d='M19.818 24.76l-1.164.442.903-.608.261.166z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint52_radial_21_137364)'
          fillRule='evenodd'
          d='M25.15 33.207c-.968-1.903-.655-4.627-.655-4.627l1.021-.74-.805-.898-.778.173-.777-.173-.805.899 1.02.739s.314 2.724-.654 4.627c-.969 1.903-4.991 2.601-4.991 2.601s3.62.336 6.19.336c2.8 0 6.222-.336 6.222-.336s-4.023-.698-4.991-2.6h.003z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint53_radial_21_137364)'
          fillRule='evenodd'
          d='M17.727 35.808l-.327 1.588 13.057.1-.317-1.688s-2.938.27-6.207.27c-2.956 0-6.206-.27-6.206-.27z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint54_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M17.727 35.808l-.327 1.588 13.057.1-.317-1.688s-2.938.27-6.207.27c-2.956 0-6.206-.27-6.206-.27z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint55_radial_21_137364)'
          fillRule='evenodd'
          d='M17.727 35.808l-.327 1.588 13.057.1-.317-1.688s-2.938.27-6.207.27c-2.956 0-6.206-.27-6.206-.27z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint56_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M17.727 35.808l-.327 1.588 13.057.1-.317-1.688s-2.938.27-6.207.27c-2.956 0-6.206-.27-6.206-.27z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint57_radial_21_137364)'
          fillRule='evenodd'
          d='M33.671 6.3s-3.137-2.213-9.631-2.216h-.011c-6.494.003-9.631 2.216-9.631 2.216-.926 20.273 9.26 21.144 9.631 21.168h.011c.37-.026 10.557-.897 9.631-21.17V6.3z'
          clipRule='evenodd'
        ></path>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.8'
        >
          <path
            fill='url(#paint58_linear_21_137364)'
            d='M22.35 27.84s1.026.39 3.166 0l-1.02.74H23.37l-1.02-.74z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint59_linear_21_137364)'
            d='M22.35 27.84s1.026.39 3.166 0l-1.02.74H23.37l-1.02-.74z'
          ></path>
        </g>
        <path
          fill='url(#paint60_radial_21_137364)'
          fillRule='evenodd'
          d='M22.963 32.637l-.439-.345.664-.421h1.491l.505.383-.28.383h-1.94z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint61_linear_21_137364)'
          fillRule='evenodd'
          d='M22.963 32.637l-.439-.345.664-.421h1.491l.505.383-.28.383h-1.94z'
          clipRule='evenodd'
        ></path>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.8'
        >
          <path
            fill='url(#paint62_linear_21_137364)'
            d='M22.524 32.292s1.182.345 2.661-.038l-.28.383s-.425.118-.97.118c-.442 0-.97-.118-.97-.118l-.439-.345h-.002z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint63_linear_21_137364)'
            d='M22.524 32.292s1.182.345 2.661-.038l-.28.383s-.425.118-.97.118c-.442 0-.97-.118-.97-.118l-.439-.345h-.002z'
          ></path>
        </g>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.5'
        >
          <path
            fill='url(#paint64_linear_21_137364)'
            d='M23.525 28.58s.036 2.21-.155 3.29h.33v-3.29h-.175z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint65_linear_21_137364)'
            d='M23.525 28.58s.036 2.21-.155 3.29h.33v-3.29h-.175z'
          ></path>
        </g>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.5'
        >
          <path
            fill='url(#paint66_linear_21_137364)'
            d='M23.159 32.675s-.284 2.296-4.197 3.244l2.457.105s1.43-1.08 1.95-3.315l-.21-.034z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint67_linear_21_137364)'
            d='M23.159 32.675s-.284 2.296-4.197 3.244l2.457.105s1.43-1.08 1.95-3.315l-.21-.034z'
          ></path>
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint68_radial_21_137364)'
          d='M19.749 10.881c-2.25-2.319-6.149-2.165-8.708.343s-2.809 6.42-.559 8.74c2.25 2.318 6.149 2.165 8.708-.343s2.81-6.421.559-8.74z'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint69_radial_21_137364)'
          d='M24.22 27.058c1.798-1.053 1.714-4.617-.19-7.96-1.902-3.342-4.903-5.198-6.702-4.145-1.799 1.053-1.714 4.617.188 7.96 1.903 3.342 4.904 5.198 6.703 4.145z'
          opacity='0.3'
        ></path>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint70_radial_21_137364)'
          d='M21.593 26.29c.676-.53.312-2.155-.813-3.63-1.125-1.476-2.585-2.241-3.26-1.711-.677.53-.313 2.156.812 3.63 1.125 1.476 2.585 2.241 3.261 1.711z'
        ></path>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint71_linear_21_137364)'
          fillRule='evenodd'
          d='M20.18 24.998s-5.694-3.855-5.33-13.708c0 0-.08 7.78 5.33 13.708z'
          clipRule='evenodd'
          opacity='0.86'
        ></path>
        <g
          style={{ mixBlendMode: 'screen' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.86'
        >
          <path
            fill='url(#paint72_linear_21_137364)'
            d='M24.797 36.072s-.499-1.79-.6-3.317l.46-.065s.44 2.608 4.237 3.212l-4.095.168-.002.002z'
            style={{ mixBlendMode: 'screen' }}
          ></path>
          <path
            fill='url(#paint73_linear_21_137364)'
            d='M24.797 36.072s-.499-1.79-.6-3.317l.46-.065s.44 2.608 4.237 3.212l-4.095.168-.002.002z'
          ></path>
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint74_linear_21_137364)'
          fillRule='evenodd'
          d='M16.09 20.04s2.933 6.601 7.843 6.601 7.747-5.876 7.747-5.876-2.18 5.92-7.65 6.7c0 0-5.169-.216-7.941-7.427l.002.001z'
          clipRule='evenodd'
          opacity='0.86'
        ></path>
        <path
          fill='url(#paint75_radial_21_137364)'
          fillRule='evenodd'
          d='M10.885 24.665s3.316 4.375 7.244-.91l-.101-.134-7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint76_linear_21_137364)'
          fillRule='evenodd'
          d='M10.885 24.665s3.316 4.375 7.244-.91l-.101-.134-7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint77_radial_21_137364)'
          fillRule='evenodd'
          d='M10.885 24.665s3.316 4.375 7.244-.91l-.101-.134-7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint78_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M10.885 24.665s3.316 4.375 7.244-.91l-.101-.134-7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint79_linear_21_137364)'
          fillRule='evenodd'
          d='M17.65 23.196l-6.765 1.467s3.64 3.912 7.143-1.044l-.378-.423z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint80_linear_21_137364)'
          fillOpacity='0.3'
          fillRule='evenodd'
          d='M17.65 23.196l-6.765 1.467s3.64 3.912 7.143-1.044l-.378-.423z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint81_radial_21_137364)'
          fillRule='evenodd'
          d='M13.55 26.754s3.619 2.577 5.103-2.435l-.172-.199-4.93 2.634z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint82_linear_21_137364)'
          fillRule='evenodd'
          d='M13.55 26.754s3.619 2.577 5.103-2.435l-.172-.199-4.93 2.634z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint83_radial_21_137364)'
          fillRule='evenodd'
          d='M13.55 26.754s3.619 2.577 5.103-2.435l-.172-.199-4.93 2.634z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint84_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M13.55 26.754s3.619 2.577 5.103-2.435l-.172-.199-4.93 2.634z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint85_linear_21_137364)'
          fillRule='evenodd'
          d='M18.03 23.619l-4.477 3.133s3.464 2.085 4.93-2.633l-.454-.5z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint86_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M18.03 23.619l-4.477 3.133s3.464 2.085 4.93-2.633l-.454-.5z'
          clipRule='evenodd'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint87_linear_21_137364)'
          fillRule='evenodd'
          d='M16.848 26.715l.3.164.515-.514-.271-.141s-.265.318-.544.492v-.001z'
          clipRule='evenodd'
          opacity='0.6'
        ></path>
        <path
          fill='url(#paint88_radial_21_137364)'
          fillRule='evenodd'
          d='M37.245 24.665s-3.315 4.375-7.243-.91l.1-.134 7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint89_linear_21_137364)'
          fillRule='evenodd'
          d='M37.245 24.665s-3.315 4.375-7.243-.91l.1-.134 7.143 1.044z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint90_linear_21_137364)'
          fillRule='evenodd'
          d='M30.48 23.196l6.765 1.467s-3.64 3.912-7.142-1.044l.377-.423z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint91_linear_21_137364)'
          fillOpacity='0.3'
          fillRule='evenodd'
          d='M30.48 23.196l6.765 1.467s-3.64 3.912-7.142-1.044l.377-.423z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint92_radial_21_137364)'
          fillRule='evenodd'
          d='M34.58 26.754s-3.619 2.577-5.103-2.435l.172-.198 4.93 2.633z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint93_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M34.58 26.754s-3.619 2.577-5.103-2.435l.172-.198 4.93 2.633z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint94_linear_21_137364)'
          fillRule='evenodd'
          d='M30.1 23.619l4.477 3.133s-3.464 2.085-4.93-2.633l.454-.5z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint95_linear_21_137364)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M30.1 23.619l4.477 3.133s-3.464 2.085-4.93-2.633l.454-.5z'
          clipRule='evenodd'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint96_linear_21_137364)'
          fillRule='evenodd'
          d='M31.282 26.715l-.3.164-.515-.514.271-.141s.265.318.544.492v-.001z'
          clipRule='evenodd'
          opacity='0.6'
        ></path>
        <path
          fill='#FFFF60'
          fillRule='evenodd'
          d='M24.197 36.077s3.2-.269 4.696-.175c0 0-2.697.2-4.696.175zM24.104 32.423s.228-.534.64-.075c0 0-.433.065-.64.075zM24.315 27.993s.19-.676.739-.079c0 0-.579.069-.74.079z'
          clipRule='evenodd'
        ></path>
        <g style={{ mixBlendMode: 'hard-light' }}>
          <path
            fill='url(#paint97_linear_21_137364)'
            d='M28.685 10.81l-2.09-.514a.803.803 0 01-.476-.335l-1.205-1.81a.792.792 0 00-.599-.355.792.792 0 00-.599.355l-1.204 1.81a.802.802 0 01-.477.335l-2.09.514c-.567.139-.803.826-.444 1.294l1.321 1.723c.123.16.183.361.167.564l-.165 2.178c-.045.59.525 1.032 1.074.83l2.021-.745a.79.79 0 01.396-.038.791.791 0 01.396.038l2.022.745c.548.202 1.119-.24 1.074-.83l-.166-2.178a.825.825 0 01.167-.564l1.322-1.723c.358-.468.122-1.155-.445-1.294z'
          ></path>
          <path
            fill='url(#paint98_linear_21_137364)'
            d='M29.009 10.67l-2.245-.552a.863.863 0 01-.512-.362l-1.294-1.949a.85.85 0 00-.643-.382.85.85 0 00-.643.382l-1.294 1.949a.862.862 0 01-.512.362l-2.244.552c-.61.15-.863.89-.478 1.394l1.42 1.856a.89.89 0 01.179.607l-.178 2.346c-.048.636.565 1.11 1.154.893l2.17-.801a.846.846 0 01.426-.042.847.847 0 01.426.042l2.171.801c.589.218 1.202-.257 1.154-.893l-.178-2.346a.89.89 0 01.18-.607l1.418-1.856c.385-.504.132-1.244-.477-1.394z'
          ></path>
          <g filter='url(#filter0_f_21_137364)'>
            <path
              fill='url(#paint99_linear_21_137364)'
              d='M28.361 10.949l-1.935-.474a.743.743 0 01-.441-.31l-1.116-1.67a.733.733 0 00-.554-.328.734.734 0 00-.554.328l-1.116 1.67a.743.743 0 01-.44.31l-1.936.474a.756.756 0 00-.412 1.195l1.224 1.59a.76.76 0 01.154.521l-.153 2.01a.745.745 0 00.994.766l1.872-.687a.734.734 0 01.367-.035.736.736 0 01.367.035l1.872.687a.746.746 0 00.994-.765l-.153-2.011a.76.76 0 01.154-.52l1.224-1.591a.756.756 0 00-.412-1.195z'
            ></path>
          </g>
          <g filter='url(#filter1_f_21_137364)'>
            <path
              fill='url(#paint100_linear_21_137364)'
              d='M27.714 11.228l-1.625-.395a.624.624 0 01-.37-.258l-.938-1.392a.617.617 0 00-.466-.273.617.617 0 00-.465.273l-.937 1.392a.624.624 0 01-.37.258l-1.626.395a.628.628 0 00-.346.995l1.027 1.326a.63.63 0 01.13.434l-.128 1.675a.625.625 0 00.835.639l1.572-.573a.62.62 0 01.308-.03.621.621 0 01.308.03l1.573.573a.625.625 0 00.835-.639l-.129-1.675a.63.63 0 01.13-.434l1.028-1.326a.628.628 0 00-.346-.995z'
            ></path>
          </g>
          <g filter='url(#filter2_f_21_137364)'>
            <path
              fill='url(#paint101_linear_21_137364)'
              d='M27.067 11.251l-1.316-.335a.507.507 0 01-.3-.22l-.759-1.183a.495.495 0 00-.377-.232.495.495 0 00-.377.232l-.758 1.183a.507.507 0 01-.3.22l-1.316.335c-.357.091-.506.54-.28.847l.832 1.126a.554.554 0 01.105.37l-.104 1.423c-.029.386.33.675.676.543l1.273-.487a.48.48 0 01.25-.025.482.482 0 01.249.025l1.272.487c.346.132.705-.157.677-.543l-.105-1.424a.554.554 0 01.106-.369l.832-1.126c.225-.306.076-.756-.28-.847z'
            ></path>
          </g>
          <path
            fill='#D9D9D9'
            fillOpacity='0.24'
            d='M26.426 10.572l1.935.493c.525.134.744.795.412 1.244-1.223 1.657-6.858 2.726-7.538 2.2a.815.815 0 00-.154-.543l-1.224-1.657c-.332-.45-.113-1.11.412-1.244l1.935-.493a.744.744 0 00.441-.323l1.116-1.74a.729.729 0 01.554-.342c.213.019.42.131.554.342l1.116 1.74a.745.745 0 00.441.323z'
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_21_137364'
          width='17.231'
          height='16.91'
          x='15.7'
          y='4.167'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_21_137364'
            stdDeviation='2'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter1_f_21_137364'
          width='15.754'
          height='15.425'
          x='16.439'
          y='4.91'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_21_137364'
            stdDeviation='2'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter2_f_21_137364'
          width='14.277'
          height='14.311'
          x='17.177'
          y='5.281'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_21_137364'
            stdDeviation='2'
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id='paint0_linear_21_137364'
          x1='37.682'
          x2='37.682'
          y1='-3.323'
          y2='32.921'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#CE7C1D'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_137364'
          x1='33.688'
          x2='44.805'
          y1='10.467'
          y2='10.467'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#693B04'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#E2C046'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_137364'
          x1='33.688'
          x2='44.805'
          y1='10.467'
          y2='10.467'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#691704'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#E27546'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_137364'
          x1='10.973'
          x2='10.973'
          y1='-3.323'
          y2='32.922'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#CE7C1D'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_137364'
          x1='7.064'
          x2='17.945'
          y1='10.467'
          y2='10.467'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_137364'
          x1='7.064'
          x2='17.945'
          y1='10.467'
          y2='10.467'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_137364'
          x1='27.853'
          x2='48.881'
          y1='6.794'
          y2='6.794'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#691704'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='0.98' stopColor='#E27546'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_137364'
          x1='33.819'
          x2='33.61'
          y1='-3.038'
          y2='24.407'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_137364'
          x1='25.774'
          x2='53.082'
          y1='12.287'
          y2='12.287'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='0.98' stopColor='#E2C046'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_137364'
          x1='25.774'
          x2='53.082'
          y1='12.287'
          y2='12.287'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#691704'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='0.98' stopColor='#E27546'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_137364'
          x1='42.723'
          x2='32.067'
          y1='4.107'
          y2='27.552'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_137364'
          x1='25.756'
          x2='50.583'
          y1='21.15'
          y2='21.15'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='0.98' stopColor='#E2C046'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_137364'
          x1='25.756'
          x2='50.583'
          y1='21.15'
          y2='21.15'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#691704'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='0.98' stopColor='#E27546'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_137364'
          x1='45.269'
          x2='32.68'
          y1='6.442'
          y2='28.496'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_137364'
          x1='28.205'
          x2='46.767'
          y1='27.259'
          y2='27.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='0.98' stopColor='#E2C046'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_137364'
          x1='28.205'
          x2='46.767'
          y1='27.259'
          y2='27.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#691704'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='0.98' stopColor='#E27546'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_137364'
          x1='40.664'
          x2='34.745'
          y1='4.25'
          y2='29.166'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_137364'
          x1='5.469'
          x2='26.497'
          y1='6.794'
          y2='6.794'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint18_linear_21_137364'
          x1='5.469'
          x2='26.497'
          y1='6.794'
          y2='6.794'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <linearGradient
          id='paint19_linear_21_137364'
          x1='14.603'
          x2='14.812'
          y1='-3.019'
          y2='24.425'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint20_linear_21_137364'
          x1='3.036'
          x2='30.343'
          y1='12.287'
          y2='12.287'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.7' stopColor='#924810'></stop>
          <stop offset='0.81' stopColor='#B95620'></stop>
          <stop offset='0.87' stopColor='#B85F20'></stop>
          <stop offset='0.98' stopColor='#C2701A'></stop>
        </linearGradient>
        <linearGradient
          id='paint21_linear_21_137364'
          x1='6.848'
          x2='15.454'
          y1='13.265'
          y2='23.39'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint22_linear_21_137364'
          x1='3.036'
          x2='30.343'
          y1='12.287'
          y2='12.287'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint23_linear_21_137364'
          x1='3.036'
          x2='30.343'
          y1='12.287'
          y2='12.287'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <linearGradient
          id='paint24_linear_21_137364'
          x1='5.68'
          x2='16.336'
          y1='4.135'
          y2='27.581'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint25_linear_21_137364'
          x1='4.836'
          x2='29.663'
          y1='21.15'
          y2='21.15'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.7' stopColor='#924810'></stop>
          <stop offset='0.81' stopColor='#B95620'></stop>
          <stop offset='0.87' stopColor='#B85F20'></stop>
          <stop offset='0.98' stopColor='#C2701A'></stop>
        </linearGradient>
        <linearGradient
          id='paint26_linear_21_137364'
          x1='8.302'
          x2='11.814'
          y1='21.654'
          y2='28.946'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint27_linear_21_137364'
          x1='4.836'
          x2='29.663'
          y1='21.15'
          y2='21.15'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint28_linear_21_137364'
          x1='4.836'
          x2='29.663'
          y1='21.15'
          y2='21.15'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <linearGradient
          id='paint29_linear_21_137364'
          x1='3.129'
          x2='15.717'
          y1='6.464'
          y2='28.519'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint30_linear_21_137364'
          x1='6.888'
          x2='25.449'
          y1='27.259'
          y2='27.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#693B04'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='0.98' stopColor='#E2C046'></stop>
        </linearGradient>
        <linearGradient
          id='paint31_linear_21_137364'
          x1='6.888'
          x2='25.449'
          y1='27.259'
          y2='27.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint32_linear_21_137364'
          x1='6.888'
          x2='25.449'
          y1='27.259'
          y2='27.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <linearGradient
          id='paint33_linear_21_137364'
          x1='7.75'
          x2='13.669'
          y1='4.26'
          y2='29.177'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.25' stopColor='#F7E436'></stop>
          <stop offset='1' stopColor='#D58D21'></stop>
        </linearGradient>
        <linearGradient
          id='paint34_linear_21_137364'
          x1='26.346'
          x2='25.413'
          y1='59.028'
          y2='4.269'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E2C046'></stop>
          <stop offset='0.505' stopColor='#DD9120'></stop>
          <stop offset='1' stopColor='#693B04'></stop>
        </linearGradient>
        <linearGradient
          id='paint35_linear_21_137364'
          x1='16.169'
          x2='35.476'
          y1='6.411'
          y2='22.172'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E27546'></stop>
          <stop offset='0.505' stopColor='#DD7020'></stop>
          <stop offset='1' stopColor='#691704'></stop>
        </linearGradient>
        <radialGradient
          id='paint36_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-6.67732 0 0 -11.8632 35.037 20.262)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint37_linear_21_137364'
          x1='33.24'
          x2='38.047'
          y1='16.577'
          y2='24.029'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint38_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(9.39527 0 0 18.294 40.581 17.266)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint39_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-3.79952 0 0 -2.81528 36.923 9.744)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint40_linear_21_137364'
          x1='35.9'
          x2='36.529'
          y1='8.869'
          y2='11.204'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint41_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(.41847 0 0 .81482 34.12 13.728)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint42_linear_21_137364'
          x1='33.621'
          x2='33.95'
          y1='13.469'
          y2='13.956'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint43_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(.4404 0 0 .85753 29.117 24.957)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <radialGradient
          id='paint44_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-6.67732 0 0 -11.8632 7.121 20.262)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint45_linear_21_137364'
          x1='5.324'
          x2='10.131'
          y1='16.577'
          y2='24.029'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint46_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.08437 0 0 -.16428 7.68 -12.166)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint47_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-3.79953 0 0 -2.81528 7.81 9.744)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint48_linear_21_137364'
          x1='6.788'
          x2='7.416'
          y1='8.869'
          y2='11.204'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint49_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.41847 0 0 -.81482 14.012 13.728)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint50_linear_21_137364'
          x1='13.896'
          x2='14.225'
          y1='13.469'
          y2='13.956'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint51_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.4404 0 0 -.85753 19.013 24.957)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <radialGradient
          id='paint52_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(6.46094 0 0 12.5804 25.008 35.527)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint53_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(8.08756 0 0 9.10413 24.711 36.45)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.51' stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#D45E2C'></stop>
        </radialGradient>
        <linearGradient
          id='paint54_linear_21_137364'
          x1='19.903'
          x2='20.13'
          y1='36.132'
          y2='37.882'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint55_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-5.62048 0 0 -2.00061 21.416 36.753)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint56_linear_21_137364'
          x1='19.903'
          x2='20.13'
          y1='36.132'
          y2='37.882'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint57_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(13.6103 0 0 26.5013 27.866 7.183)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#F17A1F'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.87' stopColor='#F57C27'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <linearGradient
          id='paint58_linear_21_137364'
          x1='22.351'
          x2='25.516'
          y1='28.21'
          y2='28.21'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint59_linear_21_137364'
          x1='22.957'
          x2='23.13'
          y1='27.982'
          y2='28.722'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint60_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(2.20539 0 0 2.08002 24.203 32.512)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <linearGradient
          id='paint61_linear_21_137364'
          x1='23.034'
          x2='23.25'
          y1='32.018'
          y2='32.764'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint62_linear_21_137364'
          x1='22.524'
          x2='25.184'
          y1='32.504'
          y2='32.504'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint63_linear_21_137364'
          x1='23.035'
          x2='23.131'
          y1='32.35'
          y2='32.861'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint64_linear_21_137364'
          x1='23.534'
          x2='23.534'
          y1='31.871'
          y2='28.58'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint65_linear_21_137364'
          x1='23.433'
          x2='23.779'
          y1='29.211'
          y2='29.245'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint66_linear_21_137364'
          x1='21.166'
          x2='21.166'
          y1='36.024'
          y2='32.675'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint67_linear_21_137364'
          x1='19.807'
          x2='21.509'
          y1='33.317'
          y2='35.555'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint68_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(4.63327 -4.5412 4.09297 4.17596 15.077 15.387)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF0'></stop>
          <stop offset='0.13' stopColor='#C3C300'></stop>
          <stop offset='0.25' stopColor='#909000'></stop>
          <stop offset='0.38' stopColor='#646400'></stop>
          <stop offset='0.5' stopColor='#404000'></stop>
          <stop offset='0.63' stopColor='#232300'></stop>
          <stop offset='0.75' stopColor='#0F0F00'></stop>
          <stop offset='0.87' stopColor='#030300'></stop>
          <stop offset='1'></stop>
        </radialGradient>
        <radialGradient
          id='paint69_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-30.35 48.94 -27.268) scale(3.76105 6.95075)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C03BC1'></stop>
          <stop offset='0.03' stopColor='#C345C4'></stop>
          <stop offset='0.21' stopColor='#D57DD6'></stop>
          <stop offset='0.39' stopColor='#E4ABE4'></stop>
          <stop offset='0.55' stopColor='#EFD0F0'></stop>
          <stop offset='0.71' stopColor='#F8E9F8'></stop>
          <stop offset='0.85' stopColor='#FDF9FD'></stop>
          <stop offset='0.96' stopColor='#fff'></stop>
        </radialGradient>
        <radialGradient
          id='paint70_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(1.2196 -.95664 2.07797 2.64918 19.534 24.047)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF8100'></stop>
          <stop offset='0.13' stopColor='#C36300'></stop>
          <stop offset='0.25' stopColor='#904900'></stop>
          <stop offset='0.38' stopColor='#643200'></stop>
          <stop offset='0.5' stopColor='#402000'></stop>
          <stop offset='0.63' stopColor='#231200'></stop>
          <stop offset='0.75' stopColor='#0F0800'></stop>
          <stop offset='0.87' stopColor='#030200'></stop>
          <stop offset='1'></stop>
        </radialGradient>
        <linearGradient
          id='paint71_linear_21_137364'
          x1='20.56'
          x2='14.473'
          y1='18.322'
          y2='17.977'
          gradientUnits='userSpaceOnUse'
        >
          <stop></stop>
          <stop offset='0.09' stopColor='#060400'></stop>
          <stop offset='0.22' stopColor='#191000'></stop>
          <stop offset='0.37' stopColor='#392400'></stop>
          <stop offset='0.54' stopColor='#654000'></stop>
          <stop offset='0.72' stopColor='#9D6500'></stop>
          <stop offset='0.92' stopColor='#E09000'></stop>
          <stop offset='1' stopColor='#FFA400'></stop>
        </linearGradient>
        <linearGradient
          id='paint72_linear_21_137364'
          x1='26.546'
          x2='26.546'
          y1='36.072'
          y2='32.69'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA400'></stop>
          <stop offset='0.08' stopColor='#E09000'></stop>
          <stop offset='0.28' stopColor='#9D6500'></stop>
          <stop offset='0.46' stopColor='#654000'></stop>
          <stop offset='0.63' stopColor='#392400'></stop>
          <stop offset='0.78' stopColor='#191000'></stop>
          <stop offset='0.91' stopColor='#060400'></stop>
          <stop offset='0.99'></stop>
        </linearGradient>
        <linearGradient
          id='paint73_linear_21_137364'
          x1='25.098'
          x2='26.788'
          y1='33.339'
          y2='35.688'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint74_linear_21_137364'
          x1='16.091'
          x2='31.68'
          y1='23.753'
          y2='23.753'
          gradientUnits='userSpaceOnUse'
        >
          <stop></stop>
          <stop offset='0.09' stopColor='#060400'></stop>
          <stop offset='0.22' stopColor='#191000'></stop>
          <stop offset='0.37' stopColor='#392400'></stop>
          <stop offset='0.54' stopColor='#654000'></stop>
          <stop offset='0.72' stopColor='#9D6500'></stop>
          <stop offset='0.92' stopColor='#E09000'></stop>
          <stop offset='1' stopColor='#FFA400'></stop>
        </linearGradient>
        <radialGradient
          id='paint75_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(4.108 -1.46139 .88647 2.49188 14.974 24.623)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint76_linear_21_137364'
          x1='12.274'
          x2='13.233'
          y1='24.147'
          y2='26.678'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint77_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-3.11821 0 0 -3.25417 13.113 25.158)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint78_linear_21_137364'
          x1='12.274'
          x2='13.233'
          y1='24.147'
          y2='26.678'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint79_linear_21_137364'
          x1='10.885'
          x2='18.03'
          y1='24.663'
          y2='24.663'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint80_linear_21_137364'
          x1='12.255'
          x2='13.341'
          y1='23.759'
          y2='26.405'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint81_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(2.74406 -2.13305 1.28438 1.6523 16.569 25.723)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint82_linear_21_137364'
          x1='14.529'
          x2='16.113'
          y1='24.752'
          y2='27.205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint83_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-2.19648 0 0 -3.90808 15.12 25.966)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint84_linear_21_137364'
          x1='14.529'
          x2='16.113'
          y1='24.752'
          y2='27.205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint85_linear_21_137364'
          x1='13.485'
          x2='18.72'
          y1='26.541'
          y2='24.85'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint86_linear_21_137364'
          x1='14.498'
          x2='16.301'
          y1='24.308'
          y2='26.782'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint87_linear_21_137364'
          x1='16.848'
          x2='17.663'
          y1='26.55'
          y2='26.55'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EABDD3'></stop>
          <stop offset='0.99' stopColor='#DD97C1'></stop>
        </linearGradient>
        <radialGradient
          id='paint88_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-4.108 -1.46142 .88648 -2.49187 33.154 24.627)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint89_linear_21_137364'
          x1='31.39'
          x2='32.349'
          y1='24.147'
          y2='26.678'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint90_linear_21_137364'
          x1='37.243'
          x2='30.101'
          y1='1.837'
          y2='1.837'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint91_linear_21_137364'
          x1='31.472'
          x2='32.559'
          y1='23.759'
          y2='26.405'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint92_radial_21_137364'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-2.19648 0 0 -3.90808 31.046 25.966)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint93_linear_21_137364'
          x1='30.455'
          x2='32.039'
          y1='24.752'
          y2='27.205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint94_linear_21_137364'
          x1='34.645'
          x2='29.41'
          y1='26.534'
          y2='24.843'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint95_linear_21_137364'
          x1='30.592'
          x2='32.395'
          y1='24.308'
          y2='26.782'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint96_linear_21_137364'
          x1='31.282'
          x2='30.465'
          y1='26.55'
          y2='26.55'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EABDD3'></stop>
          <stop offset='0.99' stopColor='#DD97C1'></stop>
        </linearGradient>
        <linearGradient
          id='paint97_linear_21_137364'
          x1='22.095'
          x2='26.162'
          y1='11.104'
          y2='15.107'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CD8315'></stop>
          <stop offset='1' stopColor='#F07222'></stop>
        </linearGradient>
        <linearGradient
          id='paint98_linear_21_137364'
          x1='21.931'
          x2='26.31'
          y1='10.988'
          y2='15.286'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CD8315'></stop>
          <stop offset='1' stopColor='#F07222'></stop>
        </linearGradient>
        <linearGradient
          id='paint99_linear_21_137364'
          x1='22.26'
          x2='26.014'
          y1='11.221'
          y2='14.927'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFBD59'></stop>
          <stop offset='0.896' stopColor='#F07222'></stop>
          <stop offset='1' stopColor='#F07222'></stop>
        </linearGradient>
        <linearGradient
          id='paint100_linear_21_137364'
          x1='22.589'
          x2='25.717'
          y1='11.455'
          y2='14.568'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC570'></stop>
          <stop offset='1' stopColor='#FE9E60'></stop>
        </linearGradient>
        <linearGradient
          id='paint101_linear_21_137364'
          x1='22.918'
          x2='25.573'
          y1='11.444'
          y2='13.961'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD99F'></stop>
          <stop offset='1' stopColor='#FFAB75'></stop>
        </linearGradient>
        <clipPath id='clip0_21_137364'>
          <path
            fill='#fff'
            d='M0 0H48V40.094H0z'
            transform='translate(.5)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export const DashedLine = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='156'
      height='2'
      fill='none'
      viewBox='0 0 156 2'
    >
      <path
        stroke='#3C3553'
        strokeDasharray='1 4'
        strokeLinecap='round'
        strokeWidth='2'
        d='M1 1h154'
      ></path>
    </svg>
  )
}

export const NormalRankBadge = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='66'
      height='50'
      fill='none'
      viewBox='0 0 66 50'
    >
      <g style={{ mixBlendMode: 'overlay' }}>
        <path
          fill='url(#paint0_linear_30_145209)'
          d='M14.4 23.425c3.836 3.04 6.448 6.914 7.617 10.787.245.812-.89 2.243-1.735 2.19-4.038-.254-8.406-1.912-12.242-4.953-3.836-3.04-6.449-6.914-7.617-10.787-.245-.811.889-2.242 1.735-2.19 4.038.254 8.405 1.913 12.242 4.953z'
        ></path>
        <path
          fill='url(#paint1_linear_30_145209)'
          d='M14.4 23.425c3.836 3.04 6.448 6.914 7.617 10.787.245.812-.89 2.243-1.735 2.19-4.038-.254-8.406-1.912-12.242-4.953-3.836-3.04-6.449-6.914-7.617-10.787-.245-.811.889-2.242 1.735-2.19 4.038.254 8.405 1.913 12.242 4.953z'
        ></path>
        <path
          fill='url(#paint2_linear_30_145209)'
          d='M51.88 23.425c-3.836 3.04-6.449 6.914-7.618 10.787-.244.812.89 2.243 1.736 2.19 4.038-.254 8.405-1.912 12.242-4.953 3.836-3.04 6.448-6.914 7.617-10.787.245-.811-.89-2.242-1.735-2.19-4.038.254-8.406 1.913-12.242 4.953z'
        ></path>
        <path
          fill='url(#paint3_linear_30_145209)'
          d='M51.88 23.425c-3.836 3.04-6.449 6.914-7.618 10.787-.244.812.89 2.243 1.736 2.19 4.038-.254 8.405-1.912 12.242-4.953 3.836-3.04 6.448-6.914 7.617-10.787.245-.811-.89-2.242-1.735-2.19-4.038.254-8.406 1.913-12.242 4.953z'
        ></path>
        <path
          fill='url(#paint4_linear_30_145209)'
          d='M33.14 49.664C46.76 49.664 57.803 38.62 57.803 25 57.803 11.38 46.761.337 33.14.337S8.477 11.379 8.477 25 19.519 49.664 33.14 49.664z'
        ></path>
        <path
          fill='url(#paint5_linear_30_145209)'
          d='M33.14 44.659c10.857 0 19.658-8.802 19.658-19.659S43.997 5.342 33.14 5.342C22.283 5.342 13.48 14.143 13.48 25c0 10.857 8.802 19.659 19.659 19.659z'
        ></path>
        <path
          fill='url(#paint6_linear_30_145209)'
          d='M33.14 41.877c9.321 0 16.877-7.556 16.877-16.877 0-9.32-7.556-16.877-16.876-16.877-9.321 0-16.877 7.556-16.877 16.877 0 9.32 7.556 16.877 16.877 16.877z'
        ></path>
        <path
          fill='url(#paint7_linear_30_145209)'
          d='M8.477 25c0 13.621 11.042 24.663 24.663 24.663S57.803 38.621 57.803 25c0-.096-.002-.19-.003-.286H8.48c-.001.095-.003.19-.003.286z'
        ></path>
        <path
          fill='url(#paint8_linear_30_145209)'
          d='M33.14.337c-.103 0-.204.003-.306.004l-3.807 3.806 27.786 27.786c.643-2.2.99-4.526.99-6.933C57.803 11.38 46.76.337 33.14.337z'
        ></path>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_30_145209'
          x1='4.739'
          x2='10.617'
          y1='19.253'
          y2='26.66'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_30_145209'
          x1='6.186'
          x2='11.771'
          y1='26.169'
          y2='27.564'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_30_145209'
          x1='61.547'
          x2='55.67'
          y1='19.247'
          y2='26.655'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_30_145209'
          x1='60.101'
          x2='54.516'
          y1='26.163'
          y2='27.559'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_30_145209'
          x1='17.934'
          x2='43.945'
          y1='9.794'
          y2='35.805'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_30_145209'
          x1='45.908'
          x2='19.999'
          y1='37.768'
          y2='11.86'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_30_145209'
          x1='20.872'
          x2='41.067'
          y1='12.731'
          y2='32.926'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_30_145209'
          x1='33.14'
          x2='33.14'
          y1='32.216'
          y2='48.404'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_30_145209'
          x1='45.293'
          x2='35.781'
          y1='14.581'
          y2='-2.065'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const CardRankBGSecond = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='72'
      height='42'
      fill='none'
      viewBox='0 0 69 32'
    >
      <path
        fill='url(#paint0_linear_21_135320)'
        d='M19.76 25.163c4.612.603 8.635 2.5 11.483 5.113.597.547.375 2.29-.34 2.665-3.41 1.794-7.777 2.594-12.389 1.99-4.611-.603-8.634-2.5-11.483-5.112-.597-.547-.375-2.29.34-2.665 3.41-1.795 7.777-2.594 12.389-1.991z'
      ></path>
      <path
        fill='url(#paint1_linear_21_135320)'
        d='M19.76 25.163c4.612.603 8.635 2.5 11.483 5.113.597.547.375 2.29-.34 2.665-3.41 1.794-7.777 2.594-12.389 1.99-4.611-.603-8.634-2.5-11.483-5.112-.597-.547-.375-2.29.34-2.665 3.41-1.795 7.777-2.594 12.389-1.991z'
      ></path>
      <path
        fill='url(#paint2_linear_21_135320)'
        d='M19.76 25.163c4.612.603 8.635 2.5 11.483 5.113.597.547.375 2.29-.34 2.665-3.41 1.794-7.777 2.594-12.389 1.99-4.611-.603-8.634-2.5-11.483-5.112-.597-.547-.375-2.29.34-2.665 3.41-1.795 7.777-2.594 12.389-1.991z'
      ></path>
      <path
        fill='url(#paint3_linear_21_135320)'
        d='M20.3 18.623c4.931 2.166 8.8 5.578 11.139 9.403.49.802-.308 2.665-1.22 2.85-4.356.888-9.46.36-14.392-1.806-4.932-2.165-8.8-5.577-11.139-9.403-.49-.801.308-2.664 1.22-2.85 4.356-.887 9.46-.359 14.391 1.806z'
      ></path>
      <path
        fill='url(#paint4_linear_21_135320)'
        d='M20.3 18.623c4.931 2.166 8.8 5.578 11.139 9.403.49.802-.308 2.665-1.22 2.85-4.356.888-9.46.36-14.392-1.806-4.932-2.165-8.8-5.577-11.139-9.403-.49-.801.308-2.664 1.22-2.85 4.356-.887 9.46-.359 14.391 1.806z'
      ></path>
      <path
        fill='url(#paint5_linear_21_135320)'
        d='M20.3 18.623c4.931 2.166 8.8 5.578 11.139 9.403.49.802-.308 2.665-1.22 2.85-4.356.888-9.46.36-14.392-1.806-4.932-2.165-8.8-5.577-11.139-9.403-.49-.801.308-2.664 1.22-2.85 4.356-.887 9.46-.359 14.391 1.806z'
      ></path>
      <path
        fill='url(#paint6_linear_21_135320)'
        d='M21.981 8.315c5.029 4.578 8.298 10.214 9.578 15.724.268 1.154-1.419 3.054-2.58 2.906-5.546-.704-11.426-3.4-16.454-7.978C7.496 14.39 4.226 8.753 2.947 3.243 2.679 2.09 4.365.19 5.527.337c5.546.704 11.425 3.4 16.454 7.978z'
      ></path>
      <path
        fill='url(#paint7_linear_21_135320)'
        d='M5.251 9.504c1.711 3.373 4.154 6.621 7.275 9.463 5.028 4.579 10.908 7.274 16.454 7.978 1.161.147 2.848-1.752 2.58-2.906-1.28-5.51-4.55-11.146-9.578-15.724-2.961-2.697-6.217-4.739-9.527-6.107L5.251 9.504z'
      ></path>
      <path
        fill='url(#paint8_linear_21_135320)'
        d='M58.24 25.163c-4.612.603-8.635 2.5-11.483 5.113-.597.547-.375 2.29.34 2.665 3.41 1.794 7.777 2.594 12.389 1.99 4.611-.603 8.634-2.5 11.483-5.112.597-.547.375-2.29-.34-2.665-3.41-1.795-7.777-2.594-12.389-1.991z'
      ></path>
      <path
        fill='url(#paint9_linear_21_135320)'
        d='M58.24 25.163c-4.612.603-8.635 2.5-11.483 5.113-.597.547-.375 2.29.34 2.665 3.41 1.794 7.777 2.594 12.389 1.99 4.611-.603 8.634-2.5 11.483-5.112.597-.547.375-2.29-.34-2.665-3.41-1.795-7.777-2.594-12.389-1.991z'
      ></path>
      <path
        fill='url(#paint10_linear_21_135320)'
        d='M58.24 25.163c-4.612.603-8.635 2.5-11.483 5.113-.597.547-.375 2.29.34 2.665 3.41 1.794 7.777 2.594 12.389 1.99 4.611-.603 8.634-2.5 11.483-5.112.597-.547.375-2.29-.34-2.665-3.41-1.795-7.777-2.594-12.389-1.991z'
      ></path>
      <path
        fill='url(#paint11_linear_21_135320)'
        d='M57.7 18.623c-4.931 2.166-8.8 5.578-11.138 9.403-.49.802.308 2.665 1.22 2.85 4.356.888 9.46.36 14.391-1.806 4.932-2.165 8.801-5.577 11.14-9.403.489-.801-.309-2.664-1.22-2.85-4.357-.887-9.46-.359-14.392 1.806z'
      ></path>
      <path
        fill='url(#paint12_linear_21_135320)'
        d='M57.7 18.623c-4.931 2.166-8.8 5.578-11.138 9.403-.49.802.308 2.665 1.22 2.85 4.356.888 9.46.36 14.391-1.806 4.932-2.165 8.801-5.577 11.14-9.403.489-.801-.309-2.664-1.22-2.85-4.357-.887-9.46-.359-14.392 1.806z'
      ></path>
      <path
        fill='url(#paint13_linear_21_135320)'
        d='M57.7 18.623c-4.931 2.166-8.8 5.578-11.138 9.403-.49.802.308 2.665 1.22 2.85 4.356.888 9.46.36 14.391-1.806 4.932-2.165 8.801-5.577 11.14-9.403.489-.801-.309-2.664-1.22-2.85-4.357-.887-9.46-.359-14.392 1.806z'
      ></path>
      <path
        fill='url(#paint14_linear_21_135320)'
        d='M56.019 8.315c-5.029 4.578-8.298 10.214-9.578 15.724-.268 1.154 1.418 3.054 2.58 2.906 5.546-.704 11.425-3.4 16.454-7.978 5.029-4.578 8.299-10.214 9.578-15.724.268-1.154-1.418-3.054-2.58-2.906-5.546.704-11.425 3.4-16.454 7.978z'
      ></path>
      <path
        fill='url(#paint15_linear_21_135320)'
        d='M72.75 9.504c-1.711 3.373-4.154 6.621-7.275 9.463-5.028 4.579-10.908 7.274-16.454 7.978-1.162.147-2.848-1.752-2.58-2.906 1.28-5.51 4.55-11.146 9.578-15.724 2.961-2.697 6.217-4.739 9.527-6.107l7.203 7.296z'
      ></path>
      <path
        fill='url(#paint16_linear_21_135320)'
        d='M39 45.631c13.433 0 24.322-11.028 24.322-24.631 0-13.604-10.889-24.632-24.321-24.632C25.568-3.632 14.679 7.396 14.679 21c0 13.603 10.89 24.631 24.322 24.631z'
      ></path>
      <path
        fill='url(#paint17_linear_21_135320)'
        d='M39 40.633c10.707 0 19.386-8.79 19.386-19.633S49.706 1.367 39 1.367 19.614 10.157 19.614 21 28.294 40.633 39 40.633z'
      ></path>
      <path
        fill='url(#paint18_linear_21_135320)'
        d='M39 37.855c9.193 0 16.644-7.546 16.644-16.855 0-9.31-7.451-16.855-16.643-16.855S22.358 11.69 22.358 21c0 9.308 7.451 16.855 16.643 16.855z'
      ></path>
      <path
        fill='url(#paint19_linear_21_135320)'
        d='M14.68 21c0 13.604 10.888 24.632 24.32 24.632 13.433 0 24.322-11.028 24.322-24.632l-.003-.286H14.683c-.001.096-.004.19-.004.286z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_21_135320'
          x1='14.04'
          x2='19.171'
          y1='22.93'
          y2='29.916'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_135320'
          x1='19.644'
          x2='19.581'
          y1='27.043'
          y2='31.488'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_135320'
          x1='14.599'
          x2='16.676'
          y1='27.827'
          y2='34.331'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_135320'
          x1='8.864'
          x2='17.274'
          y1='16.924'
          y2='23.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_135320'
          x1='18.614'
          x2='18.491'
          y1='19.449'
          y2='25.955'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_135320'
          x1='13.134'
          x2='16.942'
          y1='20.597'
          y2='29.45'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_135320'
          x1='16.679'
          x2='17.05'
          y1='5.777'
          y2='17.41'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_135320'
          x1='13.62'
          x2='21.944'
          y1='9.177'
          y2='20.506'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_135320'
          x1='63.959'
          x2='58.828'
          y1='22.93'
          y2='29.916'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_135320'
          x1='59.37'
          x2='59.307'
          y1='27.043'
          y2='31.488'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_135320'
          x1='60.479'
          x2='54.002'
          y1='27.625'
          y2='34.477'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_135320'
          x1='69.136'
          x2='60.726'
          y1='16.924'
          y2='23.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_135320'
          x1='60.487'
          x2='60.364'
          y1='19.449'
          y2='25.955'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_135320'
          x1='61.693'
          x2='52.468'
          y1='20.301'
          y2='27.543'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_135320'
          x1='61.33'
          x2='60.959'
          y1='5.772'
          y2='17.405'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_135320'
          x1='61.291'
          x2='49.306'
          y1='8.684'
          y2='14.194'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_135320'
          x1='24.006'
          x2='49.981'
          y1='5.814'
          y2='31.462'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_135320'
          x1='51.591'
          x2='25.718'
          y1='33.751'
          y2='8.204'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint18_linear_21_135320'
          x1='26.902'
          x2='47.069'
          y1='8.747'
          y2='28.66'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E6E6E6'></stop>
          <stop offset='1' stopColor='#999'></stop>
        </linearGradient>
        <linearGradient
          id='paint19_linear_21_135320'
          x1='39.001'
          x2='39.001'
          y1='28.207'
          y2='44.374'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#766E6E' stopOpacity='0'></stop>
          <stop offset='0.281' stopColor='#716B6B' stopOpacity='0.281'></stop>
          <stop offset='0.571' stopColor='#656060' stopOpacity='0.571'></stop>
          <stop offset='0.864' stopColor='#514F4F' stopOpacity='0.864'></stop>
          <stop offset='1' stopColor='#444'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const InformationIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20ZM8.4375 13.125H9.375V10.625H8.4375C7.89062 10.625 7.5 10.2344 7.5 9.6875C7.5 9.17969 7.89062 8.75 8.4375 8.75H10.3125C10.8203 8.75 11.25 9.17969 11.25 9.6875V13.125H11.5625C12.0703 13.125 12.5 13.5547 12.5 14.0625C12.5 14.6094 12.0703 15 11.5625 15H8.4375C7.89062 15 7.5 14.6094 7.5 14.0625C7.5 13.5547 7.89062 13.125 8.4375 13.125ZM10 7.5C9.29688 7.5 8.75 6.95312 8.75 6.25C8.75 5.58594 9.29688 5 10 5C10.6641 5 11.25 5.58594 11.25 6.25C11.25 6.95312 10.6641 7.5 10 7.5Z'
        fill='#DCDAE0'
      />
    </svg>
  )
}

export const CardRankBG = (props: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='78'
      height='42'
      fill='none'
      viewBox='0 0 69 32'
    >
      <path
        fill='url(#paint0_linear_21_133018)'
        d='M16.841 25.163c4.612.603 8.635 2.5 11.484 5.113.597.547.374 2.29-.34 2.665-3.41 1.794-7.777 2.594-12.39 1.99-4.61-.602-8.634-2.5-11.483-5.112-.596-.547-.374-2.29.34-2.665 3.41-1.795 7.778-2.594 12.39-1.99z'
      ></path>
      <path
        fill='url(#paint1_linear_21_133018)'
        d='M16.841 25.163c4.612.603 8.635 2.5 11.484 5.113.597.547.374 2.29-.34 2.665-3.41 1.794-7.777 2.594-12.39 1.99-4.61-.602-8.634-2.5-11.483-5.112-.596-.547-.374-2.29.34-2.665 3.41-1.795 7.778-2.594 12.39-1.99z'
      ></path>
      <path
        fill='url(#paint2_linear_21_133018)'
        d='M17.381 18.623c4.932 2.166 8.801 5.578 11.14 9.404.489.8-.309 2.664-1.22 2.85-4.357.887-9.46.359-14.392-1.807-4.932-2.165-8.801-5.576-11.14-9.403-.489-.801.309-2.664 1.22-2.85 4.357-.887 9.46-.359 14.392 1.806z'
      ></path>
      <path
        fill='url(#paint3_linear_21_133018)'
        d='M17.381 18.623c4.932 2.166 8.801 5.578 11.14 9.404.489.8-.309 2.664-1.22 2.85-4.357.887-9.46.359-14.392-1.807-4.932-2.165-8.801-5.576-11.14-9.403-.489-.801.309-2.664 1.22-2.85 4.357-.887 9.46-.359 14.392 1.806z'
      ></path>
      <path
        fill='url(#paint4_linear_21_133018)'
        d='M26.262 8.124c1.975 5.063 2.33 10.246 1.31 14.626-.212.917-2.077 1.663-2.852 1.142-3.7-2.492-6.94-6.52-8.915-11.584-1.975-5.062-2.33-10.246-1.311-14.626.213-.917 2.078-1.663 2.853-1.141 3.7 2.492 6.94 6.52 8.915 11.583z'
      ></path>
      <path
        fill='url(#paint5_linear_21_133018)'
        d='M26.262 8.124c1.975 5.063 2.33 10.246 1.31 14.626-.212.917-2.077 1.663-2.852 1.142-3.7-2.492-6.94-6.52-8.915-11.584-1.975-5.062-2.33-10.246-1.311-14.626.213-.917 2.078-1.663 2.853-1.141 3.7 2.492 6.94 6.52 8.915 11.583z'
      ></path>
      <path
        fill='url(#paint6_linear_21_133018)'
        d='M19.063 8.315c5.028 4.578 8.298 10.214 9.578 15.724.268 1.154-1.419 3.054-2.58 2.906-5.546-.704-11.426-3.4-16.455-7.978C4.578 14.39 1.308 8.753.028 3.243-.24 2.09 1.447.19 2.608.337c5.546.704 11.426 3.4 16.455 7.978z'
      ></path>
      <path
        fill='url(#paint7_linear_21_133018)'
        d='M2.333 9.504c1.71 3.373 4.153 6.621 7.274 9.463 5.029 4.579 10.909 7.274 16.455 7.978 1.161.148 2.848-1.752 2.58-2.906-1.28-5.51-4.55-11.146-9.578-15.724-2.962-2.696-6.218-4.739-9.527-6.107L2.333 9.504z'
      ></path>
      <path
        fill='url(#paint8_linear_21_133018)'
        d='M61.158 25.163c-4.611.603-8.634 2.5-11.483 5.113-.597.547-.374 2.29.34 2.665 3.41 1.794 7.777 2.594 12.389 1.99 4.612-.602 8.635-2.5 11.483-5.112.597-.547.375-2.29-.34-2.665-3.41-1.795-7.777-2.594-12.389-1.99z'
      ></path>
      <path
        fill='url(#paint9_linear_21_133018)'
        d='M61.158 25.163c-4.611.603-8.634 2.5-11.483 5.113-.597.547-.374 2.29.34 2.665 3.41 1.794 7.777 2.594 12.389 1.99 4.612-.602 8.635-2.5 11.483-5.112.597-.547.375-2.29-.34-2.665-3.41-1.795-7.777-2.594-12.389-1.99z'
      ></path>
      <path
        fill='url(#paint10_linear_21_133018)'
        d='M60.62 18.623c-4.933 2.166-8.802 5.578-11.14 9.404-.49.8.308 2.664 1.22 2.85 4.356.887 9.46.359 14.391-1.807 4.932-2.165 8.802-5.576 11.14-9.403.49-.801-.308-2.664-1.22-2.85-4.356-.887-9.46-.359-14.392 1.806z'
      ></path>
      <path
        fill='url(#paint11_linear_21_133018)'
        d='M60.62 18.623c-4.933 2.166-8.802 5.578-11.14 9.404-.49.8.308 2.664 1.22 2.85 4.356.887 9.46.359 14.391-1.807 4.932-2.165 8.802-5.576 11.14-9.403.49-.801-.308-2.664-1.22-2.85-4.356-.887-9.46-.359-14.392 1.806z'
      ></path>
      <path
        fill='url(#paint12_linear_21_133018)'
        d='M51.74 8.124c-1.976 5.063-2.33 10.246-1.311 14.626.213.917 2.078 1.663 2.852 1.142 3.7-2.492 6.94-6.52 8.915-11.584 1.976-5.062 2.33-10.246 1.311-14.626-.213-.917-2.078-1.663-2.852-1.141-3.7 2.492-6.94 6.52-8.915 11.583z'
      ></path>
      <path
        fill='url(#paint13_linear_21_133018)'
        d='M51.74 8.124c-1.976 5.063-2.33 10.246-1.311 14.626.213.917 2.078 1.663 2.852 1.142 3.7-2.492 6.94-6.52 8.915-11.584 1.976-5.062 2.33-10.246 1.311-14.626-.213-.917-2.078-1.663-2.852-1.141-3.7 2.492-6.94 6.52-8.915 11.583z'
      ></path>
      <path
        fill='url(#paint14_linear_21_133018)'
        d='M58.937 8.315c-5.028 4.578-8.298 10.214-9.578 15.724-.267 1.154 1.419 3.054 2.58 2.906 5.546-.704 11.426-3.4 16.455-7.978 5.028-4.578 8.298-10.214 9.578-15.724C78.24 2.09 76.553.19 75.392.337c-5.546.704-11.426 3.4-16.455 7.978z'
      ></path>
      <path
        fill='url(#paint15_linear_21_133018)'
        d='M75.668 9.504c-1.71 3.373-4.153 6.621-7.274 9.463-5.029 4.579-10.909 7.274-16.455 7.978-1.161.148-2.847-1.752-2.58-2.906 1.28-5.51 4.55-11.146 9.578-15.724 2.961-2.696 6.218-4.739 9.527-6.107l7.204 7.296z'
      ></path>
      <path
        fill='url(#paint16_linear_21_133018)'
        d='M39 45.631c13.433 0 24.322-11.028 24.322-24.631 0-13.604-10.889-24.632-24.321-24.632C25.568-3.632 14.679 7.396 14.679 21c0 13.603 10.89 24.631 24.322 24.631z'
      ></path>
      <path
        fill='url(#paint17_linear_21_133018)'
        d='M39 40.633c10.707 0 19.386-8.79 19.386-19.633S49.706 1.367 39 1.367 19.614 10.157 19.614 21 28.294 40.633 39 40.633z'
      ></path>
      <path
        fill='url(#paint18_linear_21_133018)'
        d='M39 37.855c9.193 0 16.644-7.547 16.644-16.855 0-9.31-7.451-16.855-16.643-16.855S22.358 11.69 22.358 21c0 9.308 7.451 16.855 16.643 16.855z'
      ></path>
      <path
        fill='url(#paint19_linear_21_133018)'
        d='M14.68 21c0 13.604 10.888 24.632 24.32 24.632 13.433 0 24.322-11.028 24.322-24.632l-.003-.286H14.683c-.001.096-.004.19-.004.286z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_21_133018'
          x1='11.122'
          x2='16.253'
          y1='22.93'
          y2='29.916'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_133018'
          x1='10.662'
          x2='18.65'
          y1='27.45'
          y2='31.09'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_133018'
          x1='5.945'
          x2='14.355'
          y1='16.924'
          y2='23.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_133018'
          x1='9.44'
          x2='15.761'
          y1='23.94'
          y2='23.84'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_133018'
          x1='22.691'
          x2='20.733'
          y1='2.493'
          y2='11.389'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_133018'
          x1='14.43'
          x2='19.388'
          y1='3.39'
          y2='8.386'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_133018'
          x1='11.49'
          x2='15.794'
          y1='4.632'
          y2='17.921'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_133018'
          x1='12.338'
          x2='17.801'
          y1='11.886'
          y2='16.567'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_133018'
          x1='66.878'
          x2='61.747'
          y1='22.93'
          y2='29.916'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_133018'
          x1='67.338'
          x2='59.35'
          y1='27.45'
          y2='31.09'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_133018'
          x1='72.055'
          x2='63.645'
          y1='16.924'
          y2='23.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_133018'
          x1='68.561'
          x2='62.239'
          y1='23.94'
          y2='23.84'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_133018'
          x1='55.31'
          x2='57.269'
          y1='2.493'
          y2='11.389'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_133018'
          x1='63.571'
          x2='58.614'
          y1='3.39'
          y2='8.386'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_133018'
          x1='66.51'
          x2='62.206'
          y1='4.632'
          y2='17.921'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_133018'
          x1='65.663'
          x2='60.2'
          y1='11.886'
          y2='16.567'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_133018'
          x1='24.006'
          x2='49.981'
          y1='5.814'
          y2='31.462'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_133018'
          x1='51.591'
          x2='25.718'
          y1='33.751'
          y2='8.204'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint18_linear_21_133018'
          x1='26.902'
          x2='47.069'
          y1='8.747'
          y2='28.66'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint19_linear_21_133018'
          x1='39.001'
          x2='39.001'
          y1='28.207'
          y2='44.374'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC8758' stopOpacity='0'></stop>
          <stop offset='0.996' stopColor='#DC8758' stopOpacity='0.996'></stop>
          <stop offset='1' stopColor='#DC8758'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const GreenCup = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='46'
      fill='none'
      viewBox='0 0 56 46'
    >
      <g clipPath='url(#clip0_21_135004)'>
        <g opacity='0.5'>
          <path
            fill='url(#paint0_linear_21_135004)'
            d='M38.566 8.202v28.719c2.51 0 9.075-6.214 9.075-6.86V15.062c0-.646-6.564-6.86-9.075-6.86z'
          ></path>
          <path
            fill='url(#paint1_linear_21_135004)'
            d='M38.566 8.202v28.719c2.51 0 9.075-6.214 9.075-6.86V15.062c0-.646-6.564-6.86-9.075-6.86z'
          ></path>
          <path
            fill='url(#paint2_linear_21_135004)'
            d='M8.06 15.063v14.999c0 .647 6.564 6.86 8.881 6.86V8.202c-2.317 0-8.881 6.214-8.881 6.86v.001z'
          ></path>
          <path
            fill='url(#paint3_linear_21_135004)'
            d='M8.06 15.063v14.999c0 .647 6.564 6.86 8.881 6.86V8.202c-2.317 0-8.881 6.214-8.881 6.86v.001z'
          ></path>
          <g style={{ mixBlendMode: 'multiply' }} fill='#318912'>
            <path d='M47.642 28.522v1.54c0 .647-6.565 6.86-9.075 6.86v-1.54c2.51 0 9.075-6.215 9.075-6.86zM16.941 35.382v1.54c-2.317 0-8.881-6.214-8.881-6.86v-1.54c0 .646 6.564 6.86 8.881 6.86z'></path>
          </g>
          <g opacity='0.49'>
            <g style={{ mixBlendMode: 'overlay' }}>
              a
              <path
                fill='#fff'
                d='M47.642 16.604v-1.54c0-.648-6.565-6.86-9.075-6.86v1.54c2.51 0 9.075 6.214 9.075 6.86z'
              ></path>
            </g>
            <path
              fill='#fff'
              d='M16.941 9.743v-1.54c-2.317 0-8.881 6.213-8.881 6.86v1.54c0-.647 6.564-6.861 8.881-6.861z'
            ></path>
          </g>
        </g>
        <path
          fill='url(#paint4_linear_21_135004)'
          d='M37.405 12.156c1.284-1.33 9.095-4.209 8.54-2.577-.486 1.424-2.546 8.087-3.038 8.643-.492.558-9.828 10.19-10.411 10.333-.583.144-.468-3.156-1.327-3.202-.859-.046 5.837-12.784 6.236-13.197z'
        ></path>
        <path
          fill='url(#paint5_linear_21_135004)'
          d='M37.405 12.156c1.284-1.33 9.095-4.209 8.54-2.577-.486 1.424-2.546 8.087-3.038 8.643-.492.558-9.828 10.19-10.411 10.333-.583.144-.468-3.156-1.327-3.202-.859-.046 5.837-12.784 6.236-13.197z'
        ></path>
        <path
          fill='#fff'
          d='M43.5 16.884c.81-2.165 2.075-6.23 2.44-7.305.556-1.632-7.255 1.246-8.54 2.577-.172.179-1.517 2.652-2.921 5.41 2.319 2.088 6.205 1.823 9.022-.682z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M31.168 25.35h.104c1.116-3.06 5.74-11.807 6.07-12.148 1.125-1.167 7.265-3.52 8.389-2.986.086-.265.157-.482.21-.637.557-1.63-7.253 1.246-8.538 2.577-.399.413-7.094 13.149-6.235 13.195v-.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint6_linear_21_135004)'
          d='M43.559 18.355c1.559.218 7.495 4.214 6.104 4.662-1.215.392-6.788 2.37-7.418 2.31-.629-.06-13.73-.02-14.124-.354-.395-.335-1.615-3.491 2.853-4.331.728-.137 12.1-2.354 12.585-2.287z'
        ></path>
        <path
          fill='url(#paint7_linear_21_135004)'
          d='M43.559 18.355c1.559.218 7.495 4.214 6.104 4.662-1.215.392-6.788 2.37-7.418 2.31-.629-.06-13.73-.02-14.124-.354-.395-.335-1.615-3.491 2.853-4.331.728-.137 12.1-2.354 12.585-2.287z'
        ></path>
        <path
          fill='#fff'
          d='M43.46 25.058c1.882-.545 5.286-1.745 6.203-2.041 1.392-.448-4.545-4.444-6.103-4.662-.21-.029-2.574.3-5.164.732-.234 2.696 1.927 5.284 5.064 5.97z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M31.42 20.762c.014.022.032.047.052.073 2.648-.79 10.946-2.041 11.345-1.986 1.367.19 6.096 3.285 6.306 4.347.224-.076.409-.137.54-.18 1.392-.448-4.545-4.444-6.103-4.661-.484-.068-12.547 1.78-12.14 2.406v.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint8_linear_21_135004)'
          d='M43.025 24.504c1.395.471 6.163 5.181 4.81 5.349-1.183.147-6.64.987-7.207.823-.568-.164-11.276-3.09-11.582-3.467-.306-.376-.421-3.29 2.02-2.938.686.098 11.527.086 11.96.232z'
        ></path>
        <path
          fill='url(#paint9_linear_21_135004)'
          d='M43.025 24.504c1.395.471 6.163 5.181 4.81 5.349-1.183.147-6.64.987-7.207.823-.568-.164-11.276-3.09-11.582-3.467-.306-.376-.421-3.29 2.02-2.938.686.098 11.527.086 11.96.232z'
        ></path>
        <path
          fill='#fff'
          d='M41.788 30.641c1.822-.17 5.153-.677 6.045-.788 1.354-.168-3.414-4.877-4.809-5.35-.186-.063-2.415-.176-4.866-.231-.677 2.435.867 5.19 3.629 6.37z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M31.465 24.589a.92.92 0 00.036.076c2.566-.261 10.401.042 10.759.163 1.223.414 5.037 4.083 5.048 5.096.219-.03.398-.054.527-.07 1.354-.168-3.414-4.877-4.81-5.35-.432-.146-11.826-.56-11.559.086v-.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint10_linear_21_135004)'
          d='M17.947 12.156c-1.285-1.33-9.096-4.209-8.54-2.577.485 1.424 2.545 8.087 3.037 8.643.492.558 9.828 10.19 10.411 10.333.583.144.468-3.156 1.327-3.202.859-.046-5.837-12.784-6.236-13.197z'
        ></path>
        <path
          fill='url(#paint11_linear_21_135004)'
          d='M17.947 12.156c-1.285-1.33-9.096-4.209-8.54-2.577.485 1.424 2.545 8.087 3.037 8.643.492.558 9.828 10.19 10.411 10.333.583.144.468-3.156 1.327-3.202.859-.046-5.837-12.784-6.236-13.197z'
        ></path>
        <path
          fill='#fff'
          d='M11.85 16.884c-.809-2.165-2.073-6.23-2.44-7.305-.555-1.632 7.256 1.246 8.54 2.577.173.179 1.518 2.652 2.922 5.41-2.319 2.088-6.205 1.823-9.021-.682z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M24.183 25.35h-.103c-1.116-3.06-5.74-11.807-6.07-12.148-1.126-1.167-7.266-3.52-8.39-2.986a42.455 42.455 0 00-.21-.637c-.557-1.63 7.253 1.246 8.538 2.577.399.413 7.094 13.149 6.235 13.195v-.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint12_linear_21_135004)'
          d='M11.792 18.355c-1.559.218-7.495 4.214-6.104 4.662 1.215.392 6.788 2.37 7.418 2.31.629-.06 13.73-.02 14.124-.354.395-.335 1.616-3.491-2.853-4.331-.728-.137-12.1-2.354-12.585-2.287z'
        ></path>
        <path
          fill='url(#paint13_linear_21_135004)'
          d='M11.792 18.355c-1.559.218-7.495 4.214-6.104 4.662 1.215.392 6.788 2.37 7.418 2.31.629-.06 13.73-.02 14.124-.354.395-.335 1.616-3.491-2.853-4.331-.728-.137-12.1-2.354-12.585-2.287z'
        ></path>
        <path
          fill='#fff'
          d='M11.891 25.058c-1.882-.545-5.286-1.745-6.203-2.041-1.392-.448 4.545-4.444 6.103-4.662.21-.029 2.574.3 5.164.732.234 2.696-1.927 5.284-5.064 5.97z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M23.931 20.762a1.12 1.12 0 01-.052.073c-2.647-.79-10.946-2.041-11.345-1.986-1.367.19-6.096 3.285-6.306 4.347a58.743 58.743 0 00-.54-.18c-1.392-.448 4.545-4.444 6.103-4.661.484-.068 12.547 1.78 12.14 2.406v.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint14_linear_21_135004)'
          d='M12.326 24.504c-1.395.471-6.163 5.181-4.81 5.349 1.183.147 6.64.987 7.207.823.568-.164 11.276-3.09 11.582-3.467.306-.376.421-3.29-2.02-2.938-.686.098-11.527.086-11.96.232z'
        ></path>
        <path
          fill='url(#paint15_linear_21_135004)'
          d='M12.326 24.504c-1.395.471-6.163 5.181-4.81 5.349 1.183.147 6.64.987 7.207.823.568-.164 11.276-3.09 11.582-3.467.306-.376.421-3.29-2.02-2.938-.686.098-11.527.086-11.96.232z'
        ></path>
        <path
          fill='#fff'
          d='M13.563 30.641c-1.822-.17-5.153-.677-6.045-.788-1.354-.168 3.414-4.877 4.809-5.35.187-.063 2.415-.176 4.866-.231.677 2.435-.866 5.19-3.629 6.37z'
          opacity='0.2'
        ></path>
        <path
          fill='#fff'
          d='M23.886 24.589a.99.99 0 01-.036.076c-2.566-.261-10.401.042-10.759.163-1.223.414-5.037 4.083-5.048 5.096-.219-.03-.398-.054-.527-.07-1.354-.168 3.415-4.877 4.81-5.35.432-.146 11.826-.56 11.559.086v-.001z'
          opacity='0.49'
        ></path>
        <path
          fill='url(#paint16_linear_21_135004)'
          d='M27.667 1.935c-2.412 0-9.135 6.254-9.135 6.904v29.058c0 .65 6.723 4.927 9.135 4.927 2.413 0 9.135-4.276 9.135-4.927V8.84c0-.65-6.722-6.904-9.135-6.904z'
        ></path>
        <path
          fill='url(#paint17_linear_21_135004)'
          d='M27.667 1.935c-2.412 0-9.135 6.254-9.135 6.904v29.058c0 .65 6.723 4.927 9.135 4.927 2.413 0 9.135-4.276 9.135-4.927V8.84c0-.65-6.722-6.904-9.135-6.904z'
        ></path>
        <path
          fill='url(#paint18_radial_21_135004)'
          fillRule='evenodd'
          d='M35.032 27.97s3.707 1.16 9.432-.888c7.689-2.748 8.34-10.264 8.34-10.264s-1.99 7.74-8.201 9.699c-6.21 1.96-9.225 1.092-9.225 1.092l-.348.36h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint19_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M35.032 27.97s3.707 1.16 9.432-.888c7.689-2.748 8.34-10.264 8.34-10.264s-1.99 7.74-8.201 9.699c-6.21 1.96-9.225 1.092-9.225 1.092l-.348.36h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint20_radial_21_135004)'
          fillRule='evenodd'
          d='M38.725 15.483l1.072-.178-.248-3.289s8.38-4.879 10.98-.567c2.189 3.63.727 11.915-7.045 14.705-5.793 2.079-8.461.34-8.461.34l-1.932 1.725 1.033.697 1.256-1.306s4.488 1.25 9.557-.883c7.002-2.945 10.337-12.072 6.248-16.806-3.773-4.366-12.297.962-12.297.962l-.16 4.602-.003-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint21_radial_21_135004)'
          fillRule='evenodd'
          d='M39.576 12.377s6.94-3.612 10.085-1.906c0 0-2.678-2.63-10.114 1.545l.027.361h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint22_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M39.576 12.377s6.94-3.612 10.085-1.906c0 0-2.678-2.63-10.114 1.545l.027.361h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint23_radial_21_135004)'
          fillRule='evenodd'
          d='M39.796 15.305l-.248.47-.893.301.07-.593 1.071-.178z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint24_linear_21_135004)'
          fillRule='evenodd'
          d='M39.796 15.305l-.248.47-.893.301.07-.593 1.071-.178z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint25_radial_21_135004)'
          fillRule='evenodd'
          d='M32.791 28.408l1.332.506-1.032-.697-.3.19z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint26_radial_21_135004)'
          fillRule='evenodd'
          d='M20.815 27.97s-3.706 1.16-9.431-.888c-7.69-2.748-8.341-10.264-8.341-10.264s1.99 7.74 8.202 9.699c6.21 1.96 9.224 1.092 9.224 1.092l.348.36h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint27_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M20.815 27.97s-3.706 1.16-9.431-.888c-7.69-2.748-8.341-10.264-8.341-10.264s1.99 7.74 8.202 9.699c6.21 1.96 9.224 1.092 9.224 1.092l.348.36h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint28_radial_21_135004)'
          fillRule='evenodd'
          d='M17.275 15.483l-1.071-.178.247-3.289s-8.38-4.879-10.98-.567c-2.188 3.63-.727 11.915 7.046 14.705 5.792 2.079 8.46.34 8.46.34l1.932 1.725-1.032.697-1.257-1.306s-4.488 1.25-9.557-.883C4.062 23.78.727 14.653 4.816 9.919c3.772-4.366 12.297.962 12.297.962l.16 4.602h.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint29_radial_21_135004)'
          fillRule='evenodd'
          d='M16.274 12.377S9.333 8.765 6.189 10.471c0 0 2.678-2.63 10.114 1.545l-.027.361h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint30_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M16.274 12.377S9.333 8.765 6.189 10.471c0 0 2.678-2.63 10.114 1.545l-.027.361h-.002z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint31_radial_21_135004)'
          fillRule='evenodd'
          d='M16.054 15.305l.247.47.893.301-.07-.593-1.07-.178z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint32_linear_21_135004)'
          fillRule='evenodd'
          d='M16.054 15.305l.247.47.893.301-.07-.593-1.07-.178z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint33_radial_21_135004)'
          fillRule='evenodd'
          d='M23.059 28.408l-1.334.506 1.034-.697.3.19z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint34_radial_21_135004)'
          fillRule='evenodd'
          d='M29.168 38.098c-1.11-2.183-.75-5.308-.75-5.308l1.17-.848-.922-1.031-.892.198-.891-.198-.922 1.03 1.17.849s.36 3.125-.75 5.308c-1.11 2.184-5.72 2.985-5.72 2.985s4.15.385 7.094.385c3.207 0 7.128-.385 7.128-.385s-4.609-.801-5.719-2.985h.004z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint35_radial_21_135004)'
          fillRule='evenodd'
          d='M20.663 41.083l-.375 1.822 14.96.113-.363-1.935s-3.366.31-7.111.31c-3.387 0-7.111-.31-7.111-.31z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint36_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M20.663 41.083l-.375 1.822 14.96.113-.363-1.935s-3.366.31-7.111.31c-3.387 0-7.111-.31-7.111-.31z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint37_radial_21_135004)'
          fillRule='evenodd'
          d='M20.663 41.083l-.375 1.822 14.96.113-.363-1.935s-3.366.31-7.111.31c-3.387 0-7.111-.31-7.111-.31z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint38_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M20.663 41.083l-.375 1.822 14.96.113-.363-1.935s-3.366.31-7.111.31c-3.387 0-7.111-.31-7.111-.31z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint39_radial_21_135004)'
          fillRule='evenodd'
          d='M38.932 7.228s-3.595-2.539-11.036-2.543h-.012c-7.442.004-11.036 2.543-11.036 2.543-1.061 23.259 10.61 24.258 11.036 24.286h.012c.425-.03 12.097-1.03 11.036-24.288v.002z'
          clipRule='evenodd'
        ></path>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.8'
        >
          <path
            fill='url(#paint40_linear_21_135004)'
            d='M25.96 31.942s1.176.447 3.628 0l-1.17.848H27.13l-1.17-.848z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint41_linear_21_135004)'
            d='M25.96 31.942s1.176.447 3.628 0l-1.17.848H27.13l-1.17-.848z'
          ></path>
        </g>
        <path
          fill='url(#paint42_radial_21_135004)'
          fillRule='evenodd'
          d='M26.662 37.445l-.502-.397.76-.483h1.709l.578.44-.321.44h-2.224z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint43_linear_21_135004)'
          fillRule='evenodd'
          d='M26.662 37.445l-.502-.397.76-.483h1.709l.578.44-.321.44h-2.224z'
          clipRule='evenodd'
        ></path>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.8'
        >
          <path
            fill='url(#paint44_linear_21_135004)'
            d='M26.16 37.048s1.353.397 3.049-.043l-.321.44s-.488.135-1.112.135a6.067 6.067 0 01-1.112-.135l-.502-.397h-.002z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint45_linear_21_135004)'
            d='M26.16 37.048s1.353.397 3.049-.043l-.321.44s-.488.135-1.112.135a6.067 6.067 0 01-1.112-.135l-.502-.397h-.002z'
          ></path>
        </g>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.5'
        >
          <path
            fill='url(#paint46_linear_21_135004)'
            d='M27.306 32.79s.04 2.536-.178 3.775h.38V32.79h-.202z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint47_linear_21_135004)'
            d='M27.306 32.79s.04 2.536-.178 3.775h.38V32.79h-.202z'
          ></path>
        </g>
        <g
          style={{ mixBlendMode: 'multiply' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.5'
        >
          <path
            fill='url(#paint48_linear_21_135004)'
            d='M26.887 37.488s-.325 2.635-4.809 3.722l2.815.12s1.638-1.239 2.233-3.803l-.24-.04z'
            style={{ mixBlendMode: 'multiply' }}
          ></path>
          <path
            fill='url(#paint49_linear_21_135004)'
            d='M26.887 37.488s-.325 2.635-4.809 3.722l2.815.12s1.638-1.239 2.233-3.803l-.24-.04z'
          ></path>
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint50_radial_21_135004)'
          d='M36.572 12.995c.092-3.716-1.428-6.77-3.394-6.82-1.966-.05-3.635 2.922-3.727 6.638-.092 3.716 1.428 6.768 3.394 6.819 1.966.05 3.635-2.922 3.727-6.638z'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint51_radial_21_135004)'
          d='M28.102 31.044c2.06-1.209 1.964-5.297-.216-9.133-2.18-3.835-5.62-5.964-7.68-4.755-2.062 1.208-1.965 5.297.215 9.132s5.62 5.964 7.68 4.756z'
          opacity='0.3'
        ></path>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint52_radial_21_135004)'
          d='M25.092 30.163c.775-.609.358-2.474-.931-4.166-1.29-1.692-2.962-2.57-3.737-1.962-.774.608-.357 2.473.932 4.165 1.289 1.692 2.961 2.571 3.736 1.963z'
        ></path>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint53_linear_21_135004)'
          fillRule='evenodd'
          d='M23.473 28.68s-6.524-4.423-6.106-15.727c0 0-.093 8.927 6.106 15.727z'
          clipRule='evenodd'
          opacity='0.86'
        ></path>
        <g
          style={{ mixBlendMode: 'screen' }}
          fillRule='evenodd'
          clipRule='evenodd'
          opacity='0.86'
        >
          <path
            fill='url(#paint54_linear_21_135004)'
            d='M28.764 41.385s-.572-2.054-.688-3.805l.527-.075s.505 2.993 4.855 3.686l-4.692.192-.002.002z'
            style={{ mixBlendMode: 'screen' }}
          ></path>
          <path
            fill='url(#paint55_linear_21_135004)'
            d='M28.764 41.385s-.572-2.054-.688-3.805l.527-.075s.505 2.993 4.855 3.686l-4.692.192-.002.002z'
          ></path>
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          fill='url(#paint56_linear_21_135004)'
          fillRule='evenodd'
          d='M18.788 22.99s3.36 7.575 8.986 7.575c5.627 0 8.877-6.742 8.877-6.742s-2.498 6.793-8.767 7.689c0 0-5.922-.25-9.098-8.523l.002.002z'
          clipRule='evenodd'
          opacity='0.86'
        ></path>
        <path
          fill='url(#paint57_radial_21_135004)'
          fillRule='evenodd'
          d='M12.823 28.298s3.799 5.02 8.3-1.045l-.116-.153-8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint58_linear_21_135004)'
          fillRule='evenodd'
          d='M12.823 28.298s3.799 5.02 8.3-1.045l-.116-.153-8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint59_radial_21_135004)'
          fillRule='evenodd'
          d='M12.823 28.298s3.799 5.02 8.3-1.045l-.116-.153-8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint60_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M12.823 28.298s3.799 5.02 8.3-1.045l-.116-.153-8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint61_linear_21_135004)'
          fillRule='evenodd'
          d='M20.574 26.613l-7.751 1.683s4.17 4.488 8.184-1.198l-.433-.485z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint62_linear_21_135004)'
          fillOpacity='0.3'
          fillRule='evenodd'
          d='M20.574 26.613l-7.751 1.683s4.17 4.488 8.184-1.198l-.433-.485z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint63_radial_21_135004)'
          fillRule='evenodd'
          d='M15.877 30.695s4.146 2.957 5.847-2.794l-.197-.228-5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint64_linear_21_135004)'
          fillRule='evenodd'
          d='M15.877 30.695s4.146 2.957 5.847-2.794l-.197-.228-5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint65_radial_21_135004)'
          fillRule='evenodd'
          d='M15.877 30.695s4.146 2.957 5.847-2.794l-.197-.228-5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint66_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M15.877 30.695s4.146 2.957 5.847-2.794l-.197-.228-5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint67_linear_21_135004)'
          fillRule='evenodd'
          d='M21.009 27.098l-5.13 3.595s3.97 2.391 5.65-3.022l-.52-.573z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint68_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M21.009 27.098l-5.13 3.595s3.97 2.391 5.65-3.022l-.52-.573z'
          clipRule='evenodd'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint69_linear_21_135004)'
          fillRule='evenodd'
          d='M19.655 30.65l.344.188.59-.589-.311-.163s-.304.365-.623.566v-.002z'
          clipRule='evenodd'
          opacity='0.6'
        ></path>
        <path
          fill='url(#paint70_radial_21_135004)'
          fillRule='evenodd'
          d='M43.027 28.298s-3.8 5.02-8.3-1.045l.116-.153 8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint71_linear_21_135004)'
          fillRule='evenodd'
          d='M43.027 28.298s-3.8 5.02-8.3-1.045l.116-.153 8.184 1.198z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint72_linear_21_135004)'
          fillRule='evenodd'
          d='M35.276 26.613l7.751 1.683s-4.17 4.488-8.184-1.198l.433-.485z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint73_linear_21_135004)'
          fillOpacity='0.3'
          fillRule='evenodd'
          d='M35.276 26.613l7.751 1.683s-4.17 4.488-8.184-1.198l.433-.485z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint74_radial_21_135004)'
          fillRule='evenodd'
          d='M39.972 30.695s-4.145 2.957-5.846-2.794l.197-.228 5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint75_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M39.972 30.695s-4.145 2.957-5.846-2.794l.197-.228 5.65 3.022z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint76_linear_21_135004)'
          fillRule='evenodd'
          d='M34.84 27.098l5.13 3.595S36 33.084 34.32 27.67l.52-.573z'
          clipRule='evenodd'
        ></path>
        <path
          fill='url(#paint77_linear_21_135004)'
          fillOpacity='0.5'
          fillRule='evenodd'
          d='M34.84 27.098l5.13 3.595S36 33.084 34.32 27.67l.52-.573z'
          clipRule='evenodd'
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill='url(#paint78_linear_21_135004)'
          fillRule='evenodd'
          d='M36.194 30.65l-.344.188-.59-.589.312-.163s.303.365.622.566v-.002z'
          clipRule='evenodd'
          opacity='0.6'
        ></path>
        <path
          fill='#FFFF60'
          fillRule='evenodd'
          d='M28.076 41.39s3.667-.307 5.38-.2c0 0-3.09.23-5.38.2zM27.97 37.2s.26-.613.732-.087c0 0-.495.075-.733.086zM28.21 32.116s.22-.775.848-.09c0 0-.663.078-.847.09z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_21_135004'
          x1='45.885'
          x2='37.553'
          y1='48.39'
          y2='8.499'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_21_135004'
          x1='40.28'
          x2='58.704'
          y1='6.694'
          y2='26.415'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_21_135004'
          x1='15.222'
          x2='6.724'
          y1='48.391'
          y2='8.572'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_21_135004'
          x1='9.736'
          x2='28.184'
          y1='6.694'
          y2='26.02'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_21_135004'
          x1='49.399'
          x2='40.136'
          y1='22.219'
          y2='9.601'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear_21_135004'
          x1='29.555'
          x2='38.858'
          y1='16.794'
          y2='26.884'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_21_135004'
          x1='45.574'
          x2='45.365'
          y1='28.113'
          y2='18.008'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear_21_135004'
          x1='31.859'
          x2='32.684'
          y1='17.988'
          y2='26.881'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint8_linear_21_135004'
          x1='44.36'
          x2='44.152'
          y1='33.276'
          y2='23.922'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint9_linear_21_135004'
          x1='32.508'
          x2='33.325'
          y1='23.902'
          y2='32.124'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint10_linear_21_135004'
          x1='5.952'
          x2='15.215'
          y1='22.219'
          y2='9.601'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint11_linear_21_135004'
          x1='25.797'
          x2='16.493'
          y1='16.794'
          y2='26.884'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint12_linear_21_135004'
          x1='9.777'
          x2='9.986'
          y1='28.113'
          y2='18.008'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint13_linear_21_135004'
          x1='23.492'
          x2='22.667'
          y1='17.988'
          y2='26.881'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint14_linear_21_135004'
          x1='10.991'
          x2='11.199'
          y1='33.276'
          y2='23.922'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint15_linear_21_135004'
          x1='22.843'
          x2='22.026'
          y1='23.902'
          y2='32.124'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <linearGradient
          id='paint16_linear_21_135004'
          x1='33.266'
          x2='24.698'
          y1='59.153'
          y2='1.145'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BB132C'></stop>
          <stop offset='1' stopColor='#FF87AE'></stop>
        </linearGradient>
        <linearGradient
          id='paint17_linear_21_135004'
          x1='21.981'
          x2='46.167'
          y1='-0.212'
          y2='36.395'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BBB13'></stop>
          <stop offset='1' stopColor='#87FF93'></stop>
        </linearGradient>
        <radialGradient
          id='paint18_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-7.65109 0 0 -13.6107 40.497 23.246)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint19_linear_21_135004'
          x1='38.438'
          x2='43.955'
          y1='19.019'
          y2='27.562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint20_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(10.7654 0 0 20.9887 46.85 19.809)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint21_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-4.35362 0 0 -3.22998 42.658 11.179)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint22_linear_21_135004'
          x1='41.486'
          x2='42.208'
          y1='10.176'
          y2='12.854'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint23_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(.4795 0 0 .93484 39.446 15.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint24_linear_21_135004'
          x1='38.874'
          x2='39.252'
          y1='15.453'
          y2='16.011'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint25_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(.50463 0 0 .98384 33.713 28.634)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <radialGradient
          id='paint26_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-7.65109 0 0 -13.6107 8.51 23.246)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint27_linear_21_135004'
          x1='6.451'
          x2='11.968'
          y1='19.019'
          y2='27.562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint28_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.09667 0 0 -.18848 9.15 -13.958)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint29_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-4.35362 0 0 -3.22998 9.3 11.179)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint30_linear_21_135004'
          x1='8.128'
          x2='8.85'
          y1='10.176'
          y2='12.854'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint31_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.4795 0 0 -.93484 16.406 15.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint32_linear_21_135004'
          x1='16.272'
          x2='16.65'
          y1='15.453'
          y2='16.011'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint33_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-.50463 0 0 -.98384 22.136 28.634)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <radialGradient
          id='paint34_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(7.40316 0 0 14.4335 29.006 40.76)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <radialGradient
          id='paint35_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(9.267 0 0 10.4452 28.665 41.82)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.51' stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#D45E2C'></stop>
        </radialGradient>
        <linearGradient
          id='paint36_linear_21_135004'
          x1='23.156'
          x2='23.416'
          y1='41.454'
          y2='43.462'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint37_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-6.44013 0 0 -2.2953 24.89 42.167)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint38_linear_21_135004'
          x1='23.156'
          x2='23.416'
          y1='41.454'
          y2='43.462'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint39_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(15.5952 0 0 30.405 32.28 8.241)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#F17A1F'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.87' stopColor='#F57C27'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <linearGradient
          id='paint40_linear_21_135004'
          x1='25.96'
          x2='29.588'
          y1='32.366'
          y2='32.366'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint41_linear_21_135004'
          x1='26.656'
          x2='26.854'
          y1='32.104'
          y2='32.952'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint42_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(2.52701 0 0 2.3864 28.083 37.301)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.7' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </radialGradient>
        <linearGradient
          id='paint43_linear_21_135004'
          x1='26.744'
          x2='26.991'
          y1='36.734'
          y2='37.59'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint44_linear_21_135004'
          x1='26.16'
          x2='29.207'
          y1='37.291'
          y2='37.291'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint45_linear_21_135004'
          x1='26.744'
          x2='26.855'
          y1='37.115'
          y2='37.701'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint46_linear_21_135004'
          x1='27.316'
          x2='27.316'
          y1='36.565'
          y2='32.79'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint47_linear_21_135004'
          x1='27.201'
          x2='27.597'
          y1='33.514'
          y2='33.553'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint48_linear_21_135004'
          x1='24.603'
          x2='24.603'
          y1='41.33'
          y2='37.488'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC9C7'></stop>
          <stop offset='1' stopColor='#C03BC1'></stop>
        </linearGradient>
        <linearGradient
          id='paint49_linear_21_135004'
          x1='23.046'
          x2='24.999'
          y1='38.225'
          y2='40.79'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint50_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(3.56028 .09088 -.17164 6.72423 33.011 12.844)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF0'></stop>
          <stop offset='0.13' stopColor='#C3C300'></stop>
          <stop offset='0.25' stopColor='#909000'></stop>
          <stop offset='0.38' stopColor='#646400'></stop>
          <stop offset='0.5' stopColor='#404000'></stop>
          <stop offset='0.63' stopColor='#232300'></stop>
          <stop offset='0.75' stopColor='#0F0F00'></stop>
          <stop offset='0.87' stopColor='#030300'></stop>
          <stop offset='1'></stop>
        </radialGradient>
        <radialGradient
          id='paint51_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-30.382 56.26 -31.827) scale(4.31094 7.97199)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C03BC1'></stop>
          <stop offset='0.03' stopColor='#C345C4'></stop>
          <stop offset='0.21' stopColor='#D57DD6'></stop>
          <stop offset='0.39' stopColor='#E4ABE4'></stop>
          <stop offset='0.55' stopColor='#EFD0F0'></stop>
          <stop offset='0.71' stopColor='#F8E9F8'></stop>
          <stop offset='0.85' stopColor='#FDF9FD'></stop>
          <stop offset='0.96' stopColor='#fff'></stop>
        </radialGradient>
        <radialGradient
          id='paint52_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(1.39746 -1.09755 2.38478 3.03644 22.733 27.589)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF8100'></stop>
          <stop offset='0.13' stopColor='#C36300'></stop>
          <stop offset='0.25' stopColor='#904900'></stop>
          <stop offset='0.38' stopColor='#643200'></stop>
          <stop offset='0.5' stopColor='#402000'></stop>
          <stop offset='0.63' stopColor='#231200'></stop>
          <stop offset='0.75' stopColor='#0F0800'></stop>
          <stop offset='0.87' stopColor='#030200'></stop>
          <stop offset='1'></stop>
        </radialGradient>
        <linearGradient
          id='paint53_linear_21_135004'
          x1='23.908'
          x2='16.934'
          y1='21.02'
          y2='20.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop></stop>
          <stop offset='0.09' stopColor='#060400'></stop>
          <stop offset='0.22' stopColor='#191000'></stop>
          <stop offset='0.37' stopColor='#392400'></stop>
          <stop offset='0.54' stopColor='#654000'></stop>
          <stop offset='0.72' stopColor='#9D6500'></stop>
          <stop offset='0.92' stopColor='#E09000'></stop>
          <stop offset='1' stopColor='#FFA400'></stop>
        </linearGradient>
        <linearGradient
          id='paint54_linear_21_135004'
          x1='30.767'
          x2='30.767'
          y1='41.385'
          y2='37.505'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA400'></stop>
          <stop offset='0.08' stopColor='#E09000'></stop>
          <stop offset='0.28' stopColor='#9D6500'></stop>
          <stop offset='0.46' stopColor='#654000'></stop>
          <stop offset='0.63' stopColor='#392400'></stop>
          <stop offset='0.78' stopColor='#191000'></stop>
          <stop offset='0.91' stopColor='#060400'></stop>
          <stop offset='0.99'></stop>
        </linearGradient>
        <linearGradient
          id='paint55_linear_21_135004'
          x1='29.108'
          x2='31.048'
          y1='38.249'
          y2='40.942'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint56_linear_21_135004'
          x1='18.788'
          x2='36.651'
          y1='27.251'
          y2='27.251'
          gradientUnits='userSpaceOnUse'
        >
          <stop></stop>
          <stop offset='0.09' stopColor='#060400'></stop>
          <stop offset='0.22' stopColor='#191000'></stop>
          <stop offset='0.37' stopColor='#392400'></stop>
          <stop offset='0.54' stopColor='#654000'></stop>
          <stop offset='0.72' stopColor='#9D6500'></stop>
          <stop offset='0.92' stopColor='#E09000'></stop>
          <stop offset='1' stopColor='#FFA400'></stop>
        </linearGradient>
        <radialGradient
          id='paint57_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(4.70708 -1.67665 1.01805 2.8581 17.508 28.25)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint58_linear_21_135004'
          x1='14.414'
          x2='15.515'
          y1='27.704'
          y2='30.607'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint59_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-3.57295 0 0 -3.73351 15.376 28.863)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint60_linear_21_135004'
          x1='14.414'
          x2='15.515'
          y1='27.704'
          y2='30.607'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint61_linear_21_135004'
          x1='12.823'
          x2='21.009'
          y1='28.296'
          y2='28.296'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint62_linear_21_135004'
          x1='14.392'
          x2='15.64'
          y1='27.258'
          y2='30.294'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint63_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-37.895 52.65 -13.406) scale(3.98437 2.39989)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint64_linear_21_135004'
          x1='16.998'
          x2='18.817'
          y1='28.398'
          y2='31.21'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <radialGradient
          id='paint65_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-2.5168 0 0 -4.48374 17.676 29.79)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint66_linear_21_135004'
          x1='16.998'
          x2='18.817'
          y1='28.398'
          y2='31.21'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint67_linear_21_135004'
          x1='15.802'
          x2='21.802'
          y1='30.451'
          y2='28.515'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint68_linear_21_135004'
          x1='16.963'
          x2='19.032'
          y1='27.888'
          y2='30.725'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint69_linear_21_135004'
          x1='19.655'
          x2='20.589'
          y1='30.461'
          y2='30.461'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EABDD3'></stop>
          <stop offset='0.99' stopColor='#DD97C1'></stop>
        </linearGradient>
        <radialGradient
          id='paint70_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-4.70708 -1.67667 1.01806 -2.8581 38.339 28.254)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D64B24'></stop>
          <stop offset='0.98' stopColor='#C5242C'></stop>
        </radialGradient>
        <linearGradient
          id='paint71_linear_21_135004'
          x1='36.318'
          x2='37.419'
          y1='27.704'
          y2='30.607'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint72_linear_21_135004'
          x1='43.025'
          x2='34.841'
          y1='2.107'
          y2='2.107'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint73_linear_21_135004'
          x1='36.412'
          x2='37.66'
          y1='27.258'
          y2='30.294'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <radialGradient
          id='paint74_radial_21_135004'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-2.5168 0 0 -4.48374 35.924 29.79)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D68424'></stop>
          <stop offset='0.98' stopColor='#562E0A'></stop>
        </radialGradient>
        <linearGradient
          id='paint75_linear_21_135004'
          x1='35.246'
          x2='37.065'
          y1='28.398'
          y2='31.21'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D5C787'></stop>
          <stop offset='1' stopColor='#9D722B'></stop>
        </linearGradient>
        <linearGradient
          id='paint76_linear_21_135004'
          x1='40.048'
          x2='34.048'
          y1='30.443'
          y2='28.507'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#FFD800'></stop>
          <stop offset='0.81' stopColor='#EF6B24'></stop>
          <stop offset='0.98' stopColor='#FF982C'></stop>
        </linearGradient>
        <linearGradient
          id='paint77_linear_21_135004'
          x1='35.404'
          x2='37.473'
          y1='27.888'
          y2='30.725'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FEF0AE'></stop>
          <stop offset='1' stopColor='#FBC56D'></stop>
        </linearGradient>
        <linearGradient
          id='paint78_linear_21_135004'
          x1='36.194'
          x2='35.258'
          y1='30.461'
          y2='30.461'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EABDD3'></stop>
          <stop offset='0.99' stopColor='#DD97C1'></stop>
        </linearGradient>
        <clipPath id='clip0_21_135004'>
          <path fill='#fff' d='M0 0H55V46H0z' transform='translate(.5)'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export const USDTIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='24'
      fill='none'
      viewBox='0 0 22 24'
    >
      <defs>
        <linearGradient
          id='paint0_linear_6625_411790'
          x1='0.608'
          x2='24.155'
          y1='0.371'
          y2='21.208'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#53AE94'></stop>
          <stop offset='1' stopColor='#2E9175'></stop>
        </linearGradient>
      </defs>
      <path
        fill='url(#paint0_linear_6625_411790)'
        fillRule='evenodd'
        d='M12.2.693l7.992 4.614a2.4 2.4 0 011.2 2.078v9.23a2.4 2.4 0 01-1.2 2.078L12.2 23.307a2.4 2.4 0 01-2.4 0l-7.992-4.614a2.4 2.4 0 01-1.2-2.078v-9.23a2.4 2.4 0 011.2-2.078L9.8.693a2.4 2.4 0 012.4 0z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        d='M11.91 10.876V9.798h2.465V8.154H7.66v1.644h2.467v1.078c-2.005.091-3.513.489-3.513.964 0 .476 1.508.873 3.513.966v3.455h1.784v-3.456c2-.092 3.505-.489 3.505-.964s-1.504-.872-3.505-.964m0 1.636v-.001c-.05.003-.31.019-.885.019-.46 0-.785-.013-.899-.02v.002c-1.77-.078-3.093-.387-3.093-.756s1.322-.677 3.093-.756v1.205c.116.008.448.027.906.027.55 0 .827-.022.878-.027v-1.204c1.767.078 3.087.387 3.087.755 0 .367-1.32.676-3.087.755'
      ></path>
    </svg>
  )
}

export const USDTIconQR = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54'
      height='56'
      fill='none'
      viewBox='0 0 22 24'
    >
      <path
        fill='url(#paint0_linear_6625_411790)'
        fillRule='evenodd'
        d='M12.2.693l7.992 4.614a2.4 2.4 0 011.2 2.078v9.23a2.4 2.4 0 01-1.2 2.078L12.2 23.307a2.4 2.4 0 01-2.4 0l-7.992-4.614a2.4 2.4 0 01-1.2-2.078v-9.23a2.4 2.4 0 011.2-2.078L9.8.693a2.4 2.4 0 012.4 0z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        d='M11.91 10.876V9.798h2.465V8.154H7.66v1.644h2.467v1.078c-2.005.091-3.513.489-3.513.964 0 .476 1.508.873 3.513.966v3.455h1.784v-3.456c2-.092 3.505-.489 3.505-.964s-1.504-.872-3.505-.964m0 1.636v-.001c-.05.003-.31.019-.885.019-.46 0-.785-.013-.899-.02v.002c-1.77-.078-3.093-.387-3.093-.756s1.322-.677 3.093-.756v1.205c.116.008.448.027.906.027.55 0 .827-.022.878-.027v-1.204c1.767.078 3.087.387 3.087.755 0 .367-1.32.676-3.087.755'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_6625_411790'
          x1='0.608'
          x2='24.155'
          y1='0.371'
          y2='21.208'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#53AE94'></stop>
          <stop offset='1' stopColor='#2E9175'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const NotificationsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='25'
      fill='none'
      viewBox='0 0 23 25'
    >
      <path
        fill='#7F779E'
        d='M11.5.5c.797 0 1.5.703 1.5 1.5v.938c3.422.656 6 3.703 6 7.312v.89c0 2.204.797 4.36 2.25 6l.328.376c.422.468.516 1.078.281 1.64-.234.516-.797.844-1.359.844h-18c-.61 0-1.172-.328-1.406-.844a1.518 1.518 0 01.281-1.64l.328-.375C3.156 15.5 4 13.344 4 11.14v-.891c0-3.61 2.578-6.656 6-7.313V2c0-.797.656-1.5 1.5-1.5zm2.11 23.156c-.563.563-1.313.844-2.11.844-.797 0-1.594-.281-2.156-.844-.563-.562-.844-1.36-.844-2.156h6c0 .797-.328 1.594-.89 2.156z'
      ></path>
    </svg>
  )
}

export const WalletIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#fff'
        d='M1.375 3v1h10V3a1 1 0 00-1-1h-8a1 1 0 00-1 1z'
      ></path>
      <path
        stroke='#fff'
        d='M1.375 4v9a1 1 0 001 1h12a1 1 0 001-1V5a1 1 0 00-1-1h-10.5m-2.5 0V3a1 1 0 011-1h8a1 1 0 011 1v1h-7.5m-2.5 0h2.5m6 6h3'
      ></path>
    </svg>
  )
}

export const HomeIconV2 = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='17'
      fill='none'
      viewBox='0 0 20 17'
    >
      <path
        fill='#FB8111'
        d='M9.313 1.25a1.08 1.08 0 011.343 0l8 7c.407.375.438 1 .094 1.438-.375.406-1 .437-1.438.093L10 3.344 2.656 9.78c-.437.344-1.062.313-1.437-.094-.344-.437-.313-1.062.093-1.437l8-7z'
      ></path>
      <path
        fill='#FB8111'
        d='M10 3.344l7 6.125v6.281c0 .688-.563 1.25-1.25 1.25h-2.5c-.719 0-1.25-.531-1.25-1.25V13c0-.563-.469-1-1-1H9c-.563 0-1 .438-1 1v2.75C8 16.469 7.437 17 6.75 17h-2.5C3.531 17 3 16.469 3 15.75V9.469l7-6.125z'
        opacity='0.4'
      ></path>
    </svg>
  )
}

export const CrashIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill='#968EB0'
        d='M7.969 13.25a3.47 3.47 0 00-3.282-3.219c.657-1.5 1.813-3.969 2.782-5.375C10.03.875 13.844.75 16.094 1.188a.862.862 0 01.718.718c.438 2.25.313 6.063-3.468 8.625-1.406.938-3.844 2.063-5.375 2.719zM13 3.75c-.719 0-1.25.563-1.25 1.25 0 .719.531 1.25 1.25 1.25.688 0 1.25-.531 1.25-1.25 0-.688-.563-1.25-1.25-1.25z'
      ></path>
      <path
        fill='#968EB0'
        d='M1.719 10c-.25 0-.5-.125-.625-.375a.659.659 0 010-.75L2.75 6.156c.406-.656 1.125-1.093 1.906-1.093h2.532C6.313 6.5 5.28 8.655 4.688 10h-2.97zm11.187 3.344c0 .781-.406 1.5-1.062 1.906l-2.719 1.656c-.219.125-.531.157-.75 0a.729.729 0 01-.406-.656v-3c1.375-.594 3.531-1.563 4.937-2.438v2.532zm-6.719 2.344C4.657 17.25 1 17 1 17s-.25-3.656 1.281-5.188c1.094-1.062 2.844-1.062 3.906 0a2.754 2.754 0 010 3.876zM4.72 13.28a.87.87 0 00-1.282 0C2.907 13.781 3 15 3 15s1.219.094 1.719-.438a.87.87 0 000-1.28z'
        opacity='0.4'
      ></path>
    </svg>
  )
}

export const PlinkoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='16'
      fill='none'
      viewBox='0 0 20 16'
    >
      <path
        fill='#968EB0'
        d='M18.5 4c.813 0 1.5.688 1.5 1.5 0 .469-.219.844-.5 1.125V13c0 1.656-1.375 3-3 3H4c-1.125 0-2-.875-2-2v-1h12v1h2.5c.531 0 1-.438 1-1V6.625A1.464 1.464 0 0117 5.5 1.5 1.5 0 0118.5 4zM12 0c1.094 0 2 .906 2 2H2c0-1.094.875-2 2-2h8z'
      ></path>
      <path
        fill='#968EB0'
        d='M14 3c1.094 0 2 .906 2 2v6c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V5c0-1.094.875-2 2-2h12zM5.187 6.531c.094-.25.063-.5-.093-.687A.708.708 0 004.5 5.5h-2a.74.74 0 00-.75.75c0 .438.313.75.75.75h.906l-.875 2.5a.74.74 0 00.438.969.866.866 0 00.281.031c.281 0 .594-.188.688-.469l1.25-3.5zm4.5 0c.094-.25.063-.5-.093-.687A.708.708 0 009 5.5H7a.74.74 0 00-.75.75c0 .438.313.75.75.75h.906l-.875 2.5a.74.74 0 00.438.969.866.866 0 00.281.031c.281 0 .594-.188.688-.469l1.25-3.5zm4.5 0c.094-.25.063-.5-.093-.687A.708.708 0 0013.5 5.5h-2a.74.74 0 00-.75.75c0 .438.313.75.75.75h.906l-.875 2.5a.74.74 0 00.438.969.866.866 0 00.281.031c.281 0 .594-.188.688-.469l1.25-3.5z'
        opacity='0.4'
      ></path>
    </svg>
  )
}

export const LottoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='18'
      fill='none'
      viewBox='0 0 21 18'
    >
      <path
        fill='#968EB0'
        d='M7.875 1.25c.844-.469 1.906-.188 2.375.625l5.219 9.063c.5.812.219 1.874-.625 2.374l-6.031 3.47c-.844.468-1.907.187-2.376-.626L1.22 7.094c-.469-.813-.188-1.875.625-2.375L7.875 1.25zM5.469 9.688c-.219.812.281 1.624 1.093 1.843a1.492 1.492 0 001.844-1.062l.094-.281v-.032l.563.969-.376.219c-.218.094-.28.375-.156.594.125.187.375.28.594.156l1.5-.875a.42.42 0 00.156-.594.42.42 0 00-.594-.156l-.374.219-.563-.97h.031l.281.095c.813.218 1.657-.282 1.876-1.094.218-.813-.282-1.625-1.094-1.844L7.125 6c-.344-.094-.688.125-.781.469l-.875 3.218z'
      ></path>
      <path
        fill='#968EB0'
        d='M11.125 16.594l4.219-2.438a2.713 2.713 0 001-3.719l-4.282-7.406c.063 0 .126-.031.188-.031h7c.938 0 1.75.813 1.75 1.75v10.5c0 .969-.813 1.75-1.75 1.75h-7c-.438 0-.844-.125-1.125-.406zM19.094 5.25c-.438-.375-1.063-.281-1.469.094l-.125.187-.156-.187c-.407-.375-1.063-.469-1.469-.094-.5.438-.531 1.188-.094 1.625l1.531 1.563c.094.093.25.093.344 0l1.532-1.563c.437-.438.406-1.188-.094-1.625z'
        opacity='0.4'
      ></path>
    </svg>
  )
}
