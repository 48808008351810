import { configureStore } from '@reduxjs/toolkit'
import { gameSlice } from './reducers/gameSlice'
import { globalSlice } from './reducers/globalSlice'
import { uiSlice } from './reducers/uiSlice'

const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
    game: gameSlice.reducer,
    ui: uiSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
