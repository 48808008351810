import googleLogo from '@assets/logo_google.png'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { connectSocket } from '@shared'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { authGoogleApi } from 'apis/authApi'
import { getAffiliateCookies } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUi,
  hideLoginModal,
  hideMobileNavigation,
  showToast,
} from 'store/reducers/uiSlice'

export const GoogleLoginButton = ({ isTest }: { isTest?: boolean }) => {
  const [credentialResponse, setCredentialResponse] =
    useState<CredentialResponse>()
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const { generalConfig } = useAppSelector(getUi)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useT()
  const { affId, channelId, refId } = getAffiliateCookies()

  useEffect(() => {
    if (credentialResponse?.credential) {
      getAuth(credentialResponse.credential)
    } else {
      window.addEventListener('focus', () => {
        setIsLoading(false)
        window.removeEventListener('focus', () => {
          setIsLoading(false)
        })
      })
    }
  }, [credentialResponse])

  const getAuth = async (googleJwt: string) => {
    if (generalConfig.googleLoginURL)
      try {
        const resData = await authGoogleApi(generalConfig.googleLoginURL, {
          ...(affId !== null ? { affId } : {}),
          ...(refId !== null ? { refId } : {}),
          ...(channelId !== null ? { channelId } : {}),
          ...(isTest ? { isTest } : {}),
          googleJwt,
        })
        if (resData?.group) {
          localStorage.setItem('group', resData.group)
        }
        if (resData?.token && !alienContext?.isSocketConnected) {
          connectSocket(resData)
          setIsLoading(false)
          dispatch(hideLoginModal())
          dispatch(hideMobileNavigation())
          dispatch(
            showToast({
              show: true,
              message: 'user.login_success',
              severity: 'success',
            })
          )
        } else {
          // Login with google failed
          console.error('google auth login failed::', resData)
          setIsLoading(false)
          dispatch(
            showToast({
              show: true,
              message: `Login failed: ${resData?.message}`,
              severity: 'error',
            })
          )
        }
      } catch (error) {
        console.error('error::', error)
      }
  }

  const handleClick = () => {
    setIsLoading(true)
    setInterval(() => {
      setIsLoading(false)
    }, 5000)
  }
  // TODO: Make Google button invisible and create a button infront of it
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: 220,
          height: 48,
          '&:hover': {
            opacity: 0.8,
          },
          display: isLoading ? 'none' : 'block',
        }}
      >
        <Button
          sx={{
            position: 'absolute',
            inset: 0,
            border: `2px solid ${colors.gray[4]}`,
            color: colors.gray[12],
            fontWeight: 600,
            width: 220,
            height: 48,
          }}
          color='secondary'
          variant='outlined'
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              '& > *:not(:last-child)': {
                marginInlineEnd: 1,
              },
              img: { width: 24 },
            }}
          >
            <img src={googleLogo} alt='' />
            <span>{t('user.sign_in_google')}</span>
          </Stack>
        </Button>
        <Box
          sx={{
            background: 'red',
            position: 'absolute',
            inset: 0,
            opacity: 0.0001,
            width: 220,
            height: 48,
            zIndex: 999999,
          }}
        >
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              setCredentialResponse(credentialResponse)
              console.log('credentialResponse::', credentialResponse)
            }}
            onError={() => {
              setIsLoading(false)
            }}
            click_listener={handleClick}
            theme='filled_black'
            width='224px'
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: 48,
          display: isLoading ? 'flex' : 'none',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='primary' size={28} />
      </Box>
    </>
  )
}
