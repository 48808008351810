import {
  IconDefinition,
  faBell,
  faExclamationCircle,
  faRectangleXmark,
  faWarning,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { gameplayBreakpoint } from '@shared/constants'
import { colors } from '@theme'
import { alertBarApi } from 'apis/strapiApi'
import { Alert } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'

const AlertBar = () => {
  const theme = useTheme()
  const [alert, setAlert] = useState<Alert>()
  const { generalConfig } = useAppSelector(getUi)
  const { locale } = useParams()
  const [showAlert, setShowAlert] = useState(alert?.show)
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))

  useEffect(() => {
    const getAlert = async () => {
      const data = await alertBarApi(locale)
      if (data) setAlert(data.data)
    }
    if (locale && generalConfig.availableLanguages?.includes(locale)) {
      getAlert()
    }
  }, [locale, generalConfig.availableLanguages])

  useEffect(() => {
    if (alert?.show) setShowAlert(true)
  }, [alert?.show])

  if (!alert || !alert.show) return <></>

  let icon: IconDefinition

  switch (alert.severity) {
    case 'INFORMATION':
      icon = faBell
      break
    case 'WARNING':
      icon = faWarning
      break
    case 'ERROR':
      icon = faExclamationCircle
      break

    default:
      icon = faBell
      break
  }

  return (
    <Toolbar
      sx={{
        display: showAlert ? 'flex' : 'none',
        background: colors.gray[3],
        justifyContent: 'space-between',
        minHeight: 'auto !important',
        p: 1,
        ...(breakpoint && { marginInlineStart: 9 }),
      }}
    >
      <Stack
        sx={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            svg: { width: 20, height: 20, color: theme.palette.primary.main },
            textAlign: 'center',
          }}
        >
          <FontAwesomeIcon icon={icon} />
          <Typography
            variant='bodyBold'
            sx={{ color: colors.gray[10], fontSize: [11, 16] }}
          >
            {alert.message}
          </Typography>
        </Stack>
        <IconButton onClick={() => setShowAlert(false)}>
          <FontAwesomeIcon icon={faRectangleXmark} />
        </IconButton>
      </Stack>
    </Toolbar>
  )
}

export default AlertBar
