import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import { bannerOneApi } from 'apis/strapiApi'
import StrapiButton from 'parsroyal/components/StrapiButton/StrapiButton'
import { Banner } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'

const BannerOne = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { generalConfig } = useAppSelector(getUi)
  const { locale } = useParams()
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const height = ['173.55vw', 131, 212, 292, 308]

  const [bannerOne, setBannerOne] = useState<Banner>()

  useEffect(() => {
    const getBannerOne = async () => {
      const data = await bannerOneApi(locale)
      if (data) setBannerOne(data.data)
    }
    if (locale && generalConfig.availableLanguages?.includes(locale)) {
      getBannerOne()
    }
  }, [locale, generalConfig.availableLanguages])

  if (!bannerOne) return <Box sx={{ width: 1152, height: 308 }}></Box>

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: 2,
        overflow: 'hidden',
        width: '100%',
        height,
      }}
    >
      <Stack>
        <Skeleton
          variant="rectangular"
          sx={{
            position: 'absolute',
            width: '100%',
            height,
            zIndex: 0,
          }}
        />
        <img
          src={
            sm
              ? bannerOne.img?.bannerImg?.url
              : bannerOne.img?.bannerImgMobile?.url
          }
          style={{ zIndex: 1 }}
          alt="/"
        />

        <Stack
          sx={{
            alignItems: 'center',
            position: 'absolute',
            width: 1,
            height: 1,
            justifyContent: 'center',
            gap: 1.5,
            textAlign: 'center',
            paddingBottom: { xs: 0, sm: '25px', md: '60px', lg: 0 },
            zIndex: 1,
          }}
        >
          <Typography
            variant={dir === 'rtl' ? 'body' : 'bodyBold'}
            sx={{ fontSize: [12, 13.5, 14, 16] }}
          >
            {bannerOne.subHeadline}
          </Typography>
          {bannerOne.button?.map((item) => (
            <StrapiButton
              {...item}
              key={item.id}
              size={md ? 'large' : sm ? 'medium' : 'large'}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

export default BannerOne
