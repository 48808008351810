import {
  Box,
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Flag from 'react-svg-country-flags'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'

interface LanguageSelectProps {
  showLabel?: boolean
}

const LanguageSelect = (props: FormControlProps & LanguageSelectProps) => {
  const { generalConfig } = useAppSelector(getUi)
  const { i18n } = useTranslation()
  const { t } = useT()
  const navigate = useNavigate()
  const location = useLocation()
  const { locale } = useParams()
  const [language, setLanguage] = useState(i18n.language)
  const dir = i18n.dir(i18n.language)
  const availableLanguages = generalConfig.availableLanguages?.split(',')

  const updateLang = (newLang: string) => {
    setLanguage(newLang)
    i18n.changeLanguage(newLang)
    document.body.dir = i18n.dir(newLang)
  }

  useEffect(() => {
    updateLang(i18n.language)
  }, [i18n.language])

  const handleChange = (e: SelectChangeEvent) => {
    updateLang(e.target.value)

    if (locale) {
      navigate(
        `${location.pathname.replace(locale, e.target.value)}${location.search}`
      )
      window.location.reload()
    }
  }

  const showFlagLabel = (nativeName: string, flag: string) => {
    return (
      <>
        <Box
          sx={{
            width: 21,
            height: 15,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant='rectangular'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
            }}
          />
          <Flag country={flag} />
        </Box>

        {props.showLabel && (
          <Typography sx={{ flex: 1, fontSize: 12, lineHeight: '27px' }}>
            {t(nativeName)}
          </Typography>
        )}
      </>
    )
  }

  return (
    <FormControl
      sx={{
        ...props.sx,
        '.MuiInputBase-input': { padding: '12px 14px' },
        '.MuiSelect-select': { p: '12px !important' },
        '.MuiSelect-icon': { display: props.showLabel ? 'initial' : 'none' },
        '&.MuiFormControl-root': { display: 'flex', alignItems: 'center' },
      }}
      fullWidth
    >
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={language}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          transformOrigin: { vertical: 'bottom', horizontal: 'center' },
          sx: {
            '.MuiPaper-root': {
              minWidth: 'auto !important',
              border: `1px solid ${colors.gray[4]}`,
              maxWidth: props.showLabel ? 160 : 45,
              width: '100%',
              right: props.showLabel ? 'auto' : '14px !important',
              left: props.showLabel ? 'auto' : '14px !important',
            },
          },
        }}
        sx={{
          position: 'relative',
          maxWidth: 160,
          width: '100%',

          '.MuiSvgIcon-root':
            dir === 'rtl'
              ? {
                  left: '7px',
                  right: 'initial',
                }
              : { right: '7px', left: 'initial' },
          fieldset: {
            border: `none`,
          },
        }}
      >
        {availableLanguages?.map((lang) => (
          <MenuItem value={lang} key={lang}>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                '*': {
                  fontFamily: `${
                    i18n.dir(i18n.language) === 'rtl'
                      ? 'Iranyekan'
                      : '"Nunito Sans"'
                  }, sans-serif !important`,
                },
                textTransform: 'uppercase',
              }}
            >
              {lang === 'en' ||
              lang === 'fa' ||
              lang === 'es' ||
              lang === 'pt' ? (
                <>
                  {lang === 'en' && showFlagLabel('languages.english', 'gb')}
                  {lang === 'fa' && showFlagLabel('languages.persian', 'ir')}
                  {lang === 'es' && showFlagLabel('languages.spanish', 'es')}
                  {lang === 'pt' && showFlagLabel('languages.portuguese', 'br')}
                </>
              ) : (
                lang
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LanguageSelect
