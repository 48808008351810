import { Box, BoxProps, SxProps, Theme } from '@mui/material'
import { colors, gradients } from '@theme'
import { useTranslation } from 'react-i18next'

const sx: SxProps<Theme> = {
  position: 'relative',
  background: gradients[7],
  border: `1px solid ${colors.gray[3]}`,
  borderRadius: '12px',
  p: 2,
  gap: 2,
  // '.MuiBox-root': {
  //   width: 1,
  //   justifyContent: 'space-between',
  // },
}

const Banner = (props: BoxProps) => {
  const { children } = props
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  return (
    <Box sx={{ ...sx, ...props.sx }} className='banner' dir={dir}>
      {children}
    </Box>
  )
}

export default Banner
