import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const gameListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}games?populate=*&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
// export const brandApi = async (locale?: string) => {
//   const data = JSON.stringify({})

//   const config: AxiosRequestConfig = {
//     method: 'get',
//     maxBodyLength: Infinity,
//     url: `${process.env.REACT_APP_STRAPI_BASE_API}brand?populate=deep&locale=${
//       locale || 'en'
//     }`,
//     data,
//   }

//   try {
//     const res: AxiosResponse = await axios(config)
//     return res.data
//   } catch (error) {
//     console.error(error)
//     return
//   }
// }

export const popupModalApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }popup-modal?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const alertBarApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}alert-bar?locale=${
      `${locale}` || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const tournamentSectionApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }tournament?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const pageListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}pages?populate=deep&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const pageApi = async (slug: string, locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }slugify/slugs/page/${slug}?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const heroSliderListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }hero-sliders?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const walletInfoListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}wallet-infos?locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const loginInfoListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}login-infos?locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const bannerOneApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }banner-one?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const bannerTwoApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }banner-two?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const joinUsSectionApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }join-us-section?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const gameBannerApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }game-banner?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const footerApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }footer?populate=deep,3&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const generalConfigWithLocalesApi = async () => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}general-config?populate=brand.logo,brand.logoMobile,brand.licenseLogos,brand.socialMediaUrls,localizations,localizations.brand.logo,localizations.brand.logoMobile,localizations.brand.licenseLogos,localizations.brand.socialMediaUrls&_locale=*`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const comingSoonApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}coming-soon?populate=deep`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const walletConfigApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API
    }wallet-config?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const verificationApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API}verification?&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
