import { faBell, faRightLeft } from '@fortawesome/pro-duotone-svg-icons'
import SideDrawer from 'parsroyal/components/SideDrawer/SideDrawer'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideNotificationSideDrawer } from 'store/reducers/uiSlice'

const NotificationSideDrawer = () => {
  const notificationSideDrawer = useAppSelector(getUi).notificationSideDrawer
  const dispatch = useAppDispatch()
  return (
    <SideDrawer
      open={notificationSideDrawer.open}
      title='nav.notifications'
      icon={faBell}
      onClose={() => dispatch(hideNotificationSideDrawer())}
    ></SideDrawer>
  )
}

export default NotificationSideDrawer
