import React from 'react'
import Login from './Login'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideLoginModal } from 'store/reducers/uiSlice'
import { ModalComponent } from '..'

const LoginModal = () => {
  const loginModal = useAppSelector(getUi).loginModal
  const dispatch = useAppDispatch()
  return (
    <ModalComponent
      open={loginModal.open}
      title="user.register_login"
      showBackButton
      showCloseButton
      height={530}
      onClose={() => dispatch(hideLoginModal())}
      keepMounted
    >
      <Login />
    </ModalComponent>
  )
}

export default LoginModal
