import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { IModalProps } from '@type'
import { AlienContext } from 'App'
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { v4 as uuidv4 } from 'uuid'
import TransactionCard from './components/TransactionCard'
import {
  Player,
  Transaction,
  TransactionStatus,
  TransactionType,
} from '@alienclient/back-front'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { formatCurrencyAmount } from 'helpers/helpers'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'

const TransactionList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const alienContext = useContext(AlienContext)
  const transactionList = useAppSelector(getGlobal).transactionList?.data
  const [checked, setChecked] = useState(true)
  const { t } = useT()
  const [activeTab, setActiveTab] = useState(
    searchParams.get('wallet') || 'deposit'
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const getList = () => {
    if (alienContext?.isSocketConnected)
      alienContext?.client.getTransactions(
        {},
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )
  }

  useEffect(() => {
    getList()
  }, [alienContext?.isSocketConnected])

  // TODO: Fetch data every 10 seconds
  async function fetchData() {
    try {
      getList()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    // Fetch data immediately on mount
    fetchData()

    // Set up an interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, []) // Empty dependency array ensures this effect runs only on mount and unmount

  let filteredTransactionList

  if (activeTab === 'deposit') {
    filteredTransactionList = transactionList
      ?.filter(
        (item) =>
          item.transactionType === TransactionType.DEPOSIT ||
          item.transactionType === TransactionType.TOPUP
      )
      .map(
        (item) =>
          item.status !== TransactionStatus.PENDING && (
            <TransactionCard {...item} key={item.txId} />
          )
      )

    if (checked) {
      filteredTransactionList = transactionList
        ?.filter(
          (item) =>
            item.transactionType === TransactionType.DEPOSIT ||
            item.transactionType === TransactionType.TOPUP
        )
        .map((item) => <TransactionCard {...item} key={item.txId} />)
    }
  } else {
    filteredTransactionList = transactionList
      ?.filter((item) => item.transactionType === TransactionType.WITHDRAW)
      .map(
        (item) =>
          item.status !== TransactionStatus.PENDING && (
            <TransactionCard {...item} key={item.txId} />
          )
      )

    if (checked) {
      filteredTransactionList = transactionList
        ?.filter((item) => item.transactionType === TransactionType.WITHDRAW)
        .map((item) => <TransactionCard {...item} key={item.txId} />)
    }
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} />
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={t('label.show_pending_transactions')}
        componentsProps={{ typography: { variant: 'bodySmallBold' } }}
      />

      <Box>{filteredTransactionList}</Box>
    </Stack>
  )
}

const walletTabs = ['deposit', 'withdraw']

interface HeaderProps {
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Header = (props: HeaderProps) => {
  const global = useAppSelector(getGlobal)
  const { activeTab, setActiveTab } = props
  let [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const theme = useTheme()
  const wallet = global.player?.wallet
  const activeCurrency = global.player?.activeCurrency

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      wallet: tab,
    })
  }

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 1.5,
        background: colors.gray[2],
        borderRadius: 4,
        p: 1,
      }}
    >
      {walletTabs.map((tab) => (
        <Button
          onClick={() => handleTabClick(tab)}
          sx={{
            fontSize: 14,
            fontWeight: 700,
            height: 40.5,
            padding: '8px 66px',
            textTransform: 'capitalize',
            py: 1,
            width: 1,
            color: '#fff',
            ...(activeTab === tab && {
              background: 'linear-gradient(98deg, #0D7CF2 0%, #084A91 100%)',
              boxShadow: '0px 0px 10px 0px #003977',
            }),
          }}
          color="secondary"
          key={tab}
        >
          {t(`wallet.${tab}`)}
        </Button>
      ))}
    </Stack>
  )
}

export default TransactionList
