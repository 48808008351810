import { faClipboard, faCopy } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { colors, gradients, shadows, theme } from '@theme'
import { IModalProps } from '@type'
import { AlienContext } from 'App'
import useT from 'hooks/useTranslation'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal, resetGlobal } from 'store/reducers/globalSlice'
import { getUi, showToast } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'

const UserDropDown = (props: IModalProps) => {
  const dispatch = useAppDispatch()
  const global = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const alienContext = useContext(AlienContext)
  const [loading, setLoading] = useState(true)
  const { i18n } = useTranslation()
  const { t } = useT()
  const navigate = useNavigate()
  const { locale } = useParams()
  const [copied, setCopied] = useState(false)

  const handleLogout = () => {
    navigate(`/${locale}/`)
    dispatch(resetGlobal())
    alienContext?.client.disconnect({
      uuid: uuidv4(),
      meta: { tabId: window.tabId },
    })
    localStorage.removeItem('group')
    localStorage.removeItem('auth')
    dispatch(
      showToast({
        show: true,
        message: 'user.logout_success',
        severity: 'success',
      })
    )
  }

  return (
    <Stack
      sx={{
        '*': {
          fontFamily: `${
            i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
          }, sans-serif !important`,
        },
        background: gradients[5],
        boxShadow: shadows[0],
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 0.5,
        width: 1,
        p: 2,
        borderRadius: 2,
        '.MuiSkeleton-root': {
          width: 68,
          height: 68,
          borderRadius: 4,
          position: 'absolute',
          display: loading ? 'block' : 'none',
        },
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          height: 68,
          width: 68,
          img: { zIndex: 1 },
        }}
      >
        <Skeleton variant="rectangular" />
        <img src="/assets/png/user.png" alt="" />
      </Stack>
      <Stack sx={{ my: 1 }}>
        <Typography variant="bodyXSmall" color={colors.gray[6]}>
          {t('label.nickname')}
        </Typography>
        <Typography variant="bodyBold">{global.player?.nickname}</Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: colors.gray[8],
          border: `1px solid ${colors.gray[6]}`,
          borderRadius: 4,
          height: 26,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: colors.gray[8],
            fontSize: 11,
            paddingInlineStart: 1,
            span: { fontWeight: 700, paddingInlineEnd: 0.75 },
          }}
        >
          <span> {t('label.player_id')}:</span>
          <span style={{ display: 'inline-block' }}>
            {' '}
            {global.player?.playerId}
          </span>
        </Typography>
        <IconButton
          sx={{
            paddingInlineStart: 0,
            ':hover, :focus': { background: 'none' },
          }}
          onClick={() => {
            setCopied(true)
            navigator.clipboard.writeText(global.player?.playerId || '')

            setTimeout(() => {
              setCopied(false)
            }, 1000)
          }}
        >
          <FontAwesomeIcon
            icon={copied ? faClipboard : faCopy}
            size="2xs"
            color={colors.gray[7]}
            fixedWidth
          />
        </IconButton>
      </Stack>
      <Typography
        variant="caption"
        sx={{
          color: colors.gray[6],
          fontSize: 10,
          fontWeight: 600,
          span: { fontWeight: 700, paddingInlineEnd: 0.5 },
        }}
      >
        {global.player?.emailAddress && (
          <>
            <span> {t('label.email_address')}:</span>
            {global.player?.emailAddress}
          </>
        )}
        {global.player?.telegramId && (
          <>
            <span> {t('label.telegram_id')}:</span>
            {global.player?.telegramId}
          </>
        )}
      </Typography>
      {sm && generalConfig.environment !== 'PROD' && (
        <Chip
          color="info"
          sx={{ height: [19, 27], '.MuiChip-label': { p: [0.5, 1.5] } }}
          label={
            <Typography
              variant="bodySmallBold"
              color={colors.gray[1]}
              sx={{ fontSize: [11, 14] }}
            >
              ENV:
              {generalConfig.environment}
            </Typography>
          }
        />
      )}

      <Button
        sx={{
          mt: 1.5,
          border: `1px solid ${colors.gray[6]}`,
          borderRadius: 1.5,
          '&:hover': {
            background: colors.gray[4],
          },
        }}
        onClick={() => handleLogout()}
        variant="outlined"
        size="small"
      >
        <Typography
          variant="buttonMedium"
          sx={{ color: colors.gray[9], fontWeight: 700 }}
        >
          {t('user.logout')}
        </Typography>
      </Button>
    </Stack>
  )
}

export default UserDropDown
