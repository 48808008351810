import { CurrencySelectProps } from '../CurrencySelect'
import { PaymentGatewayProps } from '../PaymentGatewaySelect'
import WithdrawInputs from './WithdrawInputs'

export const Withdraw = (props: PaymentGatewayProps & CurrencySelectProps) => {
  return (
    <>
      <WithdrawInputs {...props} />
    </>
  )
}
