import {
  AppBar,
  Box,
  Chip,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { colors, theme } from '@theme'

import { AlienContext } from 'App'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import AlertBar from '../AlertBar/AlertBar'
import NavDrawer from '../Drawer/NavDrawer/NavDrawer'
import MobileBottomNav from '../MobileBottomNav/MobileBottomNav'
import LoggedInUser from './components/LoggedInUser'
import LoginRegister from './components/LoginRegister'
import Logo from './components/Logo'
import WalletContainer from './components/WalletContainer'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { getUi, showMobileNavigation } from 'store/reducers/uiSlice'

function Header() {
  const alienContext = useContext(AlienContext)
  const global = useAppSelector(getGlobal)
  const { tournamentSection } = useAppSelector(getUi)
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const currentPath = window.location.pathname
  const { locale } = useParams()
  const { generalConfig } = useAppSelector(getUi)
  const homePath = `/${locale}` || `/${locale}/`
  const isSocketConnected = global.player?.playerId
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const isFunPlay = searchParams.get('funPlay')

  const handleCupClick = () => {
    dispatch(showMobileNavigation({ index: NavIndex.Cup, open: true }))
  }

  // Enable background scrolling
  useEffect(() => {
    if (breakpoint) {
      document.body.style.top = 'initial'
      document.body.style.position = 'initial'
      document.body.style.width = 'initial'
      const scrollY = document.body.dataset.scrollY
      if (scrollY) window.scroll(0, parseInt(scrollY))
      document.body.removeAttribute('data-scroll-y')
    }
  }, [breakpoint])

  return (
    <>
      <AppBar component='nav' position='fixed' elevation={2} dir={dir}>
        <Toolbar
          sx={{
            background: colors.gray[2],
            p: 2,
            py: 1,
            //   position: ['absolute', 'relative', 'relative'],
            justifyContent: 'space-between',
            width: 1,
            zIndex: 100,
            height: 68,
          }}
          disableGutters
        >
          <Stack
            sx={{
              width: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& > *:not(:last-child)': {
                marginInlineEnd: [1, 2],
              },
              ...(breakpoint &&
                !generalConfig.showComingSoon && { marginInlineStart: 9 }),
            }}
          >
            <Stack
              sx={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                '& > *:not(:last-child)': {
                  marginInlineEnd: 2,
                },
              }}
            >
              {window.location.pathname.includes('play') && !breakpoint ? (
                <Box sx={{ cursor: 'pointer' }}>
                  <Box onClick={handleCupClick}>
                    <Stack sx={{ img: { width: 45 } }}>
                      <img src={tournamentSection.cupImg?.url} alt='' />
                    </Stack>
                  </Box>
                </Box>
              ) : (
                <Logo />
              )}
            </Stack>
            {isSocketConnected && (
              <>
                <WalletContainer />
                {sm && generalConfig.environment !== 'PROD' && (
                  <Chip
                    color='info'
                    sx={{
                      height: [19, 27],
                      '.MuiChip-label': { p: [0.5, 1.5] },
                      position: 'absolute',
                      ...(dir === 'rtl' ? { left: 75 } : { right: 75 }),
                    }}
                    label={
                      <Typography
                        variant='bodySmallBold'
                        color={colors.gray[1]}
                        sx={{ fontSize: [11, 14] }}
                      >
                        ENV:
                        {generalConfig.environment}
                      </Typography>
                    }
                  />
                )}
                <LoggedInUser />
              </>
            )}
          </Stack>
          {!isSocketConnected &&
            !generalConfig.showComingSoon &&
            (isFunPlay && !breakpoint ? (
              <Logo />
            ) : (
              <>
                {sm && generalConfig.environment !== 'PROD' && (
                  <Chip
                    color='info'
                    sx={{
                      height: [19, 27],
                      '.MuiChip-label': { p: [0.5, 1.5] },
                      marginInlineEnd: 2.5,
                    }}
                    label={
                      <Typography
                        variant='bodySmallBold'
                        color={colors.gray[1]}
                        sx={{ fontSize: [11, 14] }}
                      >
                        ENV:
                        {generalConfig.environment}
                      </Typography>
                    }
                  />
                )}
                <LoginRegister />
              </>
            ))}
        </Toolbar>
        {breakpoint && !generalConfig.showComingSoon && <NavDrawer />}
        {currentPath === homePath && <AlertBar />}
      </AppBar>
      {!breakpoint && !generalConfig.showComingSoon && <MobileBottomNav />}
      <Toolbar />
    </>
  )
}

export default Header
