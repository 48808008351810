import { LaunchInfoStatus } from '@alienclient/back-front'
import { AlienContext } from 'App'
import { Crisp } from 'crisp-sdk-web'
import React, { useContext } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getGame } from 'store/reducers/gameSlice'
import { getGlobal } from 'store/reducers/globalSlice'

const usePlayerCrispData = () => {
  const global = useAppSelector(getGlobal)
  const game = useAppSelector(getGame)
  const activeWallet =
    global.player?.wallet.byCurrency[global.player?.activeCurrency]

  return {
    googleEmail: global.player?.emailAddress || '',
    telegramId: global.player?.telegramId || '',
    playerId: global.player?.playerId || '',
    nickname: global.player?.nickname || '',
    currency: global.player?.activeCurrency || '',
    balance: activeWallet?.balance || 0,
    depositCount: activeWallet?.deposit.count || 0,
    depositTotalAmount: activeWallet?.deposit.totalAmount || 0,
    topupCount: activeWallet?.topup?.count || 0,
    topupTotalAmount: activeWallet?.topup?.totalAmount || 0,
    withdrawCount: activeWallet?.withdraw.count || 0,
    withdrawTotalAmount: activeWallet?.withdraw.totalAmount || 0,
    walletType: activeWallet?.type || '',
    walletAddress: activeWallet?.address || '',
    playerBlockStatus:
      game.status === LaunchInfoStatus.PLAYER_IS_LOCKED
        ? 'BLOCKED'
        : 'NOT BLOCKED',
  }
}

export default usePlayerCrispData
