// import { EventsHandler } from './eventHandler'

import {
  AlienClient,
  AuthResult,
  LaunchInfoStatus,
} from '@alienclient/back-front'
import {
  checkJWTValidity,
  formatCurrencyAmount,
  postToGameIframe,
  refReplacer,
} from 'helpers/helpers'
import { addLog } from 'helpers/logger'
import i18n from 'i18n'
import { initGame } from 'store/reducers/gameSlice'
import {
  setBetsList,
  setGetPaymentUrlResponse,
  setPaymentVerificationResponse,
  setPlayerNickname,
  setTransactionList,
  setUploadFileUrl,
  updateKycVerification,
  updateWallet,
} from 'store/reducers/globalSlice'
import {
  hideMobileNavigation,
  hidePopupModal,
  hideWalletModal,
  showToast,
} from 'store/reducers/uiSlice'
import store from 'store/store'
import { v4 as uuidv4 } from 'uuid'
import { EventsHandler } from './eventsHandler'

export const alienClient = new AlienClient()
const eventsHandler = new EventsHandler()

export const alienClientEventsReceiver = async (result: AuthResult) => {
  // Connect to web socket

  try {
    if (result.token) {
      await alienClient.connect({
        token: result.token,
        channelName: result.channelName,
        connectionType: result.connectionType,
        playerFullId: result.playerFullId,
        publishKey: result.publishKey,
        subscribeKey: result.subscribeKey,
      })
    }
    // TODO: Add error message for user
  } catch (error) {
    console.error('error::', error)
    return
  }

  alienClient.onOpen(async () => {})

  alienClient.onError(async (event) => {
    // console.log('Error::', event)
    addLog('onError', event)
    localStorage.removeItem('group')
    localStorage.removeItem('auth')
    window.isTelegramCalledOnce = false
    if (result.token) {
      const isExpired = checkJWTValidity(result.token)
      if (isExpired) {
        eventsHandler.handleLogout()
      } else {
        await alienClientEventsReceiver(result)
      }
    }
  })

  alienClient.onClose(async (initInfo) => {
    // console.log('Socket is closed::', initInfo)

    // console.log('Socket is closed SERIALIZED::', serializeObject(initInfo))
    addLog('onClose', JSON.stringify(initInfo, refReplacer(), 4))
  })

  alienClient.onDisconnected(async (result) => {
    // console.log('onDisconnected::', result)
    addLog('onDisconnected', result)
    if (window.tabId === result?.traceData.meta.tabId) {
      eventsHandler.handleLogout()
    }
  })

  alienClient.onInit(async (initInfo) => {
    const { player, paymentGateways, group, env } = initInfo
    // console.log('socket init!!', Date.now())
    addLog('onInit', { env, group, paymentGateways, player })
    eventsHandler.handleInit(initInfo)
  })

  alienClient.onPlayerNicknameChange(async (result) => {
    // console.log('onPlayerNicknameChange::')

    store.dispatch(setPlayerNickname(result))
    // console.dir(result, { depth: null, colors: true })
  })

  alienClient.onNotification(async (notification) => {
    // console.dir(notification, { depth: null, colors: true })
    eventsHandler.handleNotification(notification)
  })

  alienClient.onGetCurrenciesResponse(async (response) => {
    // console.log('onGetCurrenciesResponse::')
    // console.dir(response, { depth: null, colors: true })
  })

  alienClient.onGetGameLaunchResponse(async (response) => {
    if (response.traceData.meta?.tabId === window.tabId && response.data) {
      // console.log('tab id matches!!')
      // console.log('real launch res: ', response)

      store.dispatch(initGame(response.data))

      if (
        response.data.status === LaunchInfoStatus.PLAYER_IS_LOCKED ||
        response.data.status === LaunchInfoStatus.GAME_ID_NOT_VALID
      ) {
        store.dispatch(
          showToast({
            message: response.data.message,
            show: true,
            severity: 'error',
          })
        )
      }
    }
    // console.log('onGetGameLaunchResponse::')
    addLog('onGetGameLaunchResponse', response)
    // console.dir(response, { depth: null, colors: true })
  })
  alienClient.onGetBetsResponse(async (result) => {
    store.dispatch(setBetsList(result))

    // console.log('onGetBetsResponse::')
    // addLog('onGetBetsResponse', result)
    // console.dir(result, { depth: null, colors: true })
  })

  alienClient.onGetPlayerWalletResponse(async (response) => {
    // console.log('onGetPlayerWalletResponse::')
    addLog('onGetPlayerWalletResponse', response)

    // console.dir(response, { depth: null, colors: true })
    postToGameIframe('balanceUpdated', {})
    // console.log(
    //   '[onGetPlayerWalletResponse] sent balance update post message!!'
    // )
    store.dispatch(updateWallet(response))
  })

  alienClient.onSetActiveCurrencyResponse(async (response) => {
    // console.log('onSetActiveCurrencyResponse::')
    addLog('onSetActiveCurrencyResponse', response)

    postToGameIframe('balanceUpdated', {})
    // console.log(
    //   '[onSetActiveCurrencyResponse] sent balance update post message!!'
    // )
  })

  alienClient.onGetTransactionsResponse(async (response) => {
    // console.log('onGetTransactionsResponse::')
    addLog('onGetTransactionsResponse', response)

    if (response.traceData.meta?.tabId === window.tabId) {
      store.dispatch(setTransactionList(response))
    }
    // console.dir(response, { depth: null, colors: true })
  })

  alienClient.onCloseGameResponse(async (response) => {
    // console.log('onCloseGameResponse::')
    addLog('onCloseGameResponse', response)

    // store.dispatch(removeActiveGame())
    // console.dir(response, { depth: null, colors: true })
  })

  alienClient.onSetUiStateResponse(async (response) => {
    // console.log('onSetUiStateResponse::')
    // console.dir(response, { depth: null, colors: true })
  })

  alienClient.onGetUiStateResponse(async (result) => {
    // console.log('onGetUiStateResponse::')
    if (result.data) {
      const data = JSON.parse(result.data)
      if (data.hidePopupModal) store.dispatch(hidePopupModal())
    }
    // console.dir(result, { depth: null, colors: true })
  })
  alienClient.onAddKycVerificationFileResponse(async (result) => {
    // console.log('onAddKycVerificationFileResponse::')
    addLog('onAddKycVerificationFileResponse', result)

    // console.dir(result, { depth: null, colors: true })
    if (result.success) {
      if (result.data) {
        store.dispatch(updateKycVerification(result.data))
        store.dispatch(
          showToast({
            show: true,
            message: `toast.file_verification_added`,
            severity: 'success',
          })
        )
      }
    }
  })

  alienClient.onDeleteKycVerificationFileResponse(async (result) => {
    // console.log('onDeleteKycVerificationFileResponse::')
    addLog('onDeleteKycVerificationFileResponse', result)

    // console.dir(result, { depth: null, colors: true })
    if (result.success) {
      if (result.data) {
        store.dispatch(updateKycVerification(result.data))
        store.dispatch(
          showToast({
            show: true,
            message: `toast.file_deleted`,
            severity: 'success',
          })
        )
      }
    }
  })

  alienClient.onAddCpgWithdrawResponse(async (result) => {
    // console.log('onAddCpgWithdrawResponse::')
    addLog('onAddCpgWithdrawResponse', result)

    const metaData = result.traceData.meta

    // Add withdraw amount inside meta, render 2 lines, show the amount in second line
    if (result.success) {
      store.dispatch(
        showToast({
          message: 'wallet.withdraw_success',
          message2: `${metaData.currency} ${metaData.amount}`,
          show: true,
          severity: 'success',
        })
      )
      store.dispatch(hideMobileNavigation())
      store.dispatch(hideWalletModal())
    } else {
      store.dispatch(
        showToast({
          message: result.message,
          show: true,
          severity: 'error',
        })
      )
    }
  })
  alienClient.onAddPwWithdrawResponse(async (result) => {
    // console.log('onAddPwWithdrawResponse::')
    addLog('onAddPwWithdrawResponse', result)

    const metaData = result.traceData.meta
    const dir = i18n.dir(i18n.language)

    // console.dir(result, { depth: null, colors: true })
    // Add withdraw amount inside meta, render 2 lines, show the amount in second line
    if (result.success) {
      store.dispatch(
        showToast({
          message: 'wallet.withdraw_success',
          message2:
            dir === 'ltr'
              ? `${metaData.currency} ${formatCurrencyAmount(
                  metaData.amount,
                  metaData.currency
                )}`
              : `${formatCurrencyAmount(metaData.amount, metaData.currency)} ${
                  metaData.currency
                } `,
          show: true,
          severity: 'success',
        })
      )
      store.dispatch(hideMobileNavigation())
      store.dispatch(hideWalletModal())
    } else {
      store.dispatch(
        showToast({
          message: result.message,
          show: true,
          severity: 'error',
        })
      )
    }
  })
  alienClient.onAddJbWithdrawResponse(async (result) => {
    // console.log('onAddJbWithdrawResponse::')
    addLog('onAddJbWithdrawResponse', result)

    const metaData = result.traceData.meta
    const dir = i18n.dir(i18n.language)

    // console.dir(result, { depth: null, colors: true })
    // Add withdraw amount inside meta, render 2 lines, show the amount in second line
    if (result.success) {
      store.dispatch(
        showToast({
          message: 'wallet.withdraw_success',
          message2:
            dir === 'ltr'
              ? `${metaData.currency} ${formatCurrencyAmount(
                  metaData.amount,
                  metaData.currency
                )}`
              : `${formatCurrencyAmount(metaData.amount, metaData.currency)} ${
                  metaData.currency
                } `,
          show: true,
          severity: 'success',
        })
      )
      store.dispatch(hideMobileNavigation())
      store.dispatch(hideWalletModal())
    } else {
      store.dispatch(
        showToast({
          message: result.message,
          show: true,
          severity: 'error',
        })
      )
    }
  })

  alienClient.onGetPaymentUrlResponse(async (result) => {
    // console.log('onGetPaymentUrlResponse::')
    store.dispatch(setGetPaymentUrlResponse(result))
    addLog('onGetPaymentUrlResponse', result)

    // console.dir(result, { depth: null, colors: true })
  })

  alienClient.onVerifyPaymentResponse(async (result) => {
    // console.log('onVerifyPaymentResponse::')
    addLog('onVerifyPaymentResponse', result)
    // console.dir(result, { depth: null, colors: true })
    store.dispatch(setPaymentVerificationResponse(result))
  })

  alienClient.onCancelWithdrawResponse(async (result) => {
    // console.log('onCancelWithdrawResponse::')
    addLog('onCancelWithdrawResponse', result)

    // console.dir(result, { depth: null, colors: true })

    if (result.success) {
      store.dispatch(
        showToast({
          message: 'wallet.withdraw_canceled',
          show: true,
          severity: 'success',
        })
      )
      alienClient.getTransactions(
        {},
        { uuid: uuidv4(), meta: { tabId: window.tabId } }
      )
    } else {
      store.dispatch(
        showToast({
          message: result.message,
          show: true,
          severity: 'error',
        })
      )
    }
  })

  alienClient.onGetUploadUrlResponse(async (result) => {
    // console.log('onGetUploadUrlResponse::')
    addLog('onGetUploadUrlResponse', result)
    // console.dir(result, { depth: null, colors: true })

    if (result.data?.uploadUrl) {
      store.dispatch(setUploadFileUrl(result.data))
      // console.log('upload url data::', result.data)
    }
  })

  alienClient.onSetActiveLanguageResponse(async (result) => {
    // console.log('onSetActiveLanguageResponse::')
    addLog('onSetActiveLanguageResponse', result)
    // console.dir(result, { depth: null, colors: true })
  })
}
