import { Button, ButtonProps, useMediaQuery, useTheme } from '@mui/material'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { AlienContext } from 'App'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import {
  showLoginModal,
  showMobileNavigation,
  showModal,
} from 'store/reducers/uiSlice'
import { Login } from '../Modals/Login/Login'
import useT from 'hooks/useTranslation'
interface PlayDemoButtonProps {
  gameId: string
  slug: string
}

// TODO: Refactor code, clean up toast and verification key states
const PlayDemoButton = (props: PlayDemoButtonProps & ButtonProps) => {
  const { t } = useT()
  const theme = useTheme()
  const navigate = useNavigate()
  const alienContext = useContext(AlienContext)
  const dispatch = useAppDispatch()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const { locale } = useParams()

  // TODO: Create a custom hook to detect if user is connected to socket
  const handleClick = () => {
    if (!alienContext?.isSocketConnected) {
      if (breakpoint) {
        dispatch(showLoginModal())
      } else {
        dispatch(
          showMobileNavigation({
            open: true,
            index: NavIndex.Login,
          })
        )
      }
    } else {
      navigate(`/${locale || 'en'}/play/${props.slug}`)
    }
  }

  const button = () => {
    return (
      <Button
        variant={props.variant}
        color={props.color}
        size={props.size}
        sx={{ ...props.sx }}
        fullWidth={props.fullWidth}
        className='demo-button'
        onClick={handleClick}
      >
        {t('general.real_play')}
      </Button>
    )
  }

  return alienContext?.isSocketConnected ? (
    <NavLink to={`/${locale || 'en'}/play/${props.slug}`}>{button()}</NavLink>
  ) : (
    button()
  )
}

export default PlayDemoButton
