import { faCoins } from '@fortawesome/pro-duotone-svg-icons'
import SideDrawer from 'parsroyal/components/SideDrawer/SideDrawer'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideBetsSideDrawer } from 'store/reducers/uiSlice'
import BetsList from './BetsList'

const BetsSideDrawer = () => {
  const betsSideDrawer = useAppSelector(getUi).betsSideDrawer
  const dispatch = useAppDispatch()
  return (
    <SideDrawer
      open={betsSideDrawer.open}
      title='nav.my_bets'
      icon={faCoins}
      onClose={() => dispatch(hideBetsSideDrawer())}
    >
      <BetsList />
    </SideDrawer>
  )
}

export default BetsSideDrawer
