import { PaymentGatewayName } from '@alienclient/back-front'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import { colors } from '@theme'
import { formatCurrencyAmount } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { Interweave } from 'interweave'
import { WalletInfo } from 'parsroyal/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'
import { ModalComponent } from '.'
import { CurrencySelectProps } from './CurrencySelect'
import { TomanDeposit } from './Deposit/TomanDeposit'
import {
  PaymentGatewayProps,
  PaymentGatewaySelect,
} from './PaymentGatewaySelect'
import { Withdraw } from './Withdraw/Withdraw'
import CryptoDeposit from './Deposit/CryptoDeposit'

interface WalletProps {
  activeTab?: 'deposit' | 'withdraw' | string
}

export const Wallet = (props: WalletProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { paymentGateways } = useAppSelector(getGlobal)
  const [paymentGateway, setPaymentGateway] = useState(
    paymentGateways
      ? (Object.keys(paymentGateways)[0] as PaymentGatewayName)
      : PaymentGatewayName.CPG
  )
  const [currency, setCurrency] = useState(
    paymentGateway === PaymentGatewayName.PW ? 'IRT' : 'USDT'
  )
  const [activeTab, setActiveTab] = useState(
    searchParams.get('wallet') || props.activeTab || 'deposit'
  )

  useEffect(() => {
    if (paymentGateway) {
      setCurrency(paymentGateway === PaymentGatewayName.PW ? 'IRT' : 'USDT')
    }
  }, [paymentGateway])

  useEffect(() => {
    if (paymentGateways) {
      setPaymentGateway(Object.keys(paymentGateways)[0] as PaymentGatewayName)
    }
  }, [paymentGateways])

  useEffect(() => {
    setActiveTab((prev) => searchParams.get('wallet') || prev)
    // if (!searchParams.get('wallet')) setSearchParams({ wallet: 'deposit' })
  }, [searchParams])
  return (
    <Stack
      sx={{
        position: 'relative',
        flexDirection: 'column',
        gap: 1.5,
        flex: 1,
        '.MuiSelect-select': {
          pr: '12px !important',
        },

        '.MuiLoadingButton-root.Mui-disabled': {
          background: '#443f52',
          pointerEvents: 'initial',
        },
      }}
    >
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        currency={currency}
        setCurrency={setCurrency}
      />
      <PaymentGatewaySelect
        paymentGateway={paymentGateway}
        setPaymentGateway={setPaymentGateway}
      />

      {activeTab === 'deposit' ? (
        <Deposit
          paymentGateway={paymentGateway}
          setPaymentGateway={setPaymentGateway}
          currency={currency}
          setCurrency={setCurrency}
        />
      ) : (
        <Withdraw
          paymentGateway={paymentGateway}
          setPaymentGateway={setPaymentGateway}
          currency={currency}
          setCurrency={setCurrency}
        />
      )}
      <HelpInfo
        paymentGateway={paymentGateway}
        setPaymentGateway={setPaymentGateway}
        activeTab={activeTab}
      />
    </Stack>
  )
}

const HelpInfo = (props: PaymentGatewayProps & WalletProps) => {
  const { paymentGateway, activeTab } = props
  const { t } = useT()
  const { walletInfoList } = useAppSelector(getUi)
  const [activeWalletInfo, setActiveWalletInfo] = useState<WalletInfo>()
  const [searchParams, setSearchParams] = useSearchParams()
  const wallet = searchParams.get('wallet')

  useEffect(() => {
    handleGatewayChange()
  }, [])

  useEffect(() => {
    if (walletInfoList) handleGatewayChange()
  }, [paymentGateway, walletInfoList])

  const handleGatewayChange = () => {
    setActiveWalletInfo(
      walletInfoList?.find((item) => item.paymentGateway === paymentGateway)
    )
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <Stack onClick={() => setOpen(true)} sx={{ mb: 6, margin: '0 auto' }}>
        <Typography
          variant='subtitle2'
          color={colors.secondary[6]}
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            ':hover,:focus': {
              color: colors.secondary[7],
            },
          }}
        >
          {t('wallet.help')}
        </Typography>
      </Stack>
      <ModalComponent
        open={open}
        onClose={() => setOpen(false)}
        showCloseButton
        title='wallet.help'
        height={700}
      >
        <Stack
          sx={{
            gap: 1.5,
            color: colors.gray[9],
            'img, video': { borderRadius: 4 },
          }}
        >
          <Interweave
            tagName='div'
            content={
              activeTab === 'deposit'
                ? activeWalletInfo?.deposit
                : activeWalletInfo?.withdraw
            }
          />
        </Stack>
      </ModalComponent>
    </>
  )
}

export interface HeaderProps extends CurrencySelectProps {
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

const walletTabs = ['deposit', 'withdraw']

const Header = (props: HeaderProps) => {
  const global = useAppSelector(getGlobal)
  const { activeTab, setActiveTab } = props
  let [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const theme = useTheme()
  const wallet = global.player?.wallet
  const activeCurrency = global.player?.activeCurrency

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      wallet: tab,
    })
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 1.5,
          background: colors.gray[2],
          borderRadius: 4,
          p: 1,
        }}
      >
        {walletTabs.map((tab) => (
          <Button
            onClick={() => handleTabClick(tab)}
            sx={{
              fontSize: 14,
              fontWeight: 700,
              height: 40.5,
              padding: '8px 66px',
              textTransform: 'capitalize',
              py: 1,
              width: 1,
              color: '#fff',
              ...(activeTab === tab && {
                background: 'linear-gradient(98deg, #0D7CF2 0%, #084A91 100%)',
              }),
            }}
            color='secondary'
            key={tab}
          >
            {t(`wallet.${tab}`)}
          </Button>
        ))}
      </Stack>
      <Stack
        sx={{
          width: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          px: 2,
        }}
      >
        <Typography variant='buttonMedium'>
          {t('wallet.your_balance_in_usd')}
        </Typography>
        <Typography
          variant='buttonMedium'
          sx={{ color: theme.palette.primary.main }}
        ></Typography>
        <Stack sx={{ alignItems: 'flex-end' }}>
          <Typography
            variant='buttonMedium'
            sx={{ color: theme.palette.primary.main }}
          >
            ${formatCurrencyAmount(wallet?.inUSD.balance)}
          </Typography>
          {/* {activeCurrency && (
            <Typography
              dir="ltr"
              variant="caption"
              sx={{ color: colors.gray[9] }}
            >
              <span style={{ display: 'inline-block' }}>
                {t(`currencies.${activeCurrency}`, activeCurrency)}
              </span>{' '}
              <span>
                {wallet?.inUSD.balance &&
                  formatCurrencyAmount(
                    wallet?.inUSD.balance *
                      global.currencies[activeCurrency].USDin,
                    activeCurrency
                  )}
              </span>
            </Typography>
          )} */}
        </Stack>
      </Stack>
    </Stack>
  )
}

export interface CurrencyStateProps {
  currency: string
}

const Deposit = (props: PaymentGatewayProps & CurrencySelectProps) => {
  const global = useAppSelector(getGlobal)
  const [copied, setCopied] = useState(false)
  const { t } = useT()

  return (
    <>
      {props.paymentGateway === PaymentGatewayName.CPG && (
        <CryptoDeposit {...props} />
      )}
      {props.paymentGateway === PaymentGatewayName.PW && (
        <TomanDeposit paymentGateway={PaymentGatewayName.PW} />
      )}
      {props.paymentGateway === PaymentGatewayName.JB && (
        <TomanDeposit paymentGateway={PaymentGatewayName.JB} />
      )}
    </>
  )
}
