import { TournamentV2FileContent } from '@alienclient/back-front'
import { BoxProps, Stack, Typography } from '@mui/material'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { colors } from '@theme'
import { fetchJSONData } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import Banner from '../Banner/Banner'
import { TournamentPrizes } from './TournamentPrizes'
import { RankTable } from './RankTable'

export interface StatsProps {
  stats?: TournamentV2FileContent
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: colors.tertiary[8],
    color: colors.tertiary[8],
    top: -9,
    '&::after': {
      position: 'absolute',
      top: -0.45,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const LiveTournamentStatus = (props: BoxProps) => {
  const [stats, setStats] = useState<TournamentV2FileContent>()

  const { t } = useT()
  const { tournamentSection } = useAppSelector(getUi)
  const { locale } = useParams()

  // TODO: Fetch data every 10 seconds
  async function fetchData() {
    try {
      // Timestamp
      const jsonData = await fetchJSONData(
        `${tournamentSection.rankStatsURL}?ts=${Date.now()
          .toString()
          .slice(0, -4)}`
      )
      setStats(jsonData)
      // Use the fetched JSON data here
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  useEffect(() => {
    // Fetch data immediately on mount
    if (
      tournamentSection.rankStatsURL !== null &&
      tournamentSection.rankStatsURL !== undefined
    )
      fetchData()

    // Set up an interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [tournamentSection]) // Empty dependency array ensures this effect runs only on mount and unmount

  if (!tournamentSection) return <></>

  return (
    <Banner sx={{ p: 1.5 }}>
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
        }}
      >
        <TournamentPrizes stats={stats} />

        {/* Modals */}
        <Stack
          sx={{
            width: 1,
            height: 1,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              background: colors.gray[4],
              py: 1.5,
              borderRadius: 2,
              display: 'flex',
              '& > *:not(:last-child)': {
                marginInlineEnd: 1.5,
              },
              svg: { color: colors.tertiary[8] },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='bodySmall'
              sx={{ fontSize: 12, color: colors.gray[8] }}
            >
              {t('tournaments.live_section.live_status')}
            </Typography>
            <StyledBadge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            ></StyledBadge>
          </Stack>
          <RankTable stats={stats} />
        </Stack>
      </Stack>
    </Banner>
  )
}
