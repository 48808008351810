import { Box, Button, Card, Skeleton, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import { getTranslation } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi, hideMobileNavigation } from 'store/reducers/uiSlice'
import { StatsProps } from './LiveTournamentStatus'

export const TournamentPrizes = (props: StatsProps) => {
  const { stats } = props
  const { locale } = useParams()
  const { tournamentSection } = useAppSelector(getUi)
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const dispatch = useAppDispatch()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        py: 1,
        gap: 1,
        m: 'auto',
        alignItems: 'center',
      }}
    >
      {tournamentSection ? (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1.5,
            width: [300, 365],
          }}
        >
          {/* Count down */}
          <Typography variant='subHeadline' textAlign='center'>
            {tournamentSection.rankTableTitle}
          </Typography>
          {stats?.to && <Countdown date={stats.to} renderer={renderer} />}
          {/* {<Countdown date={1702292811000} renderer={renderer} />} */}
        </Stack>
      ) : (
        <Skeleton
          variant='rectangular'
          sx={{ position: 'relative', height: '125px' }}
        />
      )}
      {/* Title */}
      <Stack
        direction={'column'}
        sx={{
          alignItems: 'center',
          py: '10px',
          px: '6px',
          height: '100%',
          '& > *:not(:last-child)': {
            mb: 2,
          },
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            color: colors.gray[9],
            span: {
              color: colors.primary[4],
            },
          }}
        >
          {tournamentSection.rankTableText}
        </Typography>
        {/* Button */}
        <Stack
          sx={{
            alignItems: 'center',
            img: { width: 160 },
            position: 'relative',
            width: 1,
          }}
        >
          {tournamentSection.rankCoinImg?.url ? (
            <img
              src={tournamentSection.rankCoinImg?.url}
              alt=''
              style={{ minHeight: '140px' }}
            />
          ) : (
            <Skeleton variant='rectangular' width='100%' height='150px' />
          )}
          {tournamentSection.minimumBet && (
            <Box
              sx={{
                position: ['initial', 'absolute'],
                bottom: 0,
                p: '16px 21px',
                background: colors.gray[4],
                borderRadius: 3,
                width: 300,
              }}
            >
              <Typography variant='headline'>
                {tournamentSection.minimumBet}
              </Typography>
            </Box>
          )}
        </Stack>
        <Typography
          sx={{
            fontSize: '10px',
            textAlign: 'center',
            lineHeight: 1.8,
            fontWeight: dir === 'rtl' ? 500 : 600,
            color: colors.gray[9],
          }}
        >
          {tournamentSection.rankTableNote1}
          <span style={{ color: '#4CA1FB' }}>
            <br />
            {tournamentSection.rankTableNote2}
          </span>
        </Typography>
        {!window.location.pathname.includes('tournament') && (
          <NavLink to={`/${locale}/tournament`}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => dispatch(hideMobileNavigation())}
            >
              {t('general.more_info')}
            </Button>
          </NavLink>
        )}
      </Stack>
    </Box>
  )
}

interface RenderertournamentSection {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: RenderertournamentSection) => {
  const list = [
    {
      label: 'tournaments.live_section.days',
      time: days,
    },
    {
      label: 'tournaments.live_section.hours',
      time: hours,
    },
    {
      label: 'tournaments.live_section.minutes',
      time: minutes,
    },
    {
      label: 'tournaments.live_section.seconds',
      time: seconds,
    },
  ]

  if (completed) {
    // Render a complete state
    return
  } else {
    // Render a countdown
    return (
      <Box
        dir='ltr'
        sx={{
          display: 'flex',
          '& > *:not(:last-child)': {
            marginInlineEnd: 0.75,
          },
          justifyContent: 'center',
          '.MuiCard-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 64,
            height: 88,
            borderRadius: 3,
            background: colors.gray[4],
          },
        }}
      >
        {list.map((item) => (
          <Card key={item.label}>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography variant={'headline'} sx={{ color: colors.gray[12] }}>
                {item.time}
              </Typography>
              <Typography
                variant='bodyXSmall'
                sx={{
                  opacity: 0.6,
                  textTransform: 'capitalize',
                  color: colors.gray[12],
                  fontSize: 12,
                  fontWeight: 500,
                  pt: 0.5,
                }}
              >
                {getTranslation(item.label)}
              </Typography>
            </Stack>
          </Card>
        ))}
      </Box>
    )
  }
}
