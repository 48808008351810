import { PaymentGatewayName } from '@alienclient/back-front'
import { Button, Stack, Typography } from '@mui/material'
import { AlienContext } from 'App'
import PageContent from 'parsroyal/components/PageContent/PageContent'
import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'

const PaymentVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { locale, paymentGateway } = useParams()
  const { verifyPaymentResponse } = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const alienContext = useContext(AlienContext)
  const txId = searchParams.get('txid')

  useEffect(() => {
    if (alienContext?.isSocketConnected)
      if (paymentGateway) {
        alienContext?.client.verifyPayment(
          {
            data: {
              txId,
              body: {},
              queryParams: Object.fromEntries(searchParams.entries()),
            },
            gateway:
              PaymentGatewayName[
                paymentGateway.toUpperCase() as PaymentGatewayName
              ],
          },
          { uuid: uuidv4() }
        )
      }
  }, [alienContext?.isSocketConnected])

  return (
    <>
      <Helmet>
        <title>Payment Verification - {generalConfig.siteTitle}</title>
      </Helmet>
      <PageContent
        sx={{ height: '50vh', justifyContent: 'center', alignItems: 'center' }}
      >
        <Stack sx={{ textAlign: 'center', justifyContent: 'center', gap: 1 }}>
          <Typography variant='subHeadline'>
            {verifyPaymentResponse?.message}
          </Typography>
          <NavLink to={`/${locale}`}>
            <Button size='large' variant='contained'>
              Back To Home Page
            </Button>
          </NavLink>
        </Stack>
      </PageContent>
    </>
  )
}

export default PaymentVerification
