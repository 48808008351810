import { Box, Button, Stack, Typography } from '@mui/material'
import useT from 'hooks/useTranslation'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { hideReloadGameModal } from 'store/reducers/uiSlice'

export interface ReloadGameProps {
  reloadGame: boolean
  setReloadGame: Dispatch<SetStateAction<boolean>>
}

const ReloadGame = (props: ReloadGameProps) => {
  const { reloadGame, setReloadGame } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { locale } = useParams()
  const { t } = useT()

  const handleContinueClick = () => {
    setReloadGame(true)
    dispatch(hideReloadGameModal())
  }
  const handleLeaveClick = () => {
    navigate(`/${locale}`)
    dispatch(hideReloadGameModal())
  }

  return (
    <Stack sx={{ textAlign: 'center', gap: 2 }}>
      <Typography variant="body">{t('games.reload_p1')}</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'end',
          '.MuiButton-root': { flex: 1 },
        }}
      >
        <Button variant="contained" onClick={() => handleContinueClick()}>
          {t('games.continue')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleLeaveClick()}
        >
          {t('games.leave_game')}
        </Button>
      </Box>
    </Stack>
  )
}

export default ReloadGame
