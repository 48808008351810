import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideSupportModal } from 'store/reducers/uiSlice'
import { ModalComponent } from '.'
import Login from './Login/Login'
import { LiveSupport } from './LiveSupport'
import { Box, useMediaQuery, useTheme } from '@mui/material'

const LiveSupportModal = () => {
  const { supportModal } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <ModalComponent
      open={supportModal.open}
      title='nav.live_support'
      showBackButton
      height={md ? 630 : 'auto'}
      showCloseButton
      onClose={() => dispatch(hideSupportModal())}
      keepMounted
      sx={{
        '.MuiBox-root': {
          inset: 'initial',
          transform: 'initial',
          right: 10,
          bottom: 10,
        },
      }}
    >
      <LiveSupport />
    </ModalComponent>
  )
}

export default LiveSupportModal
